var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-learning-group",class:{
        square: _vm.isSquare,
        circle: _vm.isCircle,
        oval : _vm.isOval,
        completed : _vm.isGreen,
        current : _vm.isOrange,
        'current-parcours' : _vm.isWhite,
        'next-parcours' : _vm.isOpaque
    },on:{"click":_vm.emitClickEvent}},[_vm._v(" "+_vm._s(_vm.interGroup)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }