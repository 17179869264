<!-- This component is the representation of the "Barre des Activités" !-->

<template>
  <v-row>
    <!-- Intermediate Parcours Only !-->
    <PracticeActivityItem
        class="mr-0 ml-0"
        v-show="isIntermediate && showPractice"
        :practice-activity-intra-group="minIntra"
        :learner-intra-group="learnerIntraGroup"
        :learner-practice-list-nb="learnerPracticeListNb"
    >
    </PracticeActivityItem>
    <!-- Line !-->
    <div class="line mt-5" v-show="showPractice && isIntermediate || showEval && isIntermediate"></div>

    <!-- Core Items !-->
    <div class="d-flex"
         v-for="activityIterator in intraGroupArray"
         :key="activityIterator">

      <!-- Eval Item !-->
      <EvaluationActivityItem
          v-show="showEval"
          :eval-intra-group="activityIterator"
          :learner-intra-group="learnerIntraGroup"
          :learner-practice-list-nb="learnerPracticeListNb"
      >
      </EvaluationActivityItem>
      <!-- Line !-->
      <div class="mt-5 " v-show="showPractice || showEval" :class="[!showPractice ? 'line-big' : 'line' ]"></div>

      <!-- Practice activity iterator starts at 1 for practice activities !-->
      <PracticeActivityItem
          v-show="showPractice"
          :practice-activity-intra-group="activityIterator + 1"
          :learner-intra-group="learnerIntraGroup"
          :learner-practice-list-nb="learnerPracticeListNb">
      </PracticeActivityItem>

      <!-- Line !-->
      <div class="line mt-5" v-show="showPractice || showEval"></div>
    </div>

    <!-- Last Eval !-->
    <EvaluationActivityItem
        v-show="showEval"
        :eval-intra-group="maxIntra"
        :learner-intra-group="learnerIntraGroup"
        :learner-practice-list-nb="learnerPracticeListNb"
    >
    </EvaluationActivityItem>
  </v-row>
</template>

<script>

import PracticeActivityItem from "./PracticeActivityItem";
import EvaluationActivityItem from "./EvaluationActivityItem";
import {debutant} from "../../../utils/cycleUtils";

/**
 * This component is the representation of the "Barre des Activités"
 * It displays 6 EvaluationActivity Item and 5 PracticeActivityItem
 */
export default {
  name: "ActivitiesRow",
  components: {PracticeActivityItem, EvaluationActivityItem},
  props: {
    learnerIntraGroup: {
      type: Number,
      required: true,
      default: 0
    },
    learnerPracticeListNb: {
      type: Number,
      required: true,
      default: 0
    },
    showEval: {
      type: Boolean,
      default: true
    },
    showPractice: {
      type: Boolean,
      default: true
    },
    isIntermediate: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      intraGroupArray: [],
      maxIntra: 5,
      minIntra: 0
    }
  },
  /**
   * Sets values for intra
   */
  created() {
    this.intraGroupArray = Array.from(Array(debutant.maxIntra).keys());
    this.maxIntra = debutant.maxIntra;
  }
}
</script>

<style scoped>
.line {
  width: 10px;
  height: 2px;
  background: black;
}

.line-big {
  width: 90px;
  height: 2px;
  background: black;
}
</style>