<!-- This component is responsible of the initial evaluation of a learner
     #EI1-EI2 !-->

<template>
  <div>
    <!-- Top Page : Avatar img !-->
    <v-row style="height: 220px">
      <v-col class=" mt-3" cols="2">
        <LisonsStaticComponent
            class="ml-5"
            v-if="!startAnimation">
        </LisonsStaticComponent>
        <AnimationComponent
            v-else
            :height="450"
            :width="450"
            :play-animation="startAnimation"
            :type-animation="typeAnimation">
        </AnimationComponent>
      </v-col>

      <!-- Activities Row !-->
      <v-col v-if="learner" class="ml-6">
        <v-row no-gutters>
          <v-col cols="1">
            <InterGroupItem
                class="mt-7"
                :inter-group="parseInt(learner.cycleId[1])"
                :parcours-letter="learner.cycleId[0]"
                :learner-current-inter-group="parseInt(learner.cycleId[1])">
            </InterGroupItem>
          </v-col>
          <v-col>
            <ActivitiesRow
                :show-eval="showEvalRow"
                :show-practice="showPracticeRow"
                class="mt-8"
                :learner-intra-group="learnerIntraGroup"
                :learner-practice-list-nb="learner.practiceListNumber">
            </ActivitiesRow>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div v-if="!toggleTutorialLetterA">
      <!-- Mid Page : Black Text row !-->
      <v-container class="mb-10">
        <v-row v-if="textsVisible" dense>
          <v-col cols="3"></v-col>
          <v-col
              v-for="(item, index) in testNotPassed.slice(0, displayedItemsNumber)"
              :key="item.id"
          >
            <strong style="font-size: 333%; align-content: center"
                    @click="clickedOn(item, index)"
                    v-bind:style="[item.target==='a' && shouldHighlightDemoLetter ? circleStyle : '']"
                    v-bind:class="[item.error_count !== 2 ? normalTextClass : onErrorTextClass]">
              {{ item.target }}
            </strong>
          </v-col>
          <v-col>
          </v-col>
        </v-row>
      </v-container>

      <!-- Bottom Page : Target row !-->
      <SoundTablet
          class="mb-1"
          v-if="learningGroup !== ''"
          :sound-tablets-items="soundTabletsItems"
          :apply-circle-highlight="shouldHighlightDemoLetter"
          :letter-to-highlight="'a'"
          :tablet-visible="tabletVisible"
          :learning-group="learningGroup"
      >
      </SoundTablet>
    </div>

    <div v-else>
      <TutorialLetter
          v-if="learner"
          :first-name="learner.name"
          first-letter="a"
          second-letter="â"
          :tutorial-letter-a-cpt="tutorialLetterACpt"
          @tutorialIsOver="tutorialToggle"
      >
      </TutorialLetter>
    </div>
  </div>
</template>

<script>

/**
 * This component is responsible for the initial evaluation of a learner
 */

import {getLearner, updateLearner} from "../../services/learnerService";
import {
  DEBUTANT_ONE_FIRST_INITIAL_EVAL,
  DEBUTANT_ONE_SECOND_INITIAL_EVAL,
  FAILURE,
  getAllPrevOfCurrentLearnGroup, getCyclesOfLearningGrp,
  getIntraGroup,
  getLearningGroup,
  getNewCycleOfLearner,
  getNextLearningGroup,
  NEXT_CYCLE,
  NEXT_GROUP,
  NEXT_PARCOURS, nextCycleId
} from "../../utils/cycleUtils";
import cycleService from "../../services/cycleService";
import resultService from "../../services/resultsService";
import SoundTablet from "../Items/Tablet/SoundTablet";
import formulesUtils from "../../utils/formulesUtils";
import {playTTS} from "../../services/ttsService";
import {playGraphemeAudioFile} from "../../utils/globalUtils";
import AnimationComponent from "../Items/Lisons/AnimationComponent";
import TutorialLetter from "./TutorialLetter";
import LisonsStaticComponent from "../Items/Lisons/LisonsStaticComponent";
import ActivitiesRow from "../Items/ActivitiesRow/ActivitiesRow";
import InterGroupItem from "../Items/SoundHouse/InterGroupItem";

export default {
  name: "InitialEvaluation",
  components: {ActivitiesRow, LisonsStaticComponent, SoundTablet, AnimationComponent, TutorialLetter, InterGroupItem},
  data() {
    return {
      learner: null,
      learningGroup: '', // current learner learning group
      learnerIntraGroup: undefined,
      idTarget: -1, // id of the target
      currentTarget: null,
      displayedItemsNumber: 5,
      cptReplacementItem: 0, // cpt used to replace targets by targets of the same learning group
      cptReplacement: 0, // cpt used to replace targets by targets of the next learning group
      testNotPassed: [], // contains all targets for the evaluation
      soundTabletsItems: [[]], // items in sound tablets
      replacements: [], //  all replacements
      isEvalOver: false,
      textsVisible: false,
      tabletVisible: false,
      tutorialFirstLetter: false,
      shouldHighlightDemoLetter: false,
      disableAllClicks: true,
      normalTextClass: 'font-weight-light',
      onErrorTextClass: 'font-weight-black',
      circleStyle: {
        borderRadius: '50%',
        border: '3px solid #000',
      },
      firstEmptySoundItemsIndex: 0,
      startAnimation: false,
      typeAnimation: '',
      isFirstInitialEval: false,
      toggleTutorialLetterA: false,
      tutorialLetterACpt: 0,
      previousCycleResults: undefined,
      showEvalRow: false,
      showPracticeRow: false,
    };
  },
  /**
   * If learner is not logged in redirect to connexion
   * @returns {Promise<Route>}
   */
  beforeCreate() {
    if (!this.$session.exists()) {
      this.$router.push('/connexion');
    }
  },
  /**
   * Get connected learner and sets value to start evaluation
   */
  async created() {
    if (this.$session.exists()) {
      this.learner = await this.getConnectedLearner();
      this.learningGroup = await getLearningGroup(this.learner.cycleId);
      this.learnerIntraGroup = parseInt(getIntraGroup(this.learner.cycleId));
      this.tutorialFirstLetter = (this.learner.cycleId === DEBUTANT_ONE_SECOND_INITIAL_EVAL || this.learner.cycleId === DEBUTANT_ONE_FIRST_INITIAL_EVAL);
      this.isFirstInitialEval = this.learner.cycleId === DEBUTANT_ONE_FIRST_INITIAL_EVAL;
      await this.initializeItems(this.learningGroup);
      await this.initializeEvaluation();
    }
  },

  /**
   * Used when learner goes into next learning group
   * as there is no leçon
   * React on params changes,
   * See here for more information
   * https://router.vuejs.org/guide/essentials/dynamic-matching.html#reacting-to-params-changes
   * @param to
   * @param from
   * @param next
   * @return {Promise<void>}
   */
  async beforeRouteUpdate(to, from, next) {
    // reset everything
    this.tabletVisible = false;
    this.textsVisible = false;
    this.isEvalOver = false;
    this.soundTabletsItems = [];
    this.previousCycleResults = [];
    this.startAnimation = false;
    this.cptReplacementItem = 0;
    this.cptReplacement = 0;

    this.learner = await this.getConnectedLearner();
    this.learnerIntraGroup = parseInt(getIntraGroup(this.learner.cycleId));
    this.learningGroup = await getLearningGroup(this.learner.cycleId);
    this.tutorialFirstLetter = (this.learner.cycleId === DEBUTANT_ONE_SECOND_INITIAL_EVAL || this.learner.cycleId === DEBUTANT_ONE_FIRST_INITIAL_EVAL);
    this.isFirstInitialEval = this.learner.cycleId === DEBUTANT_ONE_FIRST_INITIAL_EVAL;
    await this.initializeItems(this.learningGroup);
    next();
    await this.initializeEvaluation();
  },

  methods: {
    /**
     * Returns the connected learner
     * @returns Learner Object on success or null on failure
     */
    async getConnectedLearner() {
      const code = this.$session.get("learnerConnectedCode");
      const learner = await getLearner(code);

      if (learner !== null && learner !== undefined) {
        return learner;
      } else {
        console.log("Could not get Session !");
      }
    },

    /**
     * Initialize evaluation by reading with tts instructions and tutorialFirstLetter
     * @returns {Promise<void>}
     */
    async initializeEvaluation() {
      // defines example as first item
      this.idTarget = 0;
      this.currentTarget = this.testNotPassed[this.idTarget];
      const text = this.tutorialFirstLetter ?
          "la lettre " :
          "ce";

      // First eval presents Activities Row
      if (this.isFirstInitialEval) {
        await playTTS(this.learner.name + " ,dans chaque groupe de sons, nous aurons ensemble deux sortes d’activités.", 4500, 1, "98%");
        this.showEvalRow = true;
        await playTTS("Il y aura des jeux-questionnaires", 2500, 1, "98%");
        this.showPracticeRow = true;
        await playTTS("et il y aura des pratiques de lecture", 2500, 1, "98%");
      }

      /* presents learner with tablet and letters */
      await playTTS("    Regarde " + this.learner.name + ". Voici  des lettres.", 3000, 1, "100%");
      this.textsVisible = true;
      await playTTS("Et en dessous, voici ta tablette des sons", 3000, 1, "100%");
      this.tabletVisible = true;

      await playTTS("Nous allons maintenant jouer ensemble. Je vais dire un son, et toi tu vas cliquer sur " + text +
          " qui fait ce son. Attends que je finisse de parler avant de cliquer.", 10000, 0.95, "100%");
      await playTTS(this.learner.name + ", écoute bien le son", 2200, 1, "100%");

      /* If tutorial first letter is true */
      if (this.tutorialFirstLetter) {
        this.shouldHighlightDemoLetter = true;
        await playGraphemeAudioFile(this.currentTarget.target, 1500);
        await playTTS("Cliques sur la lettre qui fait le son ", 1500, 1, "100%");
        await playGraphemeAudioFile(this.currentTarget.target, 1500);
        await playTTS("et qui est entouré", 0, 1, "100%");
        this.disableAllClicks = false;
        return;
      }

      await playGraphemeAudioFile(this.currentTarget.target, 1000);
      this.disableAllClicks = false;
    },

    /**
     * Event Handler when an element is clicked on
     */
    async clickedOn(itemClickedOn, index) {
      if (!this.disableAllClicks) {
        this.currentTarget.target === itemClickedOn.target ?
            await this.onSuccess(itemClickedOn, index) :
            await this.onError(itemClickedOn);
      } else {
        console.log("You cannot click during init");
      }
    },

    /**
     * This method is called when learner makes a successful guess
     * Read TTS success message, change target + sends results to server
     * and continue evaluation
     * @return Promise<void>
     */
    async onSuccess(itemClickedOn, index) {
      this.disableAllClicks = true;
      itemClickedOn.is_test_passed = true;
      const isSuccessConsecutive = await this.isSuccessConsecutive(itemClickedOn.id);

      // If itemClicked is already in the list, simply replace it
      const foundIndex = this.soundTabletsItems[this.soundTabletsItems.length - 1].findIndex(test => test.target === itemClickedOn.target);
      const replaceIndex = foundIndex !== -1 ?
          foundIndex :
          this.firstEmptySoundItemsIndex;

      // we display clicked item on "tablette des sons"
      this.soundTabletsItems[this.soundTabletsItems.length - 1].splice(replaceIndex, 1, {
        target: itemClickedOn.target,
        focus: false,
        isConsecutive: isSuccessConsecutive
      });
      if (foundIndex === -1)
        this.firstEmptySoundItemsIndex++;

      //we pass on a new target reset possible error count to reset css
      this.currentTarget.error_count = 0;
      //replace item in top row by another one
      await this.replaceClickedOnItem(index);
      await this.updateCurrentTarget();

      await resultService.createOrUpdateResultOfTest(
          this.learner.id,
          itemClickedOn.id,
          this.learner.cycleId,
          itemClickedOn.is_test_passed
      );

      if (this.isEvalOver) {
        return await this.finishEvaluation();
      }

      /* End Demo */
      if (this.tutorialFirstLetter) {
        this.disableAllClicks = true;
        //Dialog for EI2
        if (this.learner.cycleId === DEBUTANT_ONE_SECOND_INITIAL_EVAL) {
          await playGraphemeAudioFile('bike_ring_sound', 1500);
          await playTTS(this.learner.name + ", as-tu entendu le son de la clochette et as-tu vu la lettre qui " +
              "est devenu noire ? Ça veut dire que c’est la deuxième fois que tu choisis la bonne réponse. Bravo !",
              10000, 1, "100%");
        }
        // Dialog for EI1
        else {
          await playTTS("Bravo ! Regarde dans ta tablette des sons " + this.learner.name + ". Vois tu la lettre qui est entouré ?", 5000, 1, "100%");
          await playTTS("Le son", 800, 1, "100%");
          await playGraphemeAudioFile('a', 2000);
          await playTTS("est maintenant dans ta tablette des sons. ça veut dire que tu as réussis une première fois !", 4500, 1, "97%");
        }
        await playTTS(" A ton tour de jouer maintenant, c'est parti ! Écoutes bien...", 4000, 1, "100%");
        this.shouldHighlightDemoLetter = false;
        this.tutorialFirstLetter = false;
        this.disableAllClicks = false;
        await playGraphemeAudioFile(this.currentTarget.target, 1000);
        return;
      }

      /* If success is consecutive */
      if (isSuccessConsecutive) {
        await playGraphemeAudioFile('bike_ring_sound', 1500);
        const randomFoObj = await formulesUtils.randomFo(this.learner.name);
        await playTTS(randomFoObj.sentence, randomFoObj.time, 1, "97%");
      } else {
        const randomFsObj = await formulesUtils.randomFs();
        await playTTS(randomFsObj.sentence, randomFsObj.time, 1, "95%");
      }

      // Lisons presents difference between a and â
      if (this.isFirstInitialEval && this.testNotPassed.slice(0, this.displayedItemsNumber).find(test => test.target === "â")) {
        await playTTS(this.learner.name + ", as-tu remarqué que deux lettres se ressemblent?", 2700, 1, "100%");
        this.toggleTutorialLetterA = true;
        return;
      }

      await playTTS("Écoute bien", 2000, 1, "100%");
      await playGraphemeAudioFile(this.currentTarget.target, 1000);
      this.disableAllClicks = false;
    },

    /**
     * Returns true if the success of a test for the connected learner is consecutive
     * @return {Promise<boolean>}
     */
    async isSuccessConsecutive(currentTestId) {
      // False if there was no previous
      return this.learnerIntraGroup === 0 ?
          false :
          this.previousCycleResults.find(test => test.TestId === currentTestId).is_test_passed;
    },

    /**
     * This method is called when learner makes an errored guess
     * increments error, read TTS error message and ask to try again
     * Handler change according to error_count
     */
    async onError() {
      this.disableAllClicks = true;
      const trueTargetItem = this.currentTarget;
      trueTargetItem.is_test_passed = false;
      trueTargetItem.error_count++;

      await resultService.createOrUpdateResultOfTest(
          this.learner.id,
          trueTargetItem.id,
          this.learner.cycleId,
          trueTargetItem.is_test_passed
      );
      /**
       * See rules of initial evaluation for more information
       */
      const randomEObj = await formulesUtils.randomE(this.learner.name);

      if (trueTargetItem.error_count === 3) {

        /* If learner fails "â" in D10 cycle, launch tutorial again */
        if (this.learner.cycleId === DEBUTANT_ONE_FIRST_INITIAL_EVAL && trueTargetItem.target === 'â') {
          await playTTS("Bien essayé, mais ce n'est pas cette lettre !", 3500, 1, "100%");
          this.toggleTutorialLetterA = true;
          await playTTS(this.learner.name + ', pratique toi encore. Clique sur chaque lettre pour ' +
              'entendre le son qu’elle fait', 6000, 1, "100%");
          trueTargetItem.error_count = 0;
          return;
        }

        await this.updateCurrentTarget();
        // End evaluation if that was the last target */
        if (this.isEvalOver)
          await this.finishEvaluation();
        // 3 fails but evaluation is not over
        else {
          await playTTS("Non, ce n’est pas cette lettre. Ce n’est pas grave, passons au son suivant. " +
              "Continuons. Écoute bien", 6500, 1, "100%");
          await playGraphemeAudioFile(this.currentTarget.target, 1000);
        }
      } else {
        await playTTS(randomEObj.sentence, randomEObj.time, 1, "100%");
        await playGraphemeAudioFile(this.currentTarget.target, 1000);
      }
      this.disableAllClicks = false;
    },

    /**
     * Chooses random next targets among available ones
     * @returns {Promise<void>}
     */
    async updateCurrentTarget() {
      let listIndex = [];
      // search for possible next target, test must belong to the current cycle.
      for (let [index, item] of this.testNotPassed.slice(0, this.displayedItemsNumber).entries()) {
        if (item.TestCycle.CycleId === this.learner.cycleId && item.is_test_passed === "unknown") {
          listIndex.push(index);
        }
      }
      // check if there is at least one available target
      if (listIndex.length !== 0) {
        //if next target is 'â' and isFirstInitialEval is true then force 4
        this.idTarget = this.cptReplacementItem === 4 && this.isFirstInitialEval ?
            this.idTarget = 4 :
            this.idTarget = listIndex[Math.floor(Math.random() * listIndex.length)];

        this.currentTarget = this.testNotPassed[this.idTarget];
      } else {
        this.isEvalOver = true;
      }
      console.log(this.currentTarget.target);
    },

    /**
     * Replaces the Item clicked on by another item, either a target or a replacement
     * @return {Promise<void>}
     */
    async replaceClickedOnItem(index) {

      /* if there is no more target in the learning grp use replacements from next learning grp*/
      if (this.displayedItemsNumber + this.cptReplacementItem >= this.testNotPassed.length) {
        this.testNotPassed.splice(index, 1, this.replacements[this.cptReplacement]);
        this.cptReplacement++;
      } else {
        this.testNotPassed.splice(index, 1, this.testNotPassed[this.displayedItemsNumber + this.cptReplacementItem]);
        this.cptReplacementItem++;
      }
    },

    /**
     * This method is responsible for initializing all items that will be used in the commponent
     * @param learningGroup : string the current learning group of the learner
     * @returns Promise<void>
     */
    async initializeItems(learningGroup) {
      this.showEvalRow = !this.isFirstInitialEval;
      this.showPracticeRow = !this.isFirstInitialEval;

      /* If previous and current are equal to 0, it means there were no previous */
      if (getIntraGroup(this.learner.previous_cycle) !== 0 || this.learnerIntraGroup !== 0) {
        this.previousCycleResults = await resultService.getResultsCycle(this.learner.id, this.learner.previous_cycle);
      }

      this.replacements = this.learningGroup !== 'D4' ?
          // We get replacements. Little trick here. Database is in Cycles not in learningGrp. However all cycles of
          // the same learning grp contain the same array of test, 0 here could be [0:5]
          await cycleService.getTestsCycle((getNextLearningGroup(learningGroup)) + "0") :
          [{target: 'ou', TestCycle: {CycleId: 'D10'}, is_test_passed: "unknown"},
            {target: 's', TestCycle: {CycleId: 'D20'}, is_test_passed: "unknown"},
            {target: 'ch', TestCycle: {CycleId: 'D30'}, is_test_passed: "unknown"},
            {target: 'on', TestCycle: {CycleId: 'D20'}, is_test_passed: "unknown"},
            {target: 'j', TestCycle: {CycleId: 'D20'}, is_test_passed: "unknown"}];

      // Only in Debutant 1, initialEvaluation is used as regular evaluation : EE1 EE2 EE3
      let allTestLength = -1;
      let includeCurrent = false;
      if (this.learnerIntraGroup !== 0 && learningGroup === 'D1') {
        const computedResults = await resultService.getComputedResults(this.learner.id, this.learningGroup[0], this.learningGroup[1], this.learner.previous_cycle);
        allTestLength = computedResults.length;

        for (let computedTest of computedResults) {
          // Test was passed at least once or is already mastered
          if (computedTest.is_test_passed || computedTest.isConsecutive === false) {
            this.soundTabletsItems[this.soundTabletsItems.length - 1].push(computedTest);
          }
          // test was passed at least once or never mastered : "undefined" -> never passed "false" -> passed last evaluation
          //  needs another success to master it
          if (!computedTest.isConsecutive) {
            this.testNotPassed.push(this.convertToTestResultsCycleFormat(computedTest));
          }
        }
      }
      // Every other initial Evaluation of every cycle and learning group
      else {
        this.testNotPassed = await cycleService.getTestsCycle(this.learner.cycleId);
        includeCurrent = true;
        allTestLength = this.testNotPassed.length;
      }

      // Always have 5 items displayed
      if (this.testNotPassed.length < this.displayedItemsNumber) {
        // eslint-disable-next-line no-unused-vars
        for (const iterator of Array(this.displayedItemsNumber - this.testNotPassed.length).keys()) {
          this.testNotPassed.push(this.replacements.shift());
        }
      }

      /* Initialise sound tablet with test of all learning group passed */
      const allLearningGroupCurrentCycle = await getAllPrevOfCurrentLearnGroup(learningGroup[0], parseInt(learningGroup[1]), includeCurrent);
      for (let learningGroup of allLearningGroupCurrentCycle) {
        const soundTabletsItems = await resultService.getComputedResults(this.learner.id, learningGroup[0], learningGroup[1], this.learner.previous_cycle);
        includeCurrent ?
            this.soundTabletsItems.push(soundTabletsItems) :
            this.soundTabletsItems.unshift(soundTabletsItems);
      }

      //gets first empty index of sound tablets items, will be used later to splice.
      this.firstEmptySoundItemsIndex = this.soundTabletsItems[this.soundTabletsItems.length - 1].length;

      //Initialize all test not passed as :
      // empty char : '-' if FIRST INITIAL EVAL (it will avoid UX problems)
      // 'not_acquired' : '_' if not
      let emptyTarget = this.learnerIntraGroup === 0 ? '-' : '_';
      Array.from({length: allTestLength - this.soundTabletsItems[this.soundTabletsItems.length - 1].length}, () =>
          this.soundTabletsItems[this.soundTabletsItems.length - 1].push({target: emptyTarget, focus: false})
      );
    },

    /**
     * This method handles the end of evaluation
     * Calls method @RedirectNext
     */
    async finishEvaluation() {
      this.disableAllClicks = true;

      // saves test results of the learner for the learning group
      await resultService.savesTestResultsLearningGroup(this.learner.id, this.learner.cycleId, this.previousCycleResults);

      //EI 2 -> update cycle
      if (this.learnerIntraGroup !== 0) {
        // gets test results of the learner in his current learning group
        const resultsLearningGroup = await resultService.getResultsLearningGroup(this.learner.id, this.learningGroup[0], this.learningGroup[1], true);

        // gets new cycle for the learner based on its tests result in his current learning group
        const newCycleObject = await getNewCycleOfLearner(parseInt(resultsLearningGroup.length), getCyclesOfLearningGrp(this.learningGroup), this.learner.cycleId);
        newCycleObject.status === NEXT_GROUP ?
            this.learner.practiceListNumber += 0 :
            this.learner.practiceListNumber += 1;

        newCycleObject.status === FAILURE ?
            await this.redirectNext(false, newCycleObject.status, newCycleObject.cycle.id) :
            await this.redirectNext(true, newCycleObject.status, newCycleObject.cycle.id)
      } else {
        //EI 1 -> EI2 : i.e D10 D11
        this.learner.practiceListNumber += 1;
        await this.redirectNext(true, NEXT_CYCLE, nextCycleId(this.learner.cycleId));
      }
    },

    /**
     * Redirect according to if SdeR passed
     * @param passed : boolean whether SdeR is passed or not
     * @param status : string the status of the success : NEXT_GROUP || NEXT_CYCLE || FAILURE || NEXT_PARCOURS
     * @param nextCycleId : string the new cycle of the learner
     * @returns {Promise<Route>}
     */
    async redirectNext(passed, status, nextCycleId) {
      //set previous as current
      this.learner.previous_cycle = this.learner.cycleId;
      // set current as next
      this.learner.cycleId = nextCycleId;

      this.learner = await updateLearner(this.learner.id, this.learner.code, this.learner.name, this.learner.agentId, this.learner.cycleId,
          this.learner.practiceListNumber, this.learner.previous_cycle, this.learner.tutorial_microphone_cpt, this.learner.tutorial_letter_cpt,
          this.learner.tutorial_apostrophe_cpt);
      //Updates for ActivityRow component
      this.learnerIntraGroup = parseInt(getIntraGroup(this.learner.cycleId));

      // Success
      if (passed === true) {
        // Next learning group
        if (status === NEXT_GROUP) {
          const randomFigObj = await formulesUtils.randomFig(this.learner.name);
          this.playAnimation(randomFigObj.typeAnimation);
          await playTTS(randomFigObj.sentence, randomFigObj.time, 1, "97%");
          return this.learningGroup === 'D1' ?
              await this.$router.push('/maison-tutoriel') :
              await this.$router.push("/evaluation-initiale/" + this.learner.cycleId);
        }
        // Next Parcours
        else if (status === NEXT_PARCOURS) {
          return await this.$router.push("/felicitations/");
        }
        // Next cycle
        else {
          const randomFilObj = await formulesUtils.randomFil(this.learner.name);
          this.playAnimation(randomFilObj.typeAnimation);
          await playTTS(randomFilObj.sentence, randomFilObj.time, 1, "97%");
          return await this.$router.push('/presentation/' + this.learner.cycleId);
        }
      }
      // Failure
      else {
        const randomEpObj = await formulesUtils.randomEp(this.learner.name);
        this.playAnimation(randomEpObj.typeAnimation);
        await playTTS(randomEpObj.sentence, randomEpObj.time + 500, 1, "97%");
        return await this.$router.push('/presentation/' + this.learner.cycleId);
      }
    },

    /**
     * Set animation type and start animation
     * @param animationType the animation type
     */
    playAnimation(animationType) {
      this.typeAnimation = animationType;
      this.startAnimation = true;
    },

    /**
     * This methods ends Lisons's tutorial
     * regarding difference between â and a
     */
    async tutorialToggle() {
      this.tutorialLetterACpt++;
      this.isFirstInitialEval = false;
      this.toggleTutorialLetterA = false;
      this.disableAllClicks = false;
      await playTTS("Bien" + this.learner.name + ", retournons à notre jeu questionnaire. Ecoutes bien", 4500, 1, "100%");
      await playGraphemeAudioFile(this.currentTarget.target, 1000);
    },

    /**
     * Converts item into tests with ResultsCycle format (used in the evaluation)
     * @param item : Object the item to convert
     */
    convertToTestResultsCycleFormat(item) {
      item.TestCycle = [];
      item.TestCycle.CycleId = this.learner.cycleId;
      item.is_test_passed = "unknown";
      item.id = item.TestId;
      delete item.TestId;
      delete item.learning_group_letter;
      delete item.learning_group_number;
      return item;
    }

  },
}
</script>

<style scoped>
strong:hover {
  color: orange;
}
</style>