<!-- This component is responsible for difference between â and a  !-->

<template>
  <div>
    <!-- Mid Page : Black Text row  !-->
    <v-row align="center" dense class="mb-11 mt-3">
      <v-col cols="3"></v-col>
      <v-col
          class='text-center '
          v-for="(item, index) in items"
          :key="index"
          :style="{paddingBottom: item.id ===3 ? '30px' : ''}"
          @click="playAudio(item)"
      >
        <strong style="font-size: 333%;" class="font-weight-light"
                :style="[item.id === 1 && highlightFirstLetter ? redCycleStyle : noCircleStyle,
                    item.id === 2 && highlightSecondLetter ? blueCycleStyle : noCircleStyle,
                    item.id === 3 ? redStyle : noCircleStyle]"
        >
          <ruby>{{ item.target }}
            <rt v-if="item.id === 3">{{ secondLetter }}</rt>
          </ruby>
        </strong>
      </v-col>
      <v-col></v-col>

      <!-- White arrow !-->
      <v-col>
        <NextArrow
            @next="backToEvaluation">
        </NextArrow>
      </v-col>
    </v-row>

    <SoundTablet
        class="mb-1"
        v-if="!isFirstLetterMoved"
        :sound-tablets-items="soundTabletsItems"
        :apply-circle-highlight="isLetterCircled"
        :letter-to-highlight="'a'"
        :learning-group="'D1'"
    >
    </SoundTablet>

  </div>
</template>

<script>
import SoundTablet from "../Items/Tablet/SoundTablet";
import {playGraphemeAudioFile} from "../../utils/globalUtils";
import {playTTS} from "../../services/ttsService";
import NextArrow from "../Items/Arrows/NextArrow";

const MIN_CLICK = 3;

/**
 * Component responsible for teaching difference between letters 'â' and 'a'
 */
export default {
  name: "TutorialLetter",
  components: {NextArrow, SoundTablet},
  props: ['firstName', 'firstLetter', 'secondLetter', 'tutorialLetterACpt'],
  data() {
    return {
      soundTabletsItems: [],
      isLetterCircled: false,
      items: [],
      tutorialStep: 0,
      displayedItemsLength: 5,
      classNoMargin: '',
      classMargin: 'mb-10',
      isClickable: false,
      isFirstLetterMoved: false,
      highlightSecondLetter: false,
      highlightFirstLetter: false,
      noCircleStyle: {},
      redCycleStyle: {
        borderRadius: '50%',
        border: '2px solid red',
        marginRight: '25px',
        background: '#FFCDD2'
      },
      blueCycleStyle: {
        borderRadius: '50%',
        border: '2px solid blue',
        background: '#90CAF9'
      },
      cptClicked: 0,
      redStyle: {color: 'red'},
      listIdClick: [],
      cptClick: 0
    }
  },

  created() {
    // first time full tutorial
    if (this.tutorialLetterACpt === 0) {
      this.initializeItems();
      this.playTutorialLetter();
    }
    // if not first time, display last step of tutorial
    else {
      this.initializeItems();
      this.isFirstLetterMoved = true;
      this.items.splice(1, 1, {id: 1, target: this.firstLetter});
      this.items.splice(3, 1, {id: 2, target: this.secondLetter});
      this.items.splice(2, 1, {id: 3, target: this.firstLetter});
      this.isClickable = true;
    }
  },

  methods: {

    /**
     * plays target from item
     */
    async playAudio(item) {
      if (this.isClickable) {
        // when id = 3, must read phoneme ( = second letter)
        const fileName = item.id !== 3 ?
            item.target :
            this.secondLetter;

        // Learner must at least click on each item 1 time
        if (this.listIdClick.findIndex(idIterator => idIterator === item.id) === -1) {
          this.listIdClick.push(item.id);
          this.cptClick++;
        }
        await playGraphemeAudioFile(fileName, 0);
      }
    },

    /**
     * Initialize sound tablet items and items
     */
    initializeItems() {
      this.soundTabletsItems.push([
        {target: this.firstLetter, focus: false, isConsecutive: false},
        {target: "-", focus: false, isConsecutive: false},
        {target: "-", focus: false, isConsecutive: false},
        {target: "-", focus: false, isConsecutive: false},
        {target: "-", focus: false, isConsecutive: false},
      ]);

      Array.from({length: this.displayedItemsLength}, () => this.items.push({target: ' '}));
    },

    /**
     * Redirects learner to evaluation
     */
    async backToEvaluation() {
      if (!this.isClickable)
        return;

      if (this.cptClick !== MIN_CLICK) {
        await playTTS(this.firstName + "Cliques au moins une fois sur les trois lettres", 0, 1, "100%");
        return;
      }
      this.listIdClick = [];
      this.$emit("tutorialIsOver");
    },

    /**
     * Plays tutorial
     * @return {Promise<void>}
     */
    async playTutorialLetter() {
      this.items.splice(4, 1, {target: this.secondLetter});
      this.isLetterCircled = true;
      await playTTS("Tu vois la lettre entouré dans ta tablette des sons ?... " +
          "C'est la lettre que tu connais et qui fait souvent le son...", 5200, 1, "98%");
      await playGraphemeAudioFile(this.firstLetter, 3000);

      /* Moves first letter a from sound tablet to items */
      await playTTS("Pour que tu la vois bien, je vais la placer ici", 4000, 1, "98%");
      this.isLetterCircled = false;
      this.isFirstLetterMoved = true;
      this.items.splice(1, 1, {id: 1, target: this.firstLetter});

      /* Moves second letter from index 4 to 3 to recenter */
      await playTTS("Et puis il y a celle-là", 2500, 1, "98%");
      this.items.splice(4, 1, '');
      this.items.splice(3, 1, {id: 2, target: this.secondLetter});
      await playTTS("C’est la même lettre, mais ici elle a un petit chapeau... " +
          "Écoute bien le son qu’elle fait", 5800, 1, "97%");
      await playGraphemeAudioFile(this.secondLetter, 3000);

      await playTTS("Maintenant, écoute bien " + this.firstName, 3000, 1, "97%");
      this.highlightSecondLetter = true;
      await playTTS(" Cette lettre, avec le petit chapeau, fait toujours le son", 3500, 1, "97%");
      await playGraphemeAudioFile(this.secondLetter, 3000);

      this.highlightFirstLetter = true;
      await playTTS(" Et celle-là, sans chapeau,  fait souvent le son", 3000, 1, "97%");
      await playGraphemeAudioFile(this.firstLetter, 3000);
      await playTTS("mais pas toujours. Des fois elle fait aussi le son", 3000, 1, "97%");
      await playGraphemeAudioFile(this.secondLetter, 3000);
      await playTTS("Alors, pour t’aider à savoir quand elle fait le son", 3000, 1, "97%");
      await playGraphemeAudioFile(this.secondLetter, 3000);
      await playTTS("Je vais l’écrire en rouge avec un petit chapeau au-dessus", 4500, 1, "97%");
      this.items.splice(2, 1, {id: 3, target: this.firstLetter});
      await playTTS("Comme ça", 4500, 1, "98%");

      await playTTS("Pour te pratiquer à les reconnaître, clique sur chaque lettre pour entendre le son qu’elle fait."
          + "Tu peux te pratiquer aussi longtemps que tu veux, clique sur la flèche blanche quand tu as finis", 7500, 1, "98%");
      this.isClickable = true;
      this.highlightFirstLetter = false;
      this.highlightSecondLetter = false;
    },
  }
}

</script>

<style scoped>
ruby {
  display: inline-flex;
  flex-direction: column-reverse;
}

rb, rt {
  display: inline;
  line-height: 1;
}

strong:hover {
  color: orange;
}

</style>