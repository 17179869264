/**
 * Utils to display alert message
 * See https://www.npmjs.com/package/@smartweb/vue-flash-message for more information
 */

export default {
    /**
     * Displays a custom message
     * @param status the type of message : success || error || warning || info
     * @param title Title of your message
     * @param message Text of your message
     * @param vue the vue where to display message
     * @param time (ms) Duration (in ms) of the single message.
     */
    displayMessage(vue, status, title, message, time) {
        vue.flashMessage.show({
            status: status,
            title: title,
            message: message,
            position: 'bottom left',
            time: time
        });
    }
}