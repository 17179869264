<template>
  <v-data-table
      :headers="headers"
      :items="examples"
      :items-per-page="100"
      dense
      hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>
          Exemples
        </v-toolbar-title>
        <v-btn
            class="mx-2"
            color="success"
            text
            @click="newBtnClick">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-dialog v-model="deleteDialog" max-width="700px">
        <v-card>
          <v-card-title class="headline">Êtes vous sûr de vouloir supprimer cet exemple?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">Annuler</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">Oui</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:[`item.actions`]="{item}">
      <v-icon
          small
          @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:[`item.example`]="props">
      <v-edit-dialog
          :return-value.sync="props.item.example"
          @cancel="save"
          @close="save"
          @save="save"
      >
        {{ props.item.example }}
        <template v-slot:input>
          <v-text-field
              v-model="props.item.example"
              clearable
              label=""
              single-line
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:[`item.examplePhonetization`]="props">
      <v-edit-dialog
          :return-value.sync="props.item.examplePhonetization"
          @cancel="save"
          @close="save"
          @save="save"
      >
        {{ props.item.examplePhonetization }}
        <template v-slot:input>
          <v-text-field
              v-model="props.item.examplePhonetization"
              clearable
              label=""
              single-line
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ["items"],
  data() {
    return {
      headers: [
        {text: 'Exemple', value: 'example', sortable: false},
        {text: 'OA', value: 'examplePhonetization', sortable: false},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      examples: this.items,
      editItem: {},
      deleteDialog: false,
      currentItemIndex: -1,
    };
  },
  created() {

  },
  watch: {
    items: function (newVal) {
      this.examples = newVal;
    }
  },
  methods: {
    deleteItem(item) {
      this.deleteDialog = true;
      this.currentItemIndex = this.examples.indexOf(item);
    },
    deleteItemConfirm() {
      this.examples.splice(this.currentItemIndex, 1);
      this.closeDelete();
    },
    newBtnClick() {
      this.examples.unshift({example: "nouvel exemple", examplePhonetization: "nouvel egzanple"});
    },
    save() {
      this.$emit('update:items', this.examples);
    },
    closeDelete() {
      this.deleteDialog = false;
      this.currentItemIndex = -1;
    },
  }
}
</script>