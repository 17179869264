-- <!-- This component is responsible for displaying Practice List !-->
<template>
  <div>
    <!-- Top page !-->
    <v-row style="height: 155px">
      <v-col cols="2">
        <LisonsStaticComponent
            class="ml-5"
            v-if="!startAnimation">
        </LisonsStaticComponent>
        <AnimationComponent
            v-else
            :height="400"
            :width="400"
            :play-animation="startAnimation"
            :type-animation="typeAnimation">
        </AnimationComponent>
      </v-col>

      <!-- Activities Row !-->
      <v-col v-if="learner" class="ml-6">
        <v-row no-gutters>
          <v-col cols="1">
            <InterGroupItem
                class="mt-7"
                :inter-group="parseInt(learner.cycleId[1])"
                :parcours-letter="learner.cycleId[0]"
                :learner-current-inter-group="parseInt(learner.cycleId[1])">
            </InterGroupItem>
          </v-col>
          <v-col>
            <ActivitiesRow
                class="mt-8"
                :learner-intra-group="learnerIntraGroup"
                :learner-practice-list-nb="learner.practiceListNumber">
            </ActivitiesRow>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Window !-->
    <v-row class="mb-16 mt-16">

      <!-- Previous Arrow !-->
      <v-col cols="1" class="text-left pt-8 pb-8 mt-10">
        <div :class="{ shake: shakePrevArrow }">
          <PreviousArrow
              v-if="showPrevArrow"
              @previous="previous"
          >
          </PreviousArrow>
        </div>
      </v-col>

      <!-- Sentence displayed !-->
      <v-col cols="10">
        <template>
          <v-row
              align="center"
              align-content="center"
              justify="center"
          >
            <h1
                v-if="!toggleTutorialApostrophe"
                style="font-size: 5rem;"
                class="black--text pb-4"
            >
              <analysed-sentence
                  :sentence="currentSentence"
                  :showAnalysis="true"
              />
            </h1>
            <TutorialApostrophe
                v-else
                :sentence="sentenceApostrophe"
                :is-first-apostrophe-tuto="isFirstTutorialApostrophe"
                @apostrophe-tutorial-over="tutorialApostropheEndListener">
            </TutorialApostrophe>
          </v-row>
        </template>
      </v-col>

      <!-- White Arrow !-->
      <v-col cols="1" class=" pt-8 pb-8 mt-10 pl-0">
        <div :class="{ shake: shakeNextArrow}">
          <NextArrow
              @next="next"
          >
          </NextArrow>
        </div>
      </v-col>
    </v-row>

    <v-row></v-row>
    <v-row
        align="center"
        align-content="center"
        justify="center"
        class="mt-16"
    >
      <SpeakerComponent
          v-if="showSpeaker"
          :shake-speaker="shakeSpeaker"
          @speaker-clicked-on="playTextToSpeech(items[currentIndex])"
      >
      </SpeakerComponent>
    </v-row>
  </div>
</template>

<script>

import cycleService from "../../services/cycleService";
import {DEBUTANT_ONE_SECOND_INITIAL_EVAL, getIntraGroup, getLearningGroup} from "../../utils/cycleUtils";
import formulesUtils from "../../utils/formulesUtils";
import {isSilent} from "../../utils/phonemeFormat";
import {playPracticeAudioFile, sleep} from "../../utils/globalUtils";
import {playTTS, stopAllAudio} from "../../services/ttsService";
import {getLearner, updateLearner} from "../../services/learnerService";
import AnalysedSentence from "../TextAnalysis/AnalysedSentence";
import SpeakerComponent from "../Items/TextToSpeech/SpeakerComponent";
import AnimationComponent from "../Items/Lisons/AnimationComponent";
import TutorialApostrophe from "./TutorialApostrophe";
import NextArrow from "../Items/Arrows/NextArrow";
import PreviousArrow from "../Items/Arrows/PreviousArrow";
import LisonsStaticComponent from "../Items/Lisons/LisonsStaticComponent";
import InterGroupItem from "../Items/SoundHouse/InterGroupItem";
import ActivitiesRow from "../Items/ActivitiesRow/ActivitiesRow";

export default {
  components: {
    ActivitiesRow,
    InterGroupItem,
    LisonsStaticComponent,
    PreviousArrow, NextArrow, TutorialApostrophe, SpeakerComponent, AnalysedSentence, AnimationComponent
  },
  name: "PracticeList",
  data() {
    return {
      currentIndex: 0,
      learnerIntraGroup: undefined,
      currentSentence: '',
      shouldPlayTutorialSilentLetter: false,
      tutorialApostrophe: false,
      isFirstTutorialApostrophe: false,
      shakeNextArrow: false,
      shakePrevArrow: false,
      shakeSpeaker: false,
      learner: null,
      items: [],
      tutorialArrow: true,
      analyzedSentencesArray: [],
      disableSpeaker: false,
      startAnimation: false,
      typeAnimation: '',
      toggleTutorialApostrophe: false,
      sentenceApostrophe: "",
      showSpeaker: true,
      tutorialTTS: false,
      disableArrows: false,
      numberBankEB: 0,
      helpOnLast: false,
    }
  },

  /**
   * If learner is not logged in redirect to login
   * @returns {Promise<void>}
   */
  beforeCreate() {
    if (!this.$session.exists()) {
      this.$router.push('/connexion');
    }
  },
  /**
   * Get connected learner and sets value to start practice
   */
  async created() {
    if (this.$session.exists()) {
      await this.initializePracticeActivity();
    }
  },

  /**
   * React on params changes
   * See here for more information
   * https://router.vuejs.org/guide/essentials/dynamic-matching.html#reacting-to-params-changes
   * @param to
   * @param from
   * @param next
   * @return {Promise<void>}
   */
  async beforeRouteUpdate(to, from, next) {
    // reset everything
    this.currentIndex = 0;
    this.currentSentence = '';
    this.items = [];
    this.analyzedSentencesArray = [];
    await this.initializePracticeActivity();
    next();
  },
  computed: {
    isLastTarget() {
      return this.currentIndex === this.analyzedSentencesArray.length - 1;
    },
    showPrevArrow: function () {
      return this.currentIndex !== 0;
    }
  },

  methods: {
    /**
     * Initialize all Needed
     * @return {Promise<void>}
     */
    async initializePracticeActivity() {
      this.learner = await this.getConnectedLearner();
      this.learnerIntraGroup = getIntraGroup(this.learner.cycleId);
      this.tutorialArrow = this.learner.cycleId === DEBUTANT_ONE_SECOND_INITIAL_EVAL && this.learner.practiceListNumber === 1;
      this.tutorialTTS = this.tutorialArrow;
      this.helpOnLast = false;
      this.disableSpeaker = false;
      this.disableArrows = false;
      this.tutorialApostrophe = this.learner.tutorial_apostrophe_cpt !== 0;
      // 2 means that the learner still has to go through 2 apostrophe tutorial. 2 being the max.
      this.isFirstTutorialApostrophe = this.learner.tutorial_apostrophe_cpt === 2;
      this.shouldPlayTutorialSilentLetter = this.learner.tutorial_letter_cpt > 0;
      this.numberBankEB = parseInt(localStorage.numberBankEB);
      this.items = await cycleService.getPracticeTextCycle(this.learner.cycleId, this.learner.practiceListNumber);
      for (let practiceListItem of this.items) {
        this.analyzedSentencesArray.push(practiceListItem.text);
      }

      /* Initialize items */
      this.currentIndex = 0;
      this.currentSentence = this.analyzedSentencesArray[this.currentIndex];

      if (this.tutorialArrow) {
        await this.NextArrowTutorial();
      }
    },

    /**
     * Starts tutorial Arrow
     * @return {Promise<void>}
     */
    async NextArrowTutorial() {
      this.disableArrows = true;
      await playTTS("   .Bienvenue" + this.learner.name + "! Ici nous allons lire des mots ensemble ! " +
          "Tu vois la fléche blanche qui bouge ?", 6000, 0.98, "98%");
      this.shakeNextArrow = true;
      setTimeout(() => {
        this.shakeNextArrow = false;
        this.disableArrows = false;
      }, 2500);
      await playTTS("Elle s’appelle la flèche blanche.", 2000, 0.98, "98%");
      await playTTS(this.learner.name + "Clique sur la flèche blanche qui bouge pour faire apparaître le mot " +
          "suivant à lire", 6500, 0.98, "98%");
    },

    /**
     * Reads The text passed in parameter in the browser
     * @param practiceListClicked the text to read
     */
    async playTextToSpeech(practiceListClicked) {
      try {
        //if Lisons is speaking do nothing
        if (this.disableSpeaker === true) {
          return;
        }

        this.disableSpeaker = true;
        // Bank + Animation
        if (this.numberBankEB !== 0) {
          const randomFigObj = await formulesUtils.randomEb(this.learner.name);
          this.playAnimation(randomFigObj.typeAnimation, parseInt(randomFigObj.time) + 1500);
          await playTTS(randomFigObj.sentence, randomFigObj.time, 1, "97%");

          //Updates localStorageValue
          if (!this.tutorialTTS) {
            this.numberBankEB--;
            localStorage.numberBankEB = this.numberBankEB;
          }
        }

        //TODO Must remove condition once Lyne has finished recording all practice text
        practiceListClicked.id <= 440 ?
            await playPracticeAudioFile(practiceListClicked.id, 1500, await getLearningGroup(this.learner.cycleId)) :
            await playTTS(practiceListClicked.text.sentence, 0, 0.95, "50%", true);

        /* finish TTS Tutorial*/
        if (this.tutorialTTS) {
          stopAllAudio();
          this.tutorialTTS = false;
          await sleep(2000);
          await playTTS("Super, maintenant que tu sais comment tout marche, tu peux t'amuser à te promener et " +
              "lire les mots", 0, 1, "98%");
        }
        this.disableSpeaker = false;
      } catch (e) {
        console.error("Error while trying to read text");
        console.error(e);
      }
    },

    /**
     * Handler for black arrow click
     * @return {Promise<void>}
     */
    async previous() {
      if (this.disableArrows)
        return;

      if (this.currentIndex !== 0) {
        this.currentIndex--;
        this.currentSentence = this.analyzedSentencesArray[this.currentIndex];

        if (this.tutorialArrow && this.currentIndex === 0) {
          stopAllAudio();
          this.tutorialArrow = false;
          await this.startTutorialTTS();
        }
      }
    },

    /**
     * Handlers for white arrow click
     * @return {Promise<void>}
     */
    async next() {
      if (this.disableArrows)
        return;

      //If learner is on last target and was helped
      if (this.isLastTarget)
        await this.redirectToNext();

      if (this.currentIndex !== this.analyzedSentencesArray.length - 1) {
        this.currentIndex++;

        // Apostrophe Tutorial
        if (this.tutorialApostrophe && this.analyzedSentencesArray[this.currentIndex].sentence.includes("'")) {
          this.disableArrows = true;
          this.toggleTutorialApostrophe = true;
          this.showSpeaker = false;
          this.sentenceApostrophe = this.analyzedSentencesArray[this.currentIndex].sentence;
        }

        this.currentSentence = this.analyzedSentencesArray[this.currentIndex];

        // Markup length = number of word in sentence, we take the last one as first is the article : "le, la etc"
        const lastWordIndex = this.analyzedSentencesArray[this.currentIndex].markup.length - 1;

        // "Muet"/Silence Letter Tutorial
        if (this.shouldPlayTutorialSilentLetter && this.analyzedSentencesArray[this.currentIndex].markup[lastWordIndex].find(
            graphemePhoneme => isSilent(graphemePhoneme.grapheme, graphemePhoneme.phoneme))) {
          this.disableArrows = true;
          const indexOfWord = this.currentIndex;
          this.shouldPlayTutorialSilentLetter = false;
          await this.startTutoSilentLetter(this.analyzedSentencesArray[indexOfWord].sentence);
          this.learner.tutorial_letter_cpt--;
          this.disableArrows = false;
        }

        // Previous Arrow tutorial
        if (this.tutorialArrow && this.currentIndex === 1) {
          stopAllAudio();
          await playTTS("    .Super ! Si tu veux revenir sur un ancien mot, clique sur la flèche grise qui bouge.   "
              + this.learner.name + " cliques sur la flèche grise qui bouge.", 6000, 1, "98%");
          this.shakePrevArrow = true;
        }

        // Learner is at the last word
        if (this.isLastTarget && !this.tutorialApostrophe && !this.helpOnLast) {
          await this.helpOnLastSentence();
          this.helpOnLast = true;
        }
      }
    },

    /**
     * This method listens on the event apostrophe-tutorial-over from TutorialApostrophe component
     * @return {Promise<void>}
     */
    async tutorialApostropheEndListener() {
      // Take into account the end of tutorial Apostrophe
      this.toggleTutorialApostrophe = false;
      this.tutorialApostrophe = false;
      this.learner.tutorial_apostrophe_cpt--;

      //TODO Must remove condition once Lyne has finished recording all practice text
      this.items[this.currentIndex].id <= 440 ?
          await playPracticeAudioFile(this.items[this.currentIndex].id, 4000, await getLearningGroup(this.learner.cycleId)) :
          await playTTS(this.analyzedSentencesArray[this.currentIndex].sentence, 4500, 1, "40%", true);

      // Makes speaker reappears
      this.showSpeaker = true;
      this.disableSpeaker = false;
      this.disableArrows = false;

      // If it was the last word then proceed to end activity
      if (this.isLastTarget) {
        await this.helpOnLastSentence();
        this.helpOnLast = true;
      }
    },

    /**
     * Plays silence letter
     * @param textToRead : string the text to read
     * @return {Promise<void>}
     */
    async startTutoSilentLetter(textToRead) {
      if (this.learner.tutorial_letter_cpt < 3 && this.learner.tutorial_letter_cpt !== 0)
        await playTTS("Tu te rappelles ?", 1000, 1, "98%");

      await playTTS(this.learner.name + ".  Tu vois la lettre qui est grise ? Quand tu vois une lettre grise dans " +
          "un mot, tu ne la prononce pas.", 6500, 0.95, "95%");
      await playTTS(" Par exemple, tu vas lire : " + textToRead + ". Et non pas. " + textToRead + ", euh", 5000, 0.95, "92%");
    },

    /**
     * Plays TTS tutorialArrow
     * @returns {Promise<void>}
     */
    async startTutorialTTS() {
      this.disableSpeaker = true;
      await playTTS("Bien, maintenant nous allons nous entrainer à lire les mots ! Si tu veux savoir comment " +
          "prononcer ce que tu lis, je vais te donner un autre truc.  Regarde bien le bouton haut parleur bleu qui bouge", 12000, 1, "98%");
      this.shakeSpeaker = true;
      this.disableSpeaker = false;
      setTimeout(() => {
        this.shakeSpeaker = false
      }, 6000);
      await playTTS("C’est le bouton pour m’entendre lire.  Vas-y, clique dessus et répète après moi..", 10000, 1, "98%");
    },

    /**
     * Lisons helps learner on last sentence
     * @returns {Promise<void>}
     */
    async helpOnLastSentence() {
      this.disableSpeaker = true;
      this.disableArrows = true;

      await playTTS("     ." + this.learner.name + ", ici je vais t'aider, répète après moi", 4000, 1, "100%");
      //TODO Must remove condition once Lyne has finished recording all practice text
      this.items[this.items.length - 1].id <= 440 ?
          await playPracticeAudioFile(this.items[this.items.length - 1].id, 5000, await getLearningGroup(this.learner.cycleId)) :
          await playTTS(this.items[this.items.length - 1].text.sentence, 5000, 1, "40%", true);

      await playTTS("Quand tu as finis de te pratiquer cliques sur la fléche blanche", 3500, 1, "97%");
      this.disableSpeaker = false;
      this.disableArrows = false;
    },

    /**
     * Redirect learner to evaluation or next practice list depending on his current practice list number
     * @returns {Promise<void>}
     */
    async redirectToNext() {
      this.disableSpeaker = true;
      this.disableArrows = true;
      if (this.learner.practiceListNumber === 2) {

        // move learner to evaluation of the current cycle, practice list number = 0
        this.learner = await updateLearner(this.learner.id, this.learner.code, this.learner.name, this.learner.agentId, this.learner.cycleId,
            0, this.learner.previous_cycle, this.learner.tutorial_microphone_cpt, this.learner.tutorial_letter_cpt,
            this.learner.tutorial_apostrophe_cpt);
        await playTTS("maintenant que tu as lu plusieurs mots, reprenons notre jeu questionnaire pour vérifier " +
            "ce que tu sais et pour voir ce que tu as appris de nouveau.", 8500, 1, "98%");

        await getLearningGroup(this.learner.cycleId) === "D1" || getIntraGroup(this.learner.cycleId) === 1 ?
            await this.$router.push('/evaluation-initiale/' + this.learner.cycleId) :
            await this.$router.push('/enonce-evaluation/' + this.learner.cycleId);
      } else {
        // move learner to the next practice list
        await playTTS("Super ! Maintenant je vais te présenter une autre liste de mots !", 1000, 1, "100%");

        // update learner to same cycle but next practice list
        this.learner = await updateLearner(this.learner.id, this.learner.code, this.learner.name, this.learner.agentId, this.learner.cycleId,
            this.learner.practiceListNumber + 1, this.learner.previous_cycle, this.learner.tutorial_microphone_cpt, this.learner.tutorial_letter_cpt,
            this.learner.tutorial_apostrophe_cpt);
        await this.$router.push('/liste-de-pratique/' + this.learner.practiceListNumber);
      }
      this.disableSpeaker = false;
    },

    /**
     * Set animation type and start animation
     * @param animationType the animation type
     * @param timeout the duration of the animation in ms
     */
    playAnimation(animationType, timeout = undefined) {
      this.typeAnimation = animationType;
      this.startAnimation = true
      if (timeout !== undefined) {
        setTimeout(() => {
          this.startAnimation = false;
          this.disableSpeaker = false;
        }, timeout);
      }
    },

    /**
     * Returns the connnected learner
     * @returns Learner Object on success or null on failure
     */
    async getConnectedLearner() {
      const code = this.$session.get("learnerConnectedCode");
      const learner = await getLearner(code);

      if (learner !== null && learner !== undefined) {
        return learner;
      } else {
        console.log("Could not get Session !");
      }
    },
  }
}
</script>

<style scoped>

.shake {
  animation: shake 5.00s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

</style>