import cycleService from "../services/cycleService";

const NEXT_GROUP = "NEXT_GROUP";
const NEXT_CYCLE = "NEXT_CYCLE";
const FAILURE = "FAILURE";
const NEXT_PARCOURS = "NEXT_PARCOURS";
const DEBUTANT_ONE_FIRST_INITIAL_EVAL = 'D10';
const DEBUTANT_ONE_SECOND_INITIAL_EVAL = 'D11';
const CURRENT = 'CURRENT';
const BEFORE = 'BEFORE';
const AFTER = 'AFTER';

export {
    NEXT_GROUP,
    NEXT_CYCLE,
    FAILURE,
    DEBUTANT_ONE_FIRST_INITIAL_EVAL,
    DEBUTANT_ONE_SECOND_INITIAL_EVAL,
    NEXT_PARCOURS,
    CURRENT,
    BEFORE,
    AFTER,
    debutant
};

const debutant = {
    learningGrp: "D",
    minInter: 1,
    maxInter: 4,
    minIntra: 0,
    maxIntra: 5,
    next: "I"
}

const intermediaire = {
    learningGrp: "I",
    minInter: 1,
    maxInter: 7,
    minIntra: 0,
    maxIntra: 5,
    next: "A"
}

const avance = {
    learningGrp: "A",
    next: "",
}


/**
 * Returns status from comparing parcours to another
 * @param parcours : string a Parcours
 * @param compareTo : string the Parcours to compare current to
 * @return string Status of comparison between parcours and compareTo : BEFORE || CURRENT || AFTER
 */
function compareParcoursTo(parcours, compareTo) {
    try {
        if (parcours === compareTo)
            return CURRENT;

        // Debutant is always before
        if (parcours === debutant.learningGrp)
            return BEFORE;
        // Intermediate
        else if (parcours === intermediaire.learningGrp)
            return compareTo === avance.learningGrp ? BEFORE : AFTER;
        // Avance is always after
        else
            return AFTER;
    } catch (e) {
        console.log(e);
    }
}


/**
 * Returns the next cycle based on the current
 * @param currentCycle the current cycle
 * @returns {string} the next cycle id
 */
function nextCycleId(currentCycle) {

    let arrayChar = Array.from(currentCycle); // example D10 -> [D, 1, 0]
    const currentLearningGroup = arrayChar[0]; // D || I || A
    const currentInterGroup = parseInt(arrayChar[1]); // [1-7]
    const currentIntraGroup = parseInt(arrayChar[2]); // [0-5]

    let newLearningGroup = "";
    let newInterGroup = "";
    let newIntraGroup = "";

    /**
     * Debutant next
     */
    if (currentLearningGroup === debutant.learningGrp) {
        newLearningGroup = debutant.learningGrp;
        // DX[0-4] : Next Cycle
        if (currentIntraGroup < debutant.maxIntra) {
            newIntraGroup = currentIntraGroup + 1;
            newInterGroup = currentInterGroup;
        } else {
            //D[1-3]5 : Next Group
            if (currentInterGroup < debutant.maxInter) {
                newIntraGroup = debutant.minIntra;
                newInterGroup = currentInterGroup + 1;
            }
            //D45 : Next Parcours
            else {
                newIntraGroup = intermediaire.minIntra;
                newInterGroup = intermediaire.minInter;
                newLearningGroup = debutant.next;
            }
        }
    }
    return newLearningGroup + newInterGroup.toString() + newIntraGroup.toString();
}

/**
 * Returns the intra group of the current cycle
 * @param cycleId : string the current cycle
 * @return {number}
 */
function getIntraGroup(cycleId) {
    try {
        return parseInt(Array.from(cycleId)[2]);
    } catch (e) {
        console.log("Could not get intra group");
    }
}

/**
 * Returns the inter group of the cycle
 * @param cycleId : string the code of the cycle
 * @return {number}
 */
function getInterGroup(cycleId) {
    try {
        return parseInt(Array.from(cycleId)[1]);
    } catch (e) {
        console.log("Could not get inter group");
    }
}

/**
 * Returns the current Parcours
 * @param cycleId : string the code of the cycle
 * @return {string} the parcours group of the cycle
 */
function getParcours(cycleId) {
    try {
        return Array.from(cycleId)[0];
    } catch (e) {
        console.log("Could not get parcours");
    }
}

/**
 * Returns the current learning group of the cycle passed in parameter
 * @param cycleId : string the code of the cycle
 * @return {Promise<string>} the learning group of the cycle
 */
async function getLearningGroup(cycleId) {
    try {
        return (getParcours(cycleId)).toString() + (getInterGroup(cycleId)).toString();
    } catch (e) {
        console.log("Could not get learning group");
    }
}

/**
 * Returns an array containing all cycles of the learning group passed in parameter
 * @param learningGrp : String the learning group
 * @return {*[]} An array containing all cycles of the @learningGrp
 */
function getCyclesOfLearningGrp(learningGrp) {
    let learningGrpSplit = Array.from(learningGrp);
    let cyclesOfLearningGrp = []
    if (learningGrpSplit[0] === "D") {
        Array.from({length: debutant.maxIntra + 1}, (x, intra) => {
            cyclesOfLearningGrp.push(learningGrp + intra);
        });
    } else if (learningGrpSplit[0] === "I") {
        Array.from({length: intermediaire.maxIntra + 1 - intermediaire.minIntra}, (x, intra) => {
            cyclesOfLearningGrp.push(learningGrp + parseInt(intermediaire.minIntra + intra));
        });
    }
    return cyclesOfLearningGrp;
}

/**
 * Returns the next learning group of the learning group passed in parameter
 * @param learningGrp : String a learningGrp
 * @return {string} the next learning group of <learningGrp>
 */
function getNextLearningGroup(learningGrp) {
    let learningGrpSplit = Array.from(learningGrp);
    let nextLearningGrp = "";
    if (learningGrpSplit[0] === "D") {
        nextLearningGrp = "D";
        if (learningGrpSplit[1] < debutant.maxInter) {
            nextLearningGrp += parseInt(learningGrpSplit[1]) + 1;
        }
    } else if (learningGrpSplit[0] === "I") {
        throw Error("Intermédiaire logique pas implementé ! ");
    }
    return nextLearningGrp;
}

/**
 * Returns the new cycle of a learner based on its tests results
 * @param nbTestPassed : number The number of tests the learner successfully passed
 * @param cyclesArrayId : Array<Object> Array containing all cycleIds of the current learning group
 * @param currentCycleId : String the learner's current cycleId
 * @return {Promise<{cycle: Object, status: String}>}
 */
async function getNewCycleOfLearner(nbTestPassed, cyclesArrayId, currentCycleId) {
    let cycle_array = [];
    let status = "";
    cyclesArrayId.splice(0, 1);

    /* loop through id to get all cycle models */
    for (let cycleId of cyclesArrayId) {
        cycle_array.push(await cycleService.getCycle(cycleId))
    }
    let newCycle = undefined;
    for (let [index, cycleModel] of cycle_array.entries()) {

        // Failure
        if (index === 0 && nbTestPassed < cycleModel.SdeR) {
            newCycle = cycleModel;
            status = FAILURE;
            break;
        }
        // next Learning Group || next Parcours
        if (index === cycle_array.length - 1 && nbTestPassed >= cycleModel.SdeR) {
            const currentParcours = getParcours(currentCycleId);

            newCycle = await cycleService.getCycle(nextCycleId(cycleModel.id));
            const nextParcours = getParcours(newCycle.id);
            status = nextParcours === currentParcours ?
                NEXT_GROUP :
                NEXT_PARCOURS;

            break;
        }
        // Next cycle
        else if (nbTestPassed >= cycleModel.SdeR && nbTestPassed < cycle_array[index + 1].SdeR) {
            newCycle = await cycleService.getCycle(nextCycleId(cycleModel.id));
            status = newCycle.id === currentCycleId ?
                FAILURE :
                NEXT_CYCLE;
            break;
        }
    }
    return {cycle: newCycle, status: status};
}

/**
 * Returns all learning groups of the learning group letter
 * @param learningGroupLetter : String the first letter of the learning Group : D || I || A
 * @return {*[]}
 */
function getAllLearningGroups(learningGroupLetter) {
    const resultArray = [];
    if (learningGroupLetter === "D") {
        Array.from({length: debutant.maxInter}, (x, i) =>
            resultArray.push(learningGroupLetter + (i + 1))
        );
    }
    return resultArray;
}

/**
 * Returns all previous learning groups of currentLearningGroup, Including current Learning group by default
 * @param currentLearningGroupLetter : String the current learning group letter
 * @param currentLearningGroupNumber : number the current learning group number
 * @param includeCurrent : boolean true to include current learning group to the returned array, false if not, default is true
 * @return {Promise<Array<Object>>} An array containing all previous learning group and current one
 */
async function getAllPrevOfCurrentLearnGroup(currentLearningGroupLetter, currentLearningGroupNumber, includeCurrent = true) {
    if ((currentLearningGroupNumber > debutant.maxInter || currentLearningGroupNumber < debutant.minInter) &&
        (currentLearningGroupNumber > intermediaire.maxInter || currentLearningGroupNumber < intermediaire.minInter)) {
        throw new Error("Can't get all previous learning group of : " + currentLearningGroupLetter + currentLearningGroupNumber);
    }

    let iterator = includeCurrent ?
        currentLearningGroupNumber :
        currentLearningGroupNumber - 1;

    const resultArray = [];
    Array.from({length: iterator}, (x, i) =>
        resultArray.push(currentLearningGroupLetter + (i + 1))
    );

    return resultArray;
}

export {
    compareParcoursTo,
    getInterGroup,
    getAllPrevOfCurrentLearnGroup,
    getAllLearningGroups,
    getNewCycleOfLearner,
    getNextLearningGroup,
    getCyclesOfLearningGrp,
    getLearningGroup,
    getParcours,
    getIntraGroup,
    nextCycleId
}