<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        Historique
      </v-expansion-panel-header>
      <v-expansion-panel-content style="overflow-y:scroll; max-height:400px">
        <v-data-table
            :headers="headers"
            :items="rules"
            :items-per-page="5"
            dense>
          <template v-slot:[`item.actions`]="{item}">
            <v-icon
                small
                @click="restore(item)"
            >
              mdi-restore
            </v-icon>
          </template>
          <!-- <template v-slot:no-data>
              Aucun historique de modification pour la règle.
          </template> -->
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import rulesService from '../../services/rulesService'

export default {
  props: ['id'],
  data() {
    return {
      headers: [
        {
          text: 'Préfixe',
          align: 'start',
          sortable: true,
          value: 'prefix'
        },
        {text: 'Graphème', value: 'grapheme'},
        {text: 'Suffixe', value: 'suffix'},
        {text: 'Archigraphème', value: 'phoneme'},
        {text: 'Commentaire', value: 'comment'},
        {text: 'Restaurer', value: 'actions', sortable: false}
      ],
      rules: []
    }
  },
  methods: {
    restore(item) {
      this.$emit('restore-item', item);
    },
    async getData() {
      var res = await rulesService.getRuleHistory(this.id);
      this.rules = res;
    },
  },
  watch: {
    id: async function (newId) {
      // eslint-disable-next-line vue/no-mutating-props
      this.id = newId;
      await this.getData();
    }
  },
  async created() {
    await this.getData();
  }
}
</script>