<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-card class="py-6 my-6">
          <v-row>
            <v-col>
              <v-card-title>
                {{ titleForm }}
              </v-card-title>
            </v-col>
            <v-col>
              <p v-if="errors.length !==0" class="red--text"> Veuillez remplir tous les champs les champs !</p>
            </v-col>
          </v-row>
          <v-container>
            <v-row align="center" align-content="center" justify="center">
              <v-col>
                <v-select
                    v-model="selectedLearningGrp"
                    :items="itemsLearning"
                    item-text="displayed"
                    item-value="code"
                    label="Groupe d'apprentissage"
                    persistent-hint
                    return-object
                />
              </v-col>
              <v-col>
                <v-select
                    v-model="selectedPracticeList"
                    :items="practiceList"
                    item-text="displayedListName"
                    item-value="codePractice"
                    label="Numéro de la liste de pratique"
                    persistent-hint
                    return-object
                />
              </v-col>
            </v-row>
            <template>
              <!-- Analyzed sentence display  !-->
              <div v-if="isSentenceAnalyzed">
                <h3> Rendu du moteur phonètique (Clique gauche sur un mot pour le modifier) : </h3>
                <v-row
                    align="center"
                    align-content="center"
                    justify="center"
                >
                  <h2
                      style="font-size: 3rem;"
                      class="black--text pb-4 mt-4"
                  >
                    <analysed-sentence
                        @clickedOn="receiveClickEvent"
                        :sentence="practiceSentenceAnalyzed"
                        :showAnalysis="true"
                    />
                  </h2>
                </v-row>
              </div>

              <!-- List of phoneme of the word clicked on !-->
              <div
                  v-if="wordClickedOn.length !== 0 && isSentenceAnalyzed"
                  class="mt-5"
              >
                <h3> Liste des phonèmes identifiés dans le mot :
                  <span style="font-size: 2rem">
                    {{ wordClickedOn }}
                  </span>
                </h3>
                <v-row>
                  <v-col
                      v-for="(item, index) in graphemePhonemePairArray"
                      :key="index"
                  >
                    <v-text-field :placeholder="item.phoneme" v-model="item.phoneme" @change="updateWord">
                    </v-text-field>
                  </v-col>
                </v-row>

                <div>
                  <h3> Liste des graphèmes identifiés dans le mot :
                    <span style="font-size: 2rem">
                    {{ wordClickedOn }}
                  </span>
                  </h3>
                  <v-row>
                    <v-col
                        v-for="(item, index) in graphemePhonemePairArray"
                        :key="index"
                    >
                      <v-text-field :placeholder="item.grapheme" v-model="item.grapheme" @change="updateWord">
                      </v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </template>

            <!-- Sentence placeholder !-->
            <v-row class="ml-1 mr-1 mt-3">
              <v-text-field
                  v-model="practiceSentenceUnanalyzed"
                  label="Énoncé"
              ></v-text-field>
            </v-row>
          </v-container>

          <!-- Possible actions !-->
          <v-card-actions>
            <v-spacer/>
            <v-btn color="success" @click="save">
              Enregistrer
            </v-btn>
            <v-btn color="success" @click="getAnalysis">
              Analyser
            </v-btn>
            <v-btn v-if="isEditForm" color="primary" @click="sendCancelToParent">
              Annuler
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <FlashMessage></FlashMessage>
  </v-container>
</template>

<script>

import testService from "../../services/textService";
import flashMessageUtils from "../../utils/flashMessageUtils";
import PhoneticAnalysisService from "../../services/phoneticAnalysisService";
import AnalysedSentence from "../TextAnalysis/AnalysedSentence";

/**
 * This component is responsible for logic and view regarding creation and edition of a practice list
 */
export default {
  name: "AddPractice",
  components: {AnalysedSentence},
  data() {
    return {
      titleForm: 'Ajouter un énoncé à une liste de pratique',
      isEditForm: false,
      errors: [],
      idEnonce: -1,
      graphemePhonemePairArray: [],
      wordClickedOn: [],
      practiceSentenceUnanalyzed: '',
      practiceSentenceAnalyzed: undefined,
      selectedLearningGrp: {displayed: "Débutant 1", code: "D1"},
      isSentenceAnalyzed: false,
      currentWordIndex: -1,
      itemsLearning: [
        {displayed: "Débutant 1", code: "D1"},
        {displayed: "Débutant 2", code: "D2"},
        {displayed: "Débutant 3", code: "D3"},
        {displayed: "Débutant 4", code: "D4"},
        {displayed: "Intermédiaire 1", code: "I1"},
        {displayed: "Intermédiaire 2", code: "I2"},
        {displayed: "Intermédiaire 3", code: "I3"},
        {displayed: "Intermédiaire 4", code: "I4"},
        {displayed: "Intermédiaire 5", code: "I5"},
        {displayed: "Intermédiaire 6", code: "I6"},
        {displayed: "Intermédiaire 7", code: "I7"},
        {displayed: "Avancé 1", code: "A1"},
        {displayed: "Avancé 2", code: "A2"}
      ],
      selectedPracticeList: {displayedListName: "Liste de pratique 1", codePractice: 1},
      practiceList: [
        {displayedListName: "Liste de pratique 1", codePractice: 1}, // 1 -> 1
        {displayedListName: "Liste de pratique 2", codePractice: 2},// 0 -> 2
        {displayedListName: "Liste de pratique 3", codePractice: 3}, // 1
        {displayedListName: "Liste de pratique 4", codePractice: 4},
        {displayedListName: "Liste de pratique 5", codePractice: 5},
        {displayedListName: "Liste de pratique 6", codePractice: 6},
        {displayedListName: "Liste de pratique 7", codePractice: 7},
        {displayedListName: "Liste de pratique 8", codePractice: 8},
        {displayedListName: "Liste de pratique 9", codePractice: 9},
        {displayedListName: "Liste de pratique 10", codePractice: 10}
      ]
    }
  },
  methods: {

    /**
     * Sends cancel click to parent
     */
    sendCancelToParent() {
      this.$emit('cancel');
    },

    /**
     * Prepares form for edition on parent click
     * @param itemToEdit
     */
    prepareEdition(itemToEdit) {
      this.isEditForm = true;
      this.titleForm = "Modifier l'énoncé d'une liste de pratique";
      this.idEnonce = itemToEdit.id;
      this.selectedLearningGrp = this.itemsLearning.find(element => element.code === itemToEdit.learningGroup);
      this.selectedPracticeList = this.practiceList.find(element => element.codePractice === itemToEdit.displayedNumList);
      this.practiceSentenceUnanalyzed = itemToEdit.text.sentence;
    },

    /**
     * Get phonetic analysis from phonetic server
     * and displays its results
     */
    async getAnalysis() {
      if (this.practiceSentenceUnanalyzed === '' || this.practiceSentenceUnanalyzed === undefined) {
        this.errors.push({evalNumber: "Veuillez saisir un énoncé de pratique"});
      } else {
        this.graphemePhonemePairArray = [];
        this.practiceSentenceAnalyzed = await PhoneticAnalysisService.getAnalysisAsWords([this.practiceSentenceUnanalyzed]);
        this.isSentenceAnalyzed = true;
        this.wordClickedOn = '';
      }
    },

    updateWord() {
      let wordTmp = []
      for (let graphemePhonemePairObject of this.practiceSentenceAnalyzed.markup[this.currentWordIndex]) {
        wordTmp.push(graphemePhonemePairObject.grapheme);
      }
      this.wordClickedOn = wordTmp.join("");
    },

    /**
     * Listener for event clickedOn
     * Displays phoneme from word clicked on
     * @param indexOfWordClicked
     */
    receiveClickEvent(indexOfWordClicked) {
      this.graphemePhonemePairArray = [];
      this.currentWordIndex = indexOfWordClicked;
      let wordCharArray = [];

      for (let graphemePhonemePairObject of this.practiceSentenceAnalyzed.markup[indexOfWordClicked]) {
        this.graphemePhonemePairArray.push(graphemePhonemePairObject);
        wordCharArray.push(graphemePhonemePairObject.grapheme);
      }
      this.wordClickedOn = wordCharArray.join("");
    },

    /**
     * Create a practice List
     * @returns
     */
    async save() {
      if (await this.validateData()) {
        // If user does not click on getAnalysis then practiceSentenceAnalyzed is undefined
        if (!this.isSentenceAnalyzed) {
          this.practiceSentenceAnalyzed = await PhoneticAnalysisService.getAnalysisAsWords([this.practiceSentenceUnanalyzed]);
        }
        //Prepares messages to display
        let title = '';
        let msg = '';

        // if number % 2 === 0 -> 2 else 1
        const practiceListNumber = this.selectedPracticeList.codePractice % 2 === 0 ? 2 : 1
        const cycleId = this.selectedLearningGrp.code + await this.convert(this.selectedPracticeList.codePractice);
        const text = JSON.stringify(this.practiceSentenceAnalyzed);
        let result = '';

        // Creation
        if (this.idEnonce === -1) {
          result = await testService.createPracticeText(text, cycleId, practiceListNumber);
          title += 'Confirmation de création';
          msg = "L'énoncé de la liste de pratique a été correctement créé !";
        }
        //Édition
        else {
          result = await testService.updatePracticeText(this.idEnonce, text, cycleId, practiceListNumber);
          title += "Confirmation d'édition";
          msg = "L'énoncé de la liste de pratique a été correctement modifié !";
        }

        // Send Success to parent
        if (result.status === true) {
          this.$emit('success', result.model);

          // display success
          flashMessageUtils.displayMessage(this, 'success', title, msg, 3000);

          // reset everything
          this.isSentenceAnalyzed = false;
          this.practiceSentenceUnanalyzed = '';
          this.practiceSentenceAnalyzed = undefined;
          this.graphemePhonemePairArray = [];
          this.idEnonce = -1;
        } else {
          // display error
          flashMessageUtils.displayMessage(this, 'error', 'Impossible de créer ou modifier l\'énoncé de la liste de pratique',
              'Une erreur est survenue lors de la création ou l\'edition de l\'évaluation', 3000);
        }
      }
    },

    /**
     * Converts selected practice code into readable for database
     * @param codePractice the code to convert
     * @returns the converted code
     */
    async convert(codePractice) {
      if (codePractice <= 2)
        return 1;
      else if (codePractice > 2 && codePractice <= 4) {
        return 2;
      } else if (codePractice > 4 && codePractice <= 6) {
        return 3;
      } else if (codePractice > 6 && codePractice <= 8) {
        return 4;
      } else if (codePractice > 8 && codePractice <= 10) {
        return 5;
      }
    },


    /**
     * Small validation on input data
     * @returns true if all field are valid, false if not
     */
    async validateData() {
      this.errors = [];

      if (this.selectedLearningGrp === undefined) {
        this.errors.push({learningGroup: "Veuillez saisir un groupe d'apprentissage valide"});
      }
      if (this.selectedPracticeList === undefined) {
        this.errors.push({evalNumber: "Veuillez saisir un énoncé d'évaluation valide"});
      }
      if (this.practiceSentenceUnanalyzed === '' || this.practiceSentenceUnanalyzed === undefined)
        this.errors.push({evalNumber: "Veuillez saisir un énoncé de pratique"});

      return this.errors.length === 0;
    },
  },

}

</script>

<style scoped>

</style>