<template>
  <v-row>
    <v-col className="ml-5 mt-3" cols="3">
      <LisonsStaticComponent></LisonsStaticComponent>
    </v-col>
  </v-row>
</template>
<script>

import LisonsStaticComponent from "../Items/Lisons/LisonsStaticComponent";
import {playTTS} from "../../services/ttsService";
import LearnerBarEvent from "../../event/LearnerBarEvent";
import {getLearner} from "../../services/learnerService";

/**
 * This component for the presentation of home button in LearnerBar
 */
export default {
  name: "HomeTutorial",
  components: {LisonsStaticComponent},
  /**
   * If learner is not logged in redirect to login
   * @returns {Promise<Route>}
   */
  beforeCreate() {
    if (!this.$session.exists()) {
      this.$router.push('/connexion');
    }
  },

  /**
   * Home button presentation
   */
  async created() {
    LearnerBarEvent.$emit("update-bar");
    const code = this.$session.get("learnerConnectedCode");
    localStorage.backToHome = true;
    this.learner = await getLearner(code);
    LearnerBarEvent.$emit("change-optional-url");
    await playTTS(this.learner.name + ", j’ai un nouveau truc à te montrer. Regarde dans la bande bleu en haut de l’écran",
        6000, 1, "97%");
    LearnerBarEvent.$emit("house-shake", true);
    await playTTS("Il y a maintenant une petite maison qui bouge. Clique dessus.", 6500, 1, "97%");
    LearnerBarEvent.$emit("house-shake", true);

  }
}
</script>

<style scoped>

</style>