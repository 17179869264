<!-- This component is responsible for displaying a Regular Evaluation  !-->

<template>
  <div>
    <!-- Top Page : Avatar img !-->
    <v-row style="height: 155px">
      <v-col cols="2">
        <LisonsStaticComponent
            class="ml-5" v-if="!startAnimation">
        </LisonsStaticComponent>
        <AnimationComponent
            v-else
            :height="400"
            :width="400"
            :play-animation="startAnimation"
            :type-animation="typeAnimation">
        </AnimationComponent>
      </v-col>

      <!-- Activities Row !-->
      <v-col v-if="learner" class="ml-6">
        <v-row no-gutters>
          <v-col cols="1">
            <InterGroupItem
                class="mt-7"
                :inter-group="parseInt(learner.cycleId[1])"
                :parcours-letter="learner.cycleId[0]"
                :learner-current-inter-group="parseInt(learner.cycleId[1])">
            </InterGroupItem>
          </v-col>
          <v-col>
            <ActivitiesRow
                class="mt-8"
                :learner-intra-group="learnerIntraGroup"
                :learner-practice-list-nb="learner.practiceListNumber">
            </ActivitiesRow>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Mid Page : Sentence !-->
    <v-row class="mt-16 mb-5 justify-center align-center">
      <h1 style="font-size: 5rem;">
            <span
                :style="{visibility: showSentence ? 'visible' : 'hidden'}">
              {{ currentAnalysedText }}
            </span>
      </h1>
    </v-row>
    <v-container class="ml-3">
      <v-row>
        <v-col>
          <!-- Bottom Page : Target row !-->
          <SoundTablet
              v-if="learningGroup !== ''"
              :first-name="this.learner.name"
              :sound-tablets-items="soundTabletsItems"
              :learning-group="learningGroup"
              :consolidation="consolidationPresentation"
              @consolidation-over="consolidationOverListener"
          >
          </SoundTablet>
        </v-col>

        <!-- Microphone !-->
        <v-row class="flex-column mb-8">
          <v-spacer></v-spacer>
          <div class="mb-14" style="margin-left: 12%">
            <MicrophoneComponent
                :style="{visibility: showMicrophone ? 'visible' : 'hidden'}"
                @transcription="getTranscript"
                @first-click-tutorial="firstClickTutorialListener"
                :tutorial-microphone="tutorialMicrophone"
                :is-lisons-speaking="isLisonsSpeaking"
                :first-click-sentence-time="firstClickSentenceTime"
                :shake-microphone="shakeMicrophone">
            </MicrophoneComponent>
          </div>
        </v-row>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import {playTTS} from "../../services/ttsService";
import SoundTablet from "../Items/Tablet/SoundTablet";
import AnimationComponent from "../Items/Lisons/AnimationComponent";
import {
  FAILURE,
  getAllPrevOfCurrentLearnGroup, getCyclesOfLearningGrp,
  getIntraGroup,
  getLearningGroup, getNewCycleOfLearner,
  NEXT_GROUP,
  NEXT_PARCOURS
} from "../../utils/cycleUtils";
import resultService from "../../services/resultsService";
import {getLearner, updateLearner} from "../../services/learnerService";
import MicrophoneComponent from "../Items/SpeechToText/MicrophoneComponent";
import {findWordsTarget, playGraphemeAudioFile, removeSpecialChars} from "../../utils/globalUtils";
import formulesUtils from "../../utils/formulesUtils";
import LisonsStaticComponent from "../Items/Lisons/LisonsStaticComponent";
import ActivitiesRow from "../Items/ActivitiesRow/ActivitiesRow";
import InterGroupItem from "../Items/SoundHouse/InterGroupItem";

/**
 * This component is responsible for displaying "un Enoncé d'Evaluation"
 */
export default {
  name: "RegularEvaluation",
  components: {
    InterGroupItem,
    ActivitiesRow, LisonsStaticComponent, SoundTablet, MicrophoneComponent, AnimationComponent
  },
  data() {
    return {
      learner: null, //connected learner
      learningGroup: "",
      soundTabletsItems: [[]], // targets mastered by the learner or at found last evaluation
      testNotPassed: [], // targets not mastered by the learner
      currentIndex: 0, // index of the test not passed displayed
      currentAnalysedText: "", // String containing the sentence displayed
      transcript: '', // transcripted sentence from learner's microphone by Google STT
      firstEmptySoundItemsIndex: -1, // first empty index in sound tablet : empty means char '-'
      lastTest: false, // boolean if it is the last test
      maxTry: 3, // number of max try before displaying next sentence
      cptTry: 1,
      previousCycleResults: null,
      startAnimation: false,
      typeAnimation: '',
      isLisonsSpeaking: false,
      shakeMicrophone: false,
      tutorialMicrophone: false,
      consolidationPresentation: null,
      showMicrophone: false,
      showSentence: false,
      learnerIntraGroup: undefined,
      firstClickSentenceTime: 11500
    }
  },

  /**
   * If learner is not logged in redirect to login
   * @returns {Promise<void>}
   */
  beforeCreate() {
    if (!this.$session.exists()) {
      this.$router.push('/connexion');
    }
  },

  /**
   * Get connected learner and sets value to start evaluation
   */
  async created() {
    if (this.$session.exists()) {
      this.learner = await this.getConnectedLearner();
      this.learnerIntraGroup = parseInt(getIntraGroup(this.learner.cycleId));
      this.learningGroup = await getLearningGroup(this.learner.cycleId);
      await this.initializeItems();
      await this.startConsolidation();
    }
  },
  methods: {

    /**
     * Handlers for speech event transcription
     * analyze transcript text in relation to words targets
     * @param transcriptText the transcription from google speech API
     */
    async getTranscript(transcriptText) {
      this.isLisonsSpeaking = true;
      let isWordPassed = false;
      let wordsPassedCpt = 0;
      let actualTest = {};

      this.tutorialMicrophone ?
          actualTest.words_target = 'Bonjour' :
          actualTest = this.testNotPassed[this.currentIndex];

      const transcriptTextLowerC = transcriptText.toLowerCase();
      console.log(transcriptTextLowerC);

      /**
       * test if learner was successful
       */
      for (let targetWord of actualTest.words_target) {
        isWordPassed = transcriptTextLowerC.includes(targetWord.toLowerCase());
        if (isWordPassed === true)
          wordsPassedCpt += 1;
      }
      const ratioSuccess = wordsPassedCpt / actualTest.words_target.length;

      ratioSuccess === 1 ?
          await this.onSuccess(actualTest) :
          await this.onError(actualTest);
    },

    /**
     * Handler for correct text pronunciation
     * @param actualTest
     * @return {Promise<void>}
     */
    async onSuccess(actualTest) {
      // Tutorial Microphone
      if (this.tutorialMicrophone) {
        this.tutorialMicrophone = false;
        this.learner.tutorial_microphone_cpt--;
        await playTTS("Bonjour à toi aussi " + this.learner.name + '!', 2000, 1, "98%");
        await playTTS("Super ! Maintenant tu sais comment le bouton microphone marche.", 4000, 1, "98%");
        await this.startEvaluation();
        return;
      }

      actualTest.is_test_passed = true;
      const isSuccessConsecutive = await this.isSuccessConsecutive(actualTest.TestId);

      // If itemClicked is already in the list, simply replace it
      const foundIndex = this.soundTabletsItems[this.soundTabletsItems.length - 1].findIndex(test => test.target === actualTest.target);
      const replaceIndex = foundIndex !== -1 ?
          foundIndex :
          this.firstEmptySoundItemsIndex;

      /* insert into sound Tablets test success or confirm/replace existing test */
      this.soundTabletsItems[this.soundTabletsItems.length - 1].splice(replaceIndex, 1, {
        target: actualTest.target,
        isConsecutive: isSuccessConsecutive,
        focus: false
      });
      if (foundIndex === -1)
        this.firstEmptySoundItemsIndex++;

      await resultService.createOrUpdateResultOfTest(
          this.learner.id,
          actualTest.TestId,
          this.learner.cycleId,
          actualTest.is_test_passed
      );

      if (isSuccessConsecutive) {
        await playGraphemeAudioFile('bike_ring_sound', 1500);
        const randomFoObj = await formulesUtils.randomFo(this.learner.name);
        await playTTS(randomFoObj.sentence, randomFoObj.time, 1, "97%");
      } else {
        const randomFsObj = await formulesUtils.randomFs();
        await playTTS(randomFsObj.sentence, randomFsObj.time, 1, "95%");
      }

      // if success and last test then finish evaluation
      if (this.lastTest) {
        return await this.finishEvaluation();
      }
      await this.updateCurrent();
      this.isLisonsSpeaking = false;
    },

    /**
     * Handler for incorrect text pronunciation
     * @return {Promise<void>}
     */
    async onError(actualTest) {
      // If Learner does not pronounce Bonjour"
      if (this.tutorialMicrophone) {
        this.isLisonsSpeaking = true;
        await playTTS("Désolé" + this.learner.name + "mais je n'ai pas bien compris. Reprenons ensemble !", 4000, 1, "100%");
        await this.startMicrophoneTutorial();
        this.isLisonsSpeaking = false;
        return;
      }

      actualTest.is_test_passed = false;

      await resultService.createOrUpdateResultOfTest(
          this.learner.id,
          actualTest.TestId,
          this.learner.cycleId,
          actualTest.is_test_passed
      );

      // test if this is last test and cpt try is equal to max
      if (this.cptTry === this.maxTry) {
        if (this.lastTest)
          return this.finishEvaluation();
        else {
          await playTTS("Ce n'est pas grave passons à la phrase suivante ! ", 2000, 1, "100%");
          await this.updateCurrent();
        }
      } else {
        this.isLisonsSpeaking = true;
        const randomEObj = await formulesUtils.randomE(this.learner.name);
        await playTTS(randomEObj.sentence, randomEObj.time, 1, "100%");
        const randomAbObj = await formulesUtils.randomAb(this.learner.name);
        this.playAnimation(randomAbObj.typeAnimation, randomAbObj.time + 200);
        await playTTS(randomAbObj.sentence, randomAbObj.time, 1, "100%");
        this.cptTry += 1;
      }
      this.isLisonsSpeaking = false;
    },

    /**
     * Returns true if the success of a test for the connected learner is consecutive
     * @return {Promise<boolean>}
     */
    async isSuccessConsecutive(currentTestId) {
      // False if there was no previous
      return this.learnerIntraGroup === 0 ?
          false :
          this.previousCycleResults.find(test => test.TestId === currentTestId).is_test_passed;
    },

    /**
     * Updates current displayed until we reach end of list
     * @return {Promise<void>}
     */
    async updateCurrent() {
      //reset try counter
      this.cptTry = 1;

      if (this.currentIndex !== this.testNotPassed.length - 1) {
        this.currentIndex++;

        // test if currentIndex is on last tast
        if (this.currentIndex === this.testNotPassed.length - 1)
          this.lastTest = true;

        this.currentAnalysedText = this.testNotPassed[this.currentIndex].text;
      }
    },

    /**
     * Initialize soundTabletsItems and tests
     * @return {Promise<void>}
     */
    async initializeItems() {
      // Initialize microphone
      this.tutorialMicrophone = this.learner.tutorial_microphone_cpt > 0;

      const computedResults = await resultService.getComputedResults(this.learner.id, this.learningGroup[0], this.learningGroup[1], this.learner.previous_cycle);
      for (let computedTest of computedResults) {

        // Test was passed at least once or is already mastered
        if (computedTest.is_test_passed || computedTest.isConsecutive === false) {
          this.soundTabletsItems[this.soundTabletsItems.length - 1].push(computedTest);
        }
        // test was passed at least once or never mastered
        // "undefined" -> never passed
        // "false" -> passed last evaluation : needs another success to master it
        if (!computedTest.isConsecutive) {
          computedTest.words_target = findWordsTarget(computedTest.text);
          computedTest.text = removeSpecialChars(computedTest.text);
          this.testNotPassed.push(computedTest);
        }
      }

      this.lastTest = this.testNotPassed.length === 1;

      /* Initialise sound tablet with test of all learning group passed */
      const allLearningGroupCurrentCycle = await getAllPrevOfCurrentLearnGroup(this.learningGroup[0], parseInt(this.learningGroup[1]), false);
      for (let learningGroup of allLearningGroupCurrentCycle) {
        this.soundTabletsItems.unshift(await resultService.getComputedResults(this.learner.id, learningGroup[0], learningGroup[1], this.learner.previous_cycle));
      }

      /* get previous cycle results */
      this.previousCycleResults = await resultService.getResultsCycle(this.learner.id, this.learner.previous_cycle);

      //gets first empty index of sound tablets items, will be used later to splice.
      this.firstEmptySoundItemsIndex = this.soundTabletsItems[this.soundTabletsItems.length - 1].length;

      // For all tests that the learner did not mastered are shown as '_'
      Array.from({length: computedResults.length - this.soundTabletsItems[this.soundTabletsItems.length - 1].length}, () =>
          this.soundTabletsItems[this.soundTabletsItems.length - 1].push({target: '_', focus: false})
      );

      // Gets object that will be used to display sentence
      this.currentAnalysedText = this.testNotPassed[this.currentIndex].text;
    },

    /**
     * Starts consolidation
     * @return {Promise<void>}
     */
    async startConsolidation() {
      /* Starts consolidation whether SoundTablet is empty or not, boolean will determine contents,
        See SoundTablet.vue for more information*/
      this.firstEmptySoundItemsIndex === 0 ?
          this.consolidationPresentation = false :
          this.consolidationPresentation = true;
    },

    /**
     * Listener for when consolidation is finished
     * Event is emit by component SoundTablet.vue
     * Continues evaluation after consolidation
     * @return {Promise<void>}
     */
    async consolidationOverListener() {
      this.showMicrophone = true;

      // Microphone tutorial
      if (this.tutorialMicrophone)
        await this.startMicrophoneTutorial();
      // No tutorial
      else {
        this.showSentence = true;
        await this.startEvaluation();
      }
    },

    /**
     * Starts microphone tutorial
     * @return {Promise<void>}
     */
    async startMicrophoneTutorial() {
      this.isLisonsSpeaking = true;
      await playTTS(this.learner.name + ", je vais t'apprendre un nouveau truc... Tu vois le bouton qui bouge ?",
          4000, 1, "99%");
      this.shakeMicrophone = true;
      await playTTS("C'est le bouton microphone. Si tu appuyes une fois dessus, je t'entends parler...", 5000, 1, "97%");
      await playTTS(this.learner.name + ", appuyes sur le bouton microphone.", 2000, 1, "97%");
      this.shakeMicrophone = false;
      this.isLisonsSpeaking = false;
    },

    /**
     * Listener for when microphone is clicked on for the first time it is microphone tutorial
     * Event is emit by component MicrophoneComponent.vue
     * @return {Promise<void>}
     */
    async firstClickTutorialListener() {
      // First click on microphone activates recognition
      this.isLisonsSpeaking = true;
      await playTTS("Super ! As tu vu ? Le bouton microphone à changé . ça veut dire que je t'entends parler. Dis ..." +
          "'Bonjour Lisons'. Puis appuyes à nouveau sur le bouton microphone quand tu as finis.", this.firstClickSentenceTime, 1, "95%");
      this.isLisonsSpeaking = false;
    },

    /**
     * This method is called when evaluation starts
     */
    async startEvaluation() {
      this.isLisonsSpeaking = true;
      await playTTS(" Bien, passons à un jeu questionnaire. Je vais te montrer des phrases comme celle là", 4500, 1, "100%");
      this.showSentence = true;
      await playTTS("Et tu vas les lire en parlant fort et lentement pour que je t'entende bien. Appuyes sur" +
          " le bouton microphone quand tu veux commencer.", 8000, 1, "98%");
      this.isLisonsSpeaking = false;
    },


    /**
     * This method handles the end of evaluation
     */
    async finishEvaluation() {

      // saves test results of the learner for the learning group
      await resultService.savesTestResultsLearningGroup(this.learner.id, this.learner.cycleId, this.previousCycleResults);

      // gets test results of the learner in his current learning group
      const resultsLearningGroup = await resultService.getResultsLearningGroup(this.learner.id, this.learningGroup[0], this.learningGroup[1], true);

      // gets new cycle for the learner based on its tests result in his current learning group
      const newCycleObject = await getNewCycleOfLearner(parseInt(resultsLearningGroup.length), getCyclesOfLearningGrp(this.learningGroup), this.learner.cycleId);

      newCycleObject.status === NEXT_GROUP ?
          this.learner.practiceListNumber += 0 :
          this.learner.practiceListNumber += 1;

      newCycleObject.status === FAILURE ?
          await this.redirectNext(false, newCycleObject.status, newCycleObject.cycle.id) :
          await this.redirectNext(true, newCycleObject.status, newCycleObject.cycle.id)
    },

    /**
     * Redirect according to if SdeR passed
     * @param passed : boolean whether SdeR is passed or not
     * @param status : string the status of the success
     * @param nextCycleId : string
     * @returns {Promise<Route>}
     */
    async redirectNext(passed, status, nextCycleId) {
      //set previous as current
      this.learner.previous_cycle = this.learner.cycleId;
      // set current as next
      this.learner.cycleId = nextCycleId;

      this.learner = await updateLearner(this.learner.id, this.learner.code, this.learner.name, this.learner.agentId, this.learner.cycleId,
          this.learner.practiceListNumber, this.learner.previous_cycle, this.learner.tutorial_microphone_cpt, this.learner.tutorial_letter_cpt,
          this.learner.tutorial_apostrophe_cpt);

      //Updates for ActivityRow component
      this.learnerIntraGroup = parseInt(getIntraGroup(this.learner.cycleId));

      // Success
      if (passed === true) {
        // Next learning group
        if (status === NEXT_GROUP) {
          const randomFigObj = await formulesUtils.randomFig(this.learner.name);
          this.playAnimation(randomFigObj.typeAnimation);
          await playTTS(randomFigObj.sentence, randomFigObj.time, 1, "97%");
          return this.learningGroup === 'D1' ?
              await this.$router.push('/maison-tutoriel') :
              await this.$router.push("/evaluation-initiale/" + this.learner.cycleId);
        }
        // Next Parcours
        else if (status === NEXT_PARCOURS) {
          return await this.$router.push("/felicitations/");
        }
        // Next cycle
        else {
          const randomFilObj = await formulesUtils.randomFil(this.learner.name);
          this.playAnimation(randomFilObj.typeAnimation);
          await playTTS(randomFilObj.sentence, randomFilObj.time, 1, "97%");
          return await this.$router.push('/presentation/' + this.learner.cycleId);
        }
      }
      // Failure
      else {
        const randomEpObj = await formulesUtils.randomEp(this.learner.name);
        this.playAnimation(randomEpObj.typeAnimation);
        await playTTS(randomEpObj.sentence, randomEpObj.time + 500, 1, "97%");
        return await this.$router.push('/presentation/' + this.learner.cycleId);
      }
    },

    /**
     * Set animation type and start animation
     * @param animationType the animation type
     * @param timeout the duration of the animation in ms
     */
    playAnimation(animationType, timeout = undefined) {
      this.typeAnimation = animationType;
      this.startAnimation = true;
      if (timeout !== undefined) {
        setTimeout(() => {
          this.startAnimation = false;
        }, timeout);
      }
    },

    /**
     * Returns the connected learner
     * @returns Learner Object on success or null on failure
     */
    async getConnectedLearner() {
      const code = this.$session.get("learnerConnectedCode");
      const learner = await getLearner(code);

      if (learner !== null && learner !== undefined) {
        return learner;
      } else {
        console.log("Could not get Session !");
      }
    },
  }
}
</script>

<style scoped>

</style>