<!-- This component is the representation of a row of PracticeListItem Component !-->

<template>
  <v-row no-gutters>
    <!-- Loop through intraGroup !-->
    <v-col
        class="d-flex justify-center"
        v-for="(practiceListItem) in practiceListItemArray"
        :key="practiceListItem.intraGroup">
      <!-- Associate every intraGroup to a PracticeList Item !-->
      <PracticeListItem
          :intra-group="practiceListItem.intraGroup"
          :is-selected="practiceListItemArray[practiceListItem.intraGroup - 1].isSelected"
          @clicked-on="clickedOnListener"
      >
      </PracticeListItem>
    </v-col>
  </v-row>
</template>

<script>
import PracticeListItem from "./PracticeListItem";

/**
 * This component is the representation of a row of PracticeListItem Component
 */
export default {
  name: "PracticeListItemRow",
  components: {PracticeListItem},
  data() {
    return {
      practiceListItemArray: [
        {intraGroup: 1, isSelected: false},
        {intraGroup: 2, isSelected: false},
        {intraGroup: 3, isSelected: false},
        {intraGroup: 4, isSelected: false},
        {intraGroup: 5, isSelected: false},
      ]
    }
  },

  methods: {
    /**
     * Listener for "clicked-on" event
     * Resets isSelected for every practiceList item
     * Updates isSelected of clicked Item
     * @param intraGroup
     */
    clickedOnListener(intraGroup) {
      for (let practiceListItem of this.practiceListItemArray) {
        practiceListItem.isSelected = false;
      }
      this.practiceListItemArray[intraGroup - 1].isSelected = !this.practiceListItemArray[intraGroup - 1].isSelected;
      this.$emit('practice-clicked-on', this.practiceListItemArray[intraGroup - 1].intraGroup);
    }
  }
}
</script>

<style scoped>

</style>