<template>
  <div class="app" :class="{ shake: shakeMicrophone }">
    <v-btn
        color="blue"
        fab
        large @click="handleClick">
      <v-icon v-if="waiting" color="white" x-large>mdi-waveform</v-icon>
      <v-icon v-else color="white" x-large>mdi-microphone</v-icon>
    </v-btn>
    <div class="transcript" v-text="transcript"></div>
  </div>
</template>

<script>

import Recorder from 'recorder-js';
import axios from "axios";
import {sleep} from "../../../utils/globalUtils";

/**
 * This component is the microphone responsible for Voice Recognition
 * This code was written with the help of the following links :
 * https://cloud.google.com/speech-to-text/docs/sync-recognize
 * https://stackoverflow.com/questions/57507737/send-microphone-audio-recorder-from-browser-to-google-speech-to-text-javascrip
 */

export default {
  name: "MicrophoneComponent",
  props: ['isLisonsSpeaking', 'shakeMicrophone', 'tutorialMicrophone', 'firstClickSentenceTime'],

  data() {
    return {
      isMicActive: false,
      transcript: '',
      recorder: null,
      audioStream: null,
      waiting: false,
    }
  },

  methods: {

    /**
     * Asks microphone permission from user to users
     */
    async accessMic() {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();

      this.recorder = new Recorder(audioContext);

      await navigator.mediaDevices
          .getUserMedia({audio: true})
          .then(this.handleSuccess)
          .catch(err => console.log('Uh oh... unable to get stream...', err));
    },

    /**
     * Handles permission granted from user
     * @param stream
     */
    async handleSuccess(stream) {
      this.waiting = true;

      /* If tutorial let Lisons speak and wait until he finishes to start recording
      as it is not good to record long audio file. Google takes too much time to process.
      Might be changed if live recognition is enabled as there is no time limit */
      if (this.tutorialMicrophone) {
        this.$emit('first-click-tutorial');
        // wait for Lisons to finish speaking
        await sleep(this.firstClickSentenceTime);
      }
      this.audioStream = stream;
      this.recorder.init(stream);
      this.recorder.start();
    },

    /**
     * Makes request to the server with short local file
     * See here for more information : https://cloud.google.com/speech-to-text/docs/sync-recognize
     * @param blob the blob that contains the recording
     * @return {Promise<void>}
     */
    async getTextFromGoogle(blob) {
      let filename = new Date().toISOString();
      let formData = new FormData();
      formData.append('audio_data', blob, filename);

      const transcriptText = await axios.post('http://localhost:3000/', formData)
          .then(res => res.data)
          .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          });
      this.$emit('transcription', transcriptText);
    },

    /**
     * Handler for when microphone is clicked on
     */
    async handleClick() {
      //if Lisons is speaking do nothing
      if (this.isLisonsSpeaking === true) {
        console.log("Lisons is speaking please wait...")
        return;
      }

      if (this.isMicActive === false) {
        await this.checkPermissions();
        await this.accessMic();
        this.isMicActive = true;
      } else {
        await this.stopAccessingMic();
        this.isMicActive = false;
      }

    },

    /**
     * Cut audio streams and gets results from server
     */
    async stopAccessingMic() {
      if (this.isLisonsSpeaking === true) {
        console.log("Lisons is speaking please wait...")
        return;
      }

      this.audioStream && this.audioStream.getTracks()[0].stop();
      /* eslint-disable */
      this.recorder.stop().then(async ({blob, buffer}) => {
        await this.getTextFromGoogle(blob);
        this.waiting = false;
      });
    },

    /**
     * Simple permission checks for microphone, does not really impact
     */
    async checkPermissions() {
      navigator.permissions
          .query({name: 'microphone'})
          .then(permissionObj => {
            console.log('Permission status - ', permissionObj.state);
          })
          .catch(error => {
            console.log('Permission status - Got error :', error);
          });
    }
  }
}
</script>

<style scoped>

.shake {
  animation: shake 6.00s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

</style>