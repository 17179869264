<template>
  <v-btn
      style="background-color: white"
      @click="next"
      plain
  >
    <img
        :src="require('@/assets/img/right-arrow.svg')"
        alt="logo arrow"
        style="width:65px; height:65px"
    />
  </v-btn>
</template>

<script>
export default {
  name: "NextArrow",
  methods: {
    next() {
      this.$emit('next');
    }
  }
}
</script>

<style scoped>

</style>