<!-- This component is responsible for displaying logout page !-->

<template>
  <v-container>
    <v-row align="center"
           justify="center">
      <AnimationComponent
          :height="650"
          :width="650"
          :play-animation="startAnimation"
          :type-animation="typeAnimation"
      >
      </AnimationComponent>
    </v-row>
  </v-container>
</template>

<script>
import AnimationComponent from "../Items/Lisons/AnimationComponent";
import formulesUtils, {ACCUEIL_DEPART_ANIMATION} from "../../utils/formulesUtils";
import {playTTS} from "../../services/ttsService";

export default {
  name: "LogoutComponent",
  components: {AnimationComponent},
  data() {
    return {
      name: null,
      startAnimation: false,
      typeAnimation: '',
    }
  },
  /**
   * If learner is not logged in redirect to login
   * @returns {Promise<void>}
   */
  beforeCreate() {
    const learnerName = this.$route.params.name;
    if (learnerName === undefined || typeof learnerName !== 'string') {
      return this.$router.push('/');
    }
  },

  async created() {
    // get name from params as we are already disconnected, See components/Bar/LearnerBar for more information on logout
    this.name = this.$route.params.name;
    this.typeAnimation = ACCUEIL_DEPART_ANIMATION;
    this.startAnimation = true;
    const randomD = await formulesUtils.randomD(this.name);
    await playTTS(randomD.sentence, randomD.time + 100, 1, "97%");
    return this.$router.push('/');
  }
}
</script>

<style scoped>

</style>