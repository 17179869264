<template>
  <v-btn
      plain
      style="background-color: white;"
      @click="previous"
  >
    <v-icon color="black" size="80px">mdi-arrow-left-bold</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "PreviousArrow",
  methods: {
    previous() {
      this.$emit('previous');
    }
  }
}
</script>

<style scoped>

</style>