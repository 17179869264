<!-- This component is the representation of an InterGroup Item !-->

<template>
  <div
      @click="emitClickEvent"
      :class="{
          square: isSquare,
          circle: isCircle,
          oval : isOval,
          completed : isGreen,
          current : isOrange,
          'current-parcours' : isWhite,
          'next-parcours' : isOpaque
      }"
      class="text-learning-group"
  >
    {{ interGroup }}
  </div>
</template>

<script>
import {CURRENT, BEFORE, AFTER} from "../../../utils/cycleUtils";

/**
 * This component is the representation of an InterGroup Item
 */
export default {
  name: "InterGroupItem",
  props: {
    parcoursLetter: {
      type: String,
      default: 'D'
    },
    interGroup: {
      type: Number,
    },
    status: {
      type: String,
      default: CURRENT,
    },
    learnerCurrentInterGroup: {
      type: Number,
    },
    isFirstConnexion: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      blueBackStyle: {
        backgroundColor: '#6091eb'
      },
      orangeBackStyle: {
        backgroundColor: '#ffa333'
      }
    }
  },
  computed: {
    /**
     * Returns true if learner learning group is Debutant
     * @return {boolean}
     */
    isSquare() {
      return this.parcoursLetter === 'D';
    },

    /**
     * Returns true if learner learning group is Intermediate
     * @return {boolean}
     */
    isCircle() {
      return this.parcoursLetter === 'I';
    },

    /**
     * Returns true if learner learning group is Avance
     * @return {boolean}
     */
    isOval() {
      return this.parcoursLetter === 'A';
    },

    /**
     * Returns true if learner's intergroup is above component's intergroup OR if status is BEFORE
     * @return {boolean}
     */
    isGreen() {
      return (this.status === BEFORE) || (parseInt(this.learnerCurrentInterGroup) > parseInt(this.interGroup) && this.status === CURRENT);
    },

    /**
     * Returns true if component's interGroup is the same as the learner and status is CURRENT
     * @return {boolean}
     */
    isOrange() {
      return this.status === CURRENT && parseInt(this.learnerCurrentInterGroup) === parseInt(this.interGroup);
    },

    /**
     * Returns true if it is not orange nor blue and status is CURRENT
     * @return {boolean}
     */
    isWhite() {
      return !this.isOrange && !this.isGreen && (this.status === CURRENT || this.isFirstConnexion);
    },

    /**
     * Returns true if status is AFTER
     * @return {boolean}
     */
    isOpaque() {
      return this.status === AFTER && !this.isFirstConnexion;
    },

  },


  methods: {

    /**
     * Emits to Parent event "clicked-on"
     * With arguments
     */
    emitClickEvent() {
      this.$emit('clicked-on', this.parcoursLetter, this.interGroup, this.status);
    }
  }
}
</script>

<style scoped>

.completed {
  background-color: rgb(153, 255, 102);
}

.current {
  background-color: rgb(255, 204, 0);
}

.current-parcours {
  background-color: white;
}

.next-parcours {
  opacity: 0.3;
}

.square {
  height: 50px;
  width: 50px;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-learning-group {
  font-size: 200%;
}

.oval {
  height: 50px;
  width: 100px;
  border-radius: 50%;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
}


.circle {
  height: 60px;
  width: 60px;
  border-style: solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>