<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="enonceListArray"
        sort-by="id"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>Tableau des évaluations</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-btn class="success" href="#enonce-evaluations">
            Ajouter un énoncé d'évaluation
          </v-btn>

          <!-- Edit dialog !-->
          <v-dialog
              width="1180"
              v-model="dialog">
            <AddEvaluation
                ref="editForm"
                @cancel="close"
                @success="saveSuccess">
            </AddEvaluation>
          </v-dialog>

          <!-- Delete dialog !-->
          <v-dialog v-model="dialogDelete" max-width="570px">
            <v-card>
              <v-card-title class="text-h5">Êtes-vous sûr de vouloir supprimer cet énoncé ?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="error" text @click="closeDelete">Annuler</v-btn>
                <v-btn class="success" text @click="deleteItemConfirm">Confirmer</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="prepareEditItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            small
            @click="prepareDeleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        Pas de données.
      </template>
    </v-data-table>

    <!-- Add Evaluation !-->
    <div id="enonce-evaluations">
      <AddEvaluation
          @success="saveSuccess">
      </AddEvaluation>
    </div>

  </div>
</template>

<script>

import AddEvaluation from "./AddEvaluation";
import textService from "../../services/textService";
import {getLearningGroup} from "../../utils/cycleUtils";
import flashMessageUtils from "../../utils/flashMessageUtils";

/**
 * This component is responsible for CRUD operation regarding "Les énoncés d'évaluations"
 * This component was written with the help of Vuetify documentation
 * See here : https://vuetifyjs.com/en/components/data-tables/#crud-actions
 * for more information
 */
export default {
  name: "EvaluationTable",
  components: {AddEvaluation},
  data: () => ({
    dialogDelete: false,
    dialog: false,
    headers: [
      {
        text: "id",
        align: 'start',
        value: 'id',
      },
      {text: "Groupe d'apprentissage", value: 'learningGroup', sortable: true},
      {text: 'Cible', value: 'target'},
      {text: "Énoncé d'évaluation", value: 'text'},
      {text: "Type d'énoncé", value: 'enonce'},
      {text: "phonème", value: 'phoneme'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    enonceListArray: [],
    editedIndex: -1,
    editedItem: {
      id: -1,
      learningGroup: 0,
      target: 0,
      text: 0,
      enonce: 0,
      phoneme: 0,
    },
    defaultItem: {
      id: -1,
      learningGroup: 0,
      target: 0,
      text: 0,
      enonce: 0,
      phoneme: 0,
    },
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  /**
   * Populates DataTable
   */
  async created() {
    await this.populateDataTable();
  },

  methods: {
    /**
     * Gets All "Enoncé d'évaluations"
     */
    async populateDataTable() {
      const allTestList = await textService.getAllEvaluations();
      for (let test of allTestList) {
        test.learningGroup = await getLearningGroup(test.cycleId);
      }
      this.enonceListArray = allTestList;
    },

    /**
     * Prepares item edition and opens modal
     * @param item
     */
    prepareEditItem(item) {
      this.dialog = true;
      this.editedIndex = this.enonceListArray.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$nextTick(() => {
        this.$refs.editForm.prepareEdition(this.editedItem);
      });
    },

    /**
     * Opens delete dialog and stores item & index to delete
     * @param itemToDelete the item to delete
     */
    prepareDeleteItem(itemToDelete) {
      this.editedIndex = this.enonceListArray.indexOf(itemToDelete);
      this.editedItem = Object.assign({}, itemToDelete);
      this.dialogDelete = true;
    },

    /**
     * Deletes item passed in parameter
     * and closes dialog
     */
    async deleteItemConfirm() {
      const result = await textService.deleteEvaluation(this.editedItem.id);

      // success
      if (result === true) {
        flashMessageUtils.displayMessage(this, 'success', 'Confirmation de suppression',
            "L'évaluation a été correctement supprimée !", 3000);
        this.enonceListArray.splice(this.editedIndex, 1);
      }
      //failure
      else {
        flashMessageUtils.displayMessage(this, 'error', "Impossible de supprimer l'énoncé de la liste de pratique",
            "Une erreur est survenue lors de la suppresion de l'évaluation. Veuillez contacter l'administrateur" +
            " si le problème persiste", 4500);
      }
      this.closeDelete();
    },

    /**
     * Resets edited item to default
     */
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    /**
     * Closes modal and resets editItem
     */
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      })
    },

    /**
     * Listener for success event emitted by AddPractice Component
     * Behavior depends on edit or creation.
     */
    async saveSuccess(createdOrEditedEnonce) {
      createdOrEditedEnonce.learningGroup = await getLearningGroup(createdOrEditedEnonce.cycleId);

      // If it was an edit, update values
      if (this.editedIndex > -1) {
        Object.assign(this.enonceListArray[this.editedIndex], createdOrEditedEnonce);
      }
      // Creation
      else {
        this.enonceListArray.push(createdOrEditedEnonce);
      }
      this.close();
    },
  }

}
</script>

<style scoped>

</style>