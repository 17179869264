/**
 * This file was written with the help of : https://github.com/auth0/auth0-vue/blob/main/tutorial/vue2-login.md
 * This file is responsible to check if the user is authenticated when he wants to access a page
 * If he is the page is displayed
 * If not he is redirected to the home page
 */

import {getInstance} from './index';

export const authGuard = (to, from, next) => {
    const authService = getInstance();

    const fn = () => {
        // If the user is authenticated, continue with the route
        if (authService.isAuthenticated) {
            return next();
        }

        // Otherwise, log in
        authService.loginWithRedirect({appState: {targetUrl: to.fullPath}});
    };

    // If loading has already finished, check our auth state using `fn()`
    if (!authService.loading) {
        return fn();
    }

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch('loading', loading => {
        if (loading === false) {
            return fn();
        }
    });
};