<!-- This components refers to the nav bar after the
     learner successful connection. Learner may disconnect
     or go to LearnerHome -->

<template>
  <v-container>
    <v-row class="justify-end">
      <v-col v-if="displayHome" cols="1">
        <div :class="{ shake: shakeHouse }">
          <v-btn
              href="/page-accueil-apprenant"
              text>
            <v-icon large>
              mdi-home
            </v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="4">
        <span class="mx-2"/>
        <v-btn
            v-if="learner"
            text
            v-bind:href="'/deconnexion/' + learner.name"
            @click="logout"
        >
          {{ $t('common.disconnect') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LearnerBarEvent from "../../../event/LearnerBarEvent";
import {getLearner} from "../../../services/learnerService";
import {getInterGroup, getParcours} from "../../../utils/cycleUtils";

/**
 * This components refers to the nav bar after the
 * learner successful connection. Learner may disconnect
 * or go to LearnerHome
 */
export default {
  data: () => ({
    learner: null,
    shakeHouse: false,
    learnerHomeUrl: '/page-accueil-apprenant/',
    displayHome: false
  }),

  async created() {
    /**
     * Event listener for house shaking event
     */
    LearnerBarEvent.$on("house-shake", booleanValue => {
      this.shakeHouse = booleanValue;
      setTimeout(() => this.shakeHouse = false, 6500);
    });

    /**
     * Event listener for adding optional to home url
     */
    LearnerBarEvent.$on("change-optional-url", () => {
      this.learnerHomeUrl += '/false';
    });

    /**
     * Event listener for updating learner's bar
     */
    LearnerBarEvent.$on("update-bar", () => {
      this.displayHome = true;
    });

    const code = this.$session.get("learnerConnectedCode");
    this.learner = await getLearner(code);
    this.displayHome = !(getParcours(this.learner.cycleId) === 'D' && getInterGroup(this.learner.cycleId) === 1);
  },

  methods: {
    /**
     * Logs out learner and redirect to login page
     */
    logout() {
      LearnerBarEvent.$emit("Logged", false);
      localStorage.clear();
      this.$session.destroy();
    },
  }
}
</script>
<style scoped>

.shake {
  animation: shake 6.00s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>