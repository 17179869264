<!-- This component displays a speaker !-->

<template>
  <div :class="{ shake: shakeSpeaker }">
    <v-btn
        color="info"
        dark
        fab
        large
        @click="useSpeaker"
    >
      <v-icon dark>mdi-volume-high</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "SpeakerComponent",
  props: {
    shakeSpeaker: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    /**
     * This method only emits events, parents will choose the comportment
     */
    useSpeaker() {
      this.$emit('speaker-clicked-on');
    }
  }
}
</script>

<style scoped>

.shake {
  animation: shake 5.00s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>