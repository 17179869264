import {getIntraGroup, getLearningGroup, getParcours} from "./cycleUtils";

const AUDIO_FILE_EXTENSION = '.mp3';

/**
 * Utils method to sleep for specified amount of time
 * @param ms the amount to wait in ms
 */
function sleep(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

/**
 * Plays a grapheme audio file from assets folder
 * @param fileName : string the name of the file to play.
 * @param sleepingAmount : number (ms) the amount of time to wait for the file to be fully played. Must be > 0.
 * @return {Promise<void>}
 */
async function playGraphemeAudioFile(fileName, sleepingAmount) {
    if (sleepingAmount < 0)
        throw new Error("Bad sleeping amount input : " + sleepingAmount);

    if (fileName !== undefined && fileName !== '') {
        const audio = new Audio(require('../assets/audio/grapheme/' + fileName + AUDIO_FILE_EXTENSION));
        await audio.play();
        await sleep(sleepingAmount);
    } else {
        throw new Error("Could not play audio file with : " + fileName);
    }
}

/**
 * Plays a practice list audio file from assets folder
 * @param fileName : string the name of the file of the practice list to play.
 * @param sleepingAmount : number (ms) the amount of time to wait for the file to be fully played. Must be > 0.
 * @param learningGroup : string the learning group of the practice list
 * @return {Promise<void>}
 */
async function playPracticeAudioFile(fileName, sleepingAmount, learningGroup) {
    if (sleepingAmount < 0)
        throw new Error("Bad sleeping amount input : " + sleepingAmount);

    if (fileName !== undefined && fileName !== '' && parseInt(fileName) > 0 && learningGroup.length === 2) {
        const audio = new Audio(require('../assets/audio/practice-list/' + learningGroup + '/' + fileName + AUDIO_FILE_EXTENSION));
        await audio.play();
        await sleep(sleepingAmount);
    } else {
        throw new Error("Could not play audio file with : " + fileName);
    }
}

/**
 * Format Text passed in parameter
 * @param textToTransform : string the text to transform
 * @param separateWords : boolean true if words are to be pronounced separately
 * @return string the text transformed
 */
function transformTextToRead(textToTransform, separateWords) {
    if (separateWords) {
        return textToTransform.replaceAll(" ", " . ");
    } else {
        return textToTransform;
    }
}

/**
 * Returns a string that is an url which points to the last activity of the learner
 * @param learner : Object the learner connected
 * @return string the string containing the url
 */
async function buildLearnerCurrentUrl(learner) {
    try {
        // fall back to home if something goes wrong
        let urlToReturn = "/";
        const intraGroup = getIntraGroup(learner.cycleId);

        // Learner is either in EI or EE
        if (learner.practiceListNumber === 0) {
            urlToReturn = intraGroup === 0 || intraGroup === 1 || await getLearningGroup(learner.cycleId) === "D1" ?
                '/evaluation-initiale/' + learner.cycleId :
                '/enonce-evaluation/' + learner.cycleId;
        } else {
            urlToReturn = getParcours(learner.cycleId) === 'I' && getIntraGroup(learner.cycleId) === 0 ?
                '/presentation-intermediaire/' :
                '/liste-de-pratique/' + learner.practiceListNumber;
        }
        return urlToReturn;
    } catch (e) {
        console.log(e);
    }
}

/**
 * Returns array containing words between "(" and ")"
 * Function written with the help of
 * https://stackoverflow.com/questions/64112955/get-multiple-substring-between-two-characters-javascript
 * @param textToProcess : string the text to process
 * @return Array<String>
 */
function findWordsTarget(textToProcess) {
    const words = [];
    for (let i = 0; i < textToProcess.length; i++) {
        if (textToProcess.charAt(i) === '(') {
            const stopIndex = textToProcess.indexOf(')', i);
            if (stopIndex !== -1)
                words.push(textToProcess.substring(i + 1, stopIndex));
        }
    }
    return words;
}

/**
 * Removes parenthesis from string passed in parameter
 * written with the help of
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#using_special_characters
 * @param textToProcess : string the text to process
 * @return {String}
 */
function removeSpecialChars(textToProcess) {
    textToProcess = textToProcess.replace(new RegExp('\\(', 'g'), '');
    return textToProcess.replace(new RegExp('\\)', 'g'), '');
}

export {
    sleep,
    transformTextToRead,
    findWordsTarget,
    removeSpecialChars,
    playGraphemeAudioFile,
    buildLearnerCurrentUrl,
    playPracticeAudioFile
}

