/**
 * This file contains services for the agent,
 * mainly used in components/Learners/Learner_table.vue
 */

import axios from 'axios';
import {getInstance} from "../auth";

const baseUrl = process.env.VUE_APP_AILE_SERVER_API_BASE_URL + 'agent';


export default {
    /**
     * Defines header containing the access token for the REST Server
     * @returns A Header for a request
     */
    async getHeader() {
        const token = await getInstance().getTokenSilently();
        return {
            headers: {
                Authorization: `Bearer ${token}`,   // send the access token through the 'Authorization' header
                'Content-Type': 'application/json'
            }
        }
    },

    /**
     * Makes a request to the server to get the connected agent or create it
     * @returns the connected agent or null if something went wrong
     */
    async getOrCreateAgent() {
        const data = await axios.put(baseUrl, {}, await this.getHeader()).then(res => res.data)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
        return data ? data.agent : null;
    },

    /**
     * Makes a request to the server to get all learner of the connected agent
     * @returns All the learners from the connected agent or null if something went wrong
     */
    async getLearners() {
        const allLearners = await axios.get(baseUrl + '/learner', await this.getHeader())
            .then(res => res.data)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
        return allLearners ? allLearners : null;
    },

    /**
     * Makes a request to the server to update a learner that belongs to the connected agent
     * @param learnerUpdated learner containing the values to updates
     * @param idToEdit, the id of the learner to update
     * @returns the updated learner or null if something went wrong
     */
    async editLearner(learnerUpdated, idToEdit) {
        const res = await axios.put(baseUrl + '/learner/' + idToEdit + '/update/', {learnerUpdated}, await this.getHeader())
            .then(res => res.data)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
        return res ? res.data : null;
    },

    /**
     * Makes a request to the server to add a learner to the connected agent
     * @param learnerToAdd, the learner to Add
     * @returns the added learner or null if something went wrong
     */
    async addLearner(learnerToAdd) {
        const learnerAdded = await axios.post(baseUrl + '/learner/add', {learnerToAdd}, await this.getHeader())
            .then(res => res.data)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
        return learnerAdded ? learnerAdded : null;
    },

    /**
     * Makes a request to the server to delete a learner
     * that belongs to the connected agent
     * @param idToDelete, the learner id to delete
     */
    async deleteLearner(idToDelete) {
        await axios.delete(baseUrl + '/learner/' + idToDelete + '/delete/', await this.getHeader())
            .then(res => res.data)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
    }
}