/**
 * This file is responsible for requests to the server
 * regarding results group & result cycle of a learner in an evaluation
 */

import axios from 'axios';
import {getLearningGroup} from "../utils/cycleUtils";

const resultCycleUrl = process.env.VUE_APP_AILE_SERVER_API_BASE_URL + 'resultcycle';
const resultGroupUrl = process.env.VUE_APP_AILE_SERVER_API_BASE_URL + 'resultgroup';

export default {
    /**
     * Makes a request to the server to create the result (cycle) of a learner in a test or update it
     * @param idLearner the id of the learner who is passing the test
     * @param idTest the id of the test
     * @param idCycle the id of the cycle
     * @param isPassed the result of the test, true if passed, false if not
     */
    async createOrUpdateResultOfTest(idLearner, idTest, idCycle, isPassed) {
        const body = {
            "id_learner": idLearner,
            "id_test": idTest,
            "id_cycle": idCycle,
            "is_passed": isPassed
        }

        const data = await axios.put(resultCycleUrl, body).then(res => res.data)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
        if (data.status !== "ok") {
            console.log("Could not update results");
        }
    },

    /**
     * Makes a request to the server to create the result (group) of a learner in a test or update it
     * @param is_test_passed : boolean whether the learner passed successfully the test or not
     * @param testId : number the id of the test passed
     * @param idLearner : number the id of the learner
     * @param learningGroup : String the learning group associated with the results
     * @return
     */
    async createOrUpdateResultGroupOfTest(is_test_passed, testId, idLearner, learningGroup) {
        const body = {
            is_test_passed: is_test_passed,
            TestId: testId,
            LearnerId: idLearner,
            learning_group_letter: learningGroup[0],
            learning_group_number: learningGroup[1],
        }

        const result = await axios.put(resultGroupUrl, body)
            .then(res => res.data)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
        return result !== undefined;
    },

    /**
     * Makes a request to the server to get all results of a learner for a given cycle
     * @param idLearner : int the id of the learner
     * @param idCycle : string the id of the cycle
     * @returns {Promise<void>} an array of Results object
     */
    async getResultsCycle(idLearner, idCycle) {
        const results = await axios.get(resultCycleUrl + '/' + idLearner + '/' + idCycle)
            .then(res => res.data)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
        return results !== undefined ? results : undefined;
    },

    /**
     * Makes a request to the server to get all results group of a learner
     * @param idLearner : int the id of the learner
     * @param learningGroupLetter : string the first letter of the learning number
     * @param learningGroupNumber : string the number of the learning group
     * @param isPassed : boolean what results should we fetch
     * @returns {Promise<Array<Object>>} an array of Results object
     */
    async getResultsLearningGroup(idLearner, learningGroupLetter, learningGroupNumber, isPassed) {
        const results = await axios.get(resultGroupUrl, {
            params: {
                idLearner: idLearner,
                learning_group_letter: learningGroupLetter,
                learning_group_number: learningGroupNumber,
                is_test_passed: isPassed
            }
        })
            .then(res => res.data)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });

        /* adds artificials attribute */
        for (let test of results) {
            test.focus = false;
            test.error_count = 0;
        }
        return results !== undefined ? results : undefined;
    },

    /**
     * Makes a request to the server to results whether they are consecutive or not of a learner
     * @param idLearner : int the id of the learner
     * @param learningGroupLetter : string the first letter of the learning number
     * @param learningGroupNumber : string the number of the learning group
     * @param previousCycle : string the id of the previous cycle
     * @returns {Promise<Array<Object>>} an array of Results object
     */
    async getComputedResults(idLearner, learningGroupLetter, learningGroupNumber, previousCycle) {
        const results = await axios.get(resultGroupUrl + '/computed', {
            params: {
                idLearner: idLearner,
                learning_group_letter: learningGroupLetter,
                learning_group_number: learningGroupNumber,
                previous_cycle: previousCycle
            }
        })
            .then(res => res.data)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });

        /* adds artificial focus and error_count attributes */
        for (let res of results) {
            res.focus = false;
            res.error_count = 0;
        }
        return results !== undefined ? results : undefined;
    },

    /**
     * Gets test result with the id passed in parameter of a cycle of a learner
     * @param idTest : number the id of the test
     * @param idCycle : String the id of the cycle
     * @param idLearner : number the id of the learner
     * @return {Promise<boolean>}
     */
    async getTestResult(idTest, idCycle, idLearner) {
        const test = await axios.get(resultCycleUrl + '/' + idTest,
            {
                params: {
                    idCycle: idCycle,
                    idLearner: idLearner
                }
            })
            .then(res => res.data)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
        return test !== undefined ? test : undefined;
    },

    /**
     * Saves tests result of a learner for the learning group of the cycle passed in parameter
     * It computes tests results from current and previous cycle
     * @param idLearner : number the id of the learner
     * @param idCycle : String the code of the cycle
     * @param previousCycleResults the array containing results of the previous cycle, undefined if there are no previous cycle
     * @returns {Promise<void>}
     */
    async savesTestResultsLearningGroup(idLearner, idCycle, previousCycleResults) {
        const resultsArrayCurrentCycle = await this.getResultsCycle(idLearner, idCycle);

        /* loop through current */
        for (let resultCurrent of resultsArrayCurrentCycle) {
            let isPassed = false;
            // If there are previous results
            if (previousCycleResults !== undefined) {
                for (let resultPrevious of previousCycleResults) {
                    if (resultCurrent.TestId === resultPrevious.TestId) {
                        /* Test if two consecutives test are successful*/
                        isPassed = resultCurrent.is_test_passed && resultPrevious.is_test_passed;
                        break;
                    }
                }
            }
            // save results of the test in result group
            await this.createOrUpdateResultGroupOfTest(isPassed, resultCurrent.TestId, idLearner, await getLearningGroup(idCycle));
        }
    },
}