/**
 * This file is responsible for requests to the server regarding text to speech
 */
import axios from 'axios';
import {sleep, transformTextToRead} from "../utils/globalUtils";

const baseUrl = process.env.VUE_APP_AILE_SERVER_API_BASE_URL + 'tts';

/**
 * Makes a request to the Google Text to speech to read the text in parameter
 * @param {string} text the text to read
 * @param {int} speedSpeech the speed of the reading
 * @param rate the rate according to https://www.w3.org/TR/speech-synthesis11/#S3.2.4
 */
async function getTTS(text, speedSpeech, rate) {
    let responseTTS = await axios.post(baseUrl, {text: text, speed: speedSpeech, rate: rate})
        .catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        });
    return responseTTS.data;
}

export {getTTS}

let sources = [];

/**
 * Get TTS from server and play it in browser
 * @param textToRead : string the text to read with TTS
 * @param sleepingAmount : number (ms) the amount of time to wait for the TTS to be fully played. Must be > 0
 * @param speedSpeech : number the speed of the reading between 0 and 1, 1 being normal speed speaking
 * @param rate : string must be format \"number%\". The rate according to {@link https://www.w3.org/TR/speech-synthesis11/#S3.2.4}
 * @param separateWords : boolean true if the text to read are to be pronounced with a separation, undefined || false if not
 */
async function playTTS(textToRead, sleepingAmount, speedSpeech, rate, separateWords = false) {
    textToRead = transformTextToRead(textToRead, separateWords);

    const responseTTS = await getTTS(textToRead, speedSpeech, rate);
    const arrayBuffer = new Uint8Array(responseTTS.audioContent.data).buffer;
    let audioContext = new (window.AudioContext || window.webkitAudioContext)();

    await audioContext.decodeAudioData(arrayBuffer, async function (decodedArrayBuffer) {
        const audioBufferSourceNode = audioContext.createBufferSource();
        sources.push(audioBufferSourceNode);
        audioBufferSourceNode.buffer = decodedArrayBuffer;
        audioBufferSourceNode.start(0);
        audioBufferSourceNode.connect(audioContext.destination);
    });
    await sleep(sleepingAmount);
}

/**
 * Stops all running TTS playing
 * and removes them from sources
 */
function stopAllAudio() {
    for (let i = 0; i < sources.length; i++) {
        if (sources[i]) {
            sources[i].stop(0);
        }
    }
    sources = [];
}

export {playTTS, stopAllAudio};