/**
 * This file is responsible for actions related to the phonetic server
 */
import axios from 'axios'

const baseUrl = process.env.VUE_APP_AILE_PHONETIC_API_BASE_URL + 'phoneticAnalysis';


export default {
    /**
     * Makes a request to the phonetic server to analyse a sentence
     * @param sentences the sentence to analyse
     * @returns {Promise<any>}
     */
    async getAnalysis(sentences) {
        let res = await axios.post(baseUrl, {
            sentence: sentences
        });
        return res.data
    },

    /**
     * Transforms a sentence analyzed by phonetic server into an object containing the sentence split as words
     * Returns an array containing the analyzed sentence object
     * @param sentence the sentence to analyze and transform
     * @param logRule : boolean true to log rules into console false if not
     * @return {Promise<Object>} The transformed Object that contains a sentence and transformed markup
     */
    async getAnalysisAsWords(sentence, logRule = false) {
        const result_array = await axios.post(baseUrl, {sentence: sentence})
            .then(res => res.data)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });

        const sentenceObjectToTransform = result_array[0];

        if (logRule) {
            logRules(sentenceObjectToTransform);
        }

        const sentenceObjectTransformed = getCasing(sentenceObjectToTransform);
        let transformedMarkup = transformMarkup(sentenceObjectTransformed.graphemePhonemes);
        // removes first two grapheme pair of every first word which are two space.
        transformedMarkup[0] = transformedMarkup[0].slice(2);
        return {
            sentence: sentenceObjectToTransform.sentence,
            markup: transformedMarkup
        };
    },
}

/**
 * Moteur phonetique ressort en full minuscules + perd ponctuation et apostrophe
 * Mapping entre le texte brute et le analyzed sentence pour rajouter la ponctuation et majuscules
 * Results are maybe obsolete => function doesn't seem to do anything, must be verified on larger example
 * @param sentenceObjectToTransform
 * @return {*}
 */
function getCasing(sentenceObjectToTransform) {
    let currentCharIndex = 0;
    const sentenceNoSpace = "_" + sentenceObjectToTransform.sentence.replaceAll(" ", "_");

    // loop through every association grapheme phoeneme
    for (let pairGraphemePhoneme of sentenceObjectToTransform.graphemePhonemes) {
        const splitedGraphemeArray = pairGraphemePhoneme.grapheme.split('');

        // loop through every split grapheme
        for (let j = 0; j < splitedGraphemeArray.length; j++) {
            splitedGraphemeArray[j] = sentenceNoSpace.charAt(currentCharIndex);
            currentCharIndex++;
        }
        pairGraphemePhoneme.grapheme = splitedGraphemeArray.join('');
    }
    return sentenceObjectToTransform;
}

/**
 * Logs rules of the analyze sentence passed in parameter
 * @param sentenceObject the object containing the rules used to analyze the sentence
 */
function logRules(sentenceObject) {
    sentenceObject.rules.forEach(rule => {
        console.log(rule.prefix + " [[ " + rule.root + " ]] " + rule.suffix + " --> " + rule.phoneme)
    })
}

/**
 * Transform an array of grapheme phoneme pair into a displayable markup for the component grapheme phonemePair
 * @return *[] : Array<Array<Object>> Array of words containg an array of graphemePhonemes pair
 * @param graphemePhonemesArray : Array<Object> the array of grapheme phoneme to transform
 */
function transformMarkup(graphemePhonemesArray) {
    let transformedMarkupArray = [];
    for (let graphemePhonemePair of graphemePhonemesArray) {
        // If grapheme is space then what follows is a new word -> push array for new word into transformedMarkupArray
        // IE_la_rue -> [__la][__rue]
        if (graphemePhonemePair.grapheme === '_') {
            transformedMarkupArray.push([graphemePhonemePair]);
        }
        // split phoneme into two if it has a space " "
        if (graphemePhonemePair.phoneme.includes(" ")) {
            const phonemes_split = graphemePhonemePair.phoneme.split(" ");
            transformedMarkupArray[transformedMarkupArray.length - 1].push(
                {phoneme: phonemes_split[0], grapheme: graphemePhonemePair.grapheme.replace('_', '')},
                {phoneme: '\\', grapheme: '_'},
                {phoneme: phonemes_split[1], grapheme: '_'},
                {phoneme: '\\', grapheme: '_'});
        }
        // Simply push graphemePhonemePair into the latest word
        else {
            /** uses copy because JS would copy reference if object was pushed */
            transformedMarkupArray[transformedMarkupArray.length - 1].push({
                phoneme: graphemePhonemePair.phoneme,
                grapheme: graphemePhonemePair.grapheme
            });
        }
    }
    return transformedMarkupArray;
}