<!-- This component contains the home view
     This component is displayed when no one
     is logged in, either agent or learner
     !-->

<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-img
          alt="logo_lecture+"
          max-height="500"
          max-width="500"
          src="../../assets/img/Lecture+_Final_logo_Black.jpg"
      ></v-img>
    </v-row>
    <v-row align="center" align-content="center" class="text-center " justify="center">
      <v-card class="my-4 py-4 mx-4" elevation="4" min-width="200px">
        <div>
          <img src="../../assets/img/learner.svg" alt="logo apprentis"/>
        </div>
        <div>
          <v-btn color="success" to='/connexion' x-large>{{ $t('app.i-learn') }}</v-btn>
        </div>
      </v-card>
      <v-card class="my-4 py-4 mx-4" elevation="4" min-width="200px">
        <v-img
            alt="logo_enseignant"
            max-height="500"
            max-width="500"
            src="../../assets/img/teacher.svg"
        ></v-img>
        <div>
          <v-btn color="primary" x-large @click="login">{{ $t('app.i-teach') }}</v-btn>
        </div>
      </v-card>
    </v-row>
    <v-row align="center" justify="center">
    </v-row>
    <v-row align="center" justify="center">
      <sentence-analysis/>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.logo-title {
  font-family: 'Nerko One', serif !important;
  font-size: 500%;
}
</style>

<script>
import SentenceAnalysis from '../TextAnalysis/SentenceAnalysis.vue'

export default {
  name: 'HomeComponent',
  components: {SentenceAnalysis},
  methods: {
    login() {
      this.$auth.loginWithPopup();
    }
  }
}
</script>