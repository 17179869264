<!-- This component contains the view and logic for the learner connection !-->

<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $t("common.connect") }}
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="code"
                  :label="$t('app.access-code')"
              ></v-text-field>
            </v-col>
          </v-row>
          <div v-if="error">
            <v-alert
                color="orange"
                border="top"
                type="error"
            >Nous n'avons pas pu trouver votre code d'accés, veuillez contacter votre enseignant si le problème
              persiste
            </v-alert>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="login">
          {{ $t("common.connection") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import {getLearner, updateLoginNumber} from "../../services/learnerService";
import LearnerBarEvent from "../../event/LearnerBarEvent";

export default {
  name: "LoginComponent",
  data() {
    return {
      code: "",
      error: false,
    };
  },
  methods: {
    /**
     * Login learner, displays error message on failure
     * redirect to /learnerHome on success
     */
    async login() {
      try {
        const learner = await getLearner(this.code);

        if (learner !== null && learner !== undefined) {
          this.$session.start();
          this.$session.set('learnerConnectedCode', learner.code);
          this.displayLoginModalLearner = false;
          LearnerBarEvent.$emit("Logged", true);
          const newLoginNumber = parseInt(await updateLoginNumber(learner.id, learner.login_number + 1));

          // Set values used for Lisons's bank activation
          if (newLoginNumber === 1) {
            localStorage.numberBankEB = 3;
            localStorage.firstLogin = 1;
          } else if (newLoginNumber <= 3) {
            localStorage.numberBankEB = 1;
          } else
            localStorage.numberBankEB = 0;

          this.$router.push('/page-accueil-apprenant/');
        } else {
          this.error = true;
          console.log('Could not connect User');
        }
      } catch (e) {
        console.log(e);
        console.log('Could not connect User');
      }
    },

  }
}
</script>

<style scoped>

</style>