<!-- This component is responsible for playing animations !-->
<template>
  <video
      ref="animationRef"
      :height="height"
      :width="width"
      playsinline
      autoplay
      preload="auto"
      style="pointer-events: none;"
  >
    <source src='' type="video/mp4">
    Sorry, your browser doesn't support embedded videos.
  </video>
</template>

<script>

import {
  FELICITATION_ANIMATION,
  ENCOURAGEMENT_ANIMATION,
  ACCUEIL_DEPART_ANIMATION,
  ECOUTE_ARTICULE_ANIMATION
} from "../../../utils/formulesUtils";

export default {
  name: "AnimationComponent",
  props: ['typeAnimation', 'playAnimation', 'height', 'width'],

  /**
   * Gets video ref
   */
  computed: {
    videoElement() {
      return this.$refs.animationRef;
    },
  },

  watch: {
    /**
     * Updates video source depending on props
     */
    typeAnimation: function () {
      this.getAnimationSource();
    },

    /**
     * Restarts animation when true
     */
    playAnimation: function () {
      if (this.playAnimation === true) {
        this.videoElement.pause();
        this.videoElement.currentTime = 0;
        this.videoElement.load();
      }
    }
  },

  mounted() {
    this.getAnimationSource();
  },

  methods: {
    /**
     * Set video src correctly depending on animation type
     */
    getAnimationSource() {
      switch (this.typeAnimation) {
        case ENCOURAGEMENT_ANIMATION :
          this.videoElement.src = require('../../../assets/animations/encouragement_animation.mp4');
          break;
        case ECOUTE_ARTICULE_ANIMATION :
          this.videoElement.src = require('../../../assets/animations/ecoute_animation.mp4');
          break;
        case ACCUEIL_DEPART_ANIMATION :
          this.videoElement.src = require('../../../assets/animations/wave_animation.mp4');
          break;
        case FELICITATION_ANIMATION :
          this.videoElement.src = require('../../../assets/animations/felicitation_animation.mp4');
          break;
        default :
          this.videoElement.src = require('../../../assets/animations/encouragement_animation.mp4');
      }
    },
  }
}
</script>

<style scoped>

</style>