<!-- This component contains Lisons Static Image !-->

<template>
  <v-img
      :height="250"
      :width="250"
      :max-height="MaxHeight"
      :max-width="MaxWidth"
      :src="require('@/assets/img/Lisons_wave.png')"
      alt="Lisons_avatar"
  />
</template>

<script>

/**
 * This component contains Lisons Static Image
 */
export default {
  name: "LisonsStaticComponent",
  props: {
    MaxHeight: {
      default: 250,
      type: Number
    },
    MaxWidth: {
      default: 250,
      type: Number
    }
  }
}
</script>

<style scoped>

</style>