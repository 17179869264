/**
 * This file contains services related to learner
 */

import axios from 'axios';

const baseUrl = process.env.VUE_APP_AILE_SERVER_API_BASE_URL + 'learner';

/**
 * get a learner from database with the code passed in parameter
 * @param code the code of connection entered by the learner
 * @returns Learner a new Learner model
 */
async function getLearner(code) {
    if (code === "" || code === undefined) {
        return null;
    }
    const arrayRes = await axios.get(baseUrl + '/' + code).then(res => res.data)
        .catch(function (error) {
            if (error.response) {
                // Learner not found
            }
        });
    if (arrayRes.message === "ok") {
        return arrayRes.learner[0];
    } else {
        return null;
    }
}

/**
 * Updates all field of a learner learner
 * @param id : number the id of the learner to update
 * @param code : string the code of the learner to update
 * @param name : string the name of the learner
 * @param agentId : string the agent id of the learner
 * @param cycleId : string the cycleId of the learner
 * @param previousCycleId : string the previous cycle id of the learner
 * @param practiceListNumber : number the number of the practice list the learner is
 * @param tutorialMicrophoneCpt : number
 * @param tutorialLetterCpt : number
 * @param tutorialApostropheCpt : number
 */
async function updateLearner(id, code, name, agentId, cycleId, practiceListNumber, previousCycleId,
                             tutorialMicrophoneCpt, tutorialLetterCpt, tutorialApostropheCpt) {
    // TODO Remove for production
    console.log("new cycle id", cycleId);
    console.log("previousCycleId", previousCycleId);
    // if not specified default is current
    if (previousCycleId === undefined)
        previousCycleId = cycleId;

    const data = {
        code: code,
        name: name,
        agentId: agentId,
        cycleId: cycleId,
        practiceListNumber: practiceListNumber,
        previous_cycle: previousCycleId,
        tutorial_microphone_cpt: tutorialMicrophoneCpt,
        tutorial_letter_cpt: tutorialLetterCpt,
        tutorial_apostrophe_cpt: tutorialApostropheCpt
    }

    try {
        const result = await (await axios.put(baseUrl + '/' + id, data)).data;
        return result.status === 'ok' ? result.updatedModel : null;
    } catch (e) {
        console.log(e);
    }
}

/**
 * Updates login number of the learner with the id passed in parameter
 * @param id : number the id of the learner
 * @param loginNumber : number the value to update
 * @return loginNumberUpdated the update login number
 */
async function updateLoginNumber(id, loginNumber) {
    try {
        /* small front validation */
        if (id < 0 || id === undefined || loginNumber < 0 || loginNumber === undefined)
            return;

        const loginNumberUpdated = await axios.patch(baseUrl + '/' + id + '/login_number', {login_number: loginNumber})
            .then(res => res.data.newLoginNumber)
            .catch(function (error) {
                if (error.response) {
                    // Learner not found
                }
            });
        return loginNumberUpdated !== undefined ? loginNumberUpdated : null;
    } catch (e) {
        console.log(e)
    }
}


export {getLearner, updateLearner, updateLoginNumber}