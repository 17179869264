/**
 * This file is responsible for CRUD request to server on url /rule
 * Mainly used in multiple components in components/rules
 */
import axios from 'axios';
import {getInstance} from '@/auth'
import phoneticAnalysisService from './phoneticAnalysisService'

/**
 * Format rule from phonetic server to display actual the prediction from phonetic server
 * @param rule, the rule to parse
 * @returns {string}
 */
function getPhonetization(rule) {
    let phonetization = '';
    rule.graphemePhonemes.forEach(pair => {
        let phoneme = ''
        if (pair.phoneme === '\\' && pair.grapheme === '_') {
            phoneme = ' ';
        } else if (pair.phoneme !== '\\') {
            if (pair.phoneme === '') {
                phoneme = pair.grapheme
            } else {
                phoneme = pair.phoneme
            }
        }
        phonetization += phoneme;
    })
    return phonetization.trim();
}

const baseUrl = process.env.VUE_APP_AILE_SERVER_API_BASE_URL + 'rule';
const updateRuleFileUrl = baseUrl + '/updatesRuleFile';

export default {
    /**
     * Defines header containing the access token for the REST Server
     * @returns A Header for a request
     */
    async getHeader() {
        const token = await getInstance().getTokenSilently();
        return {
            headers: {
                Authorization: `Bearer ${token}`    // send the access token through the 'Authorization' header
            }
        }
    },

    /**
     * Makes a request to the server to get all active rules
     * @returns allRules all active rules on success, null on failure
     */
    async getRules() {
        const allActiveRules = await axios.get(baseUrl, await this.getHeader()).then(res => res.data)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
        return allActiveRules ? allActiveRules : null;
    },

    /**
     * Makes a request to server to create the rule passed in parameter
     * @param rule the rule to create
     * @returns addedRule, the addedRule on success, null on failure
     */
    async addRule(rule) {
        const addedRule = await axios.post(baseUrl, rule, await this.getHeader()).then(res => res.data)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
        await axios.get(updateRuleFileUrl, await this.getHeader());
        return addedRule ? addedRule : null;
    },

    /**
     * Makes a request to the server to updates a rule
     * @param rule the rule containing the new values
     * @param id the id of the rule to update
     * @returns the updatedRule on success, null on failure
     */
    async editRule(rule, id) {
        const updatedRule = await axios.put(baseUrl + '/' + id, rule, await this.getHeader()).then(res => res.data)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
        return updatedRule ? updatedRule : null;
    },

    /**
     * Make a request to the server to get the history of a rule
     * @param id the id of the rule to fetch history
     * @returns {Promise<any>} the rule history on success, null on failure
     */
    async getRuleHistory(id) {
        const ruleHistory = await axios.get(baseUrl + '/' + id + '/history', await this.getHeader()).then(res => res.data)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
        return ruleHistory ? ruleHistory : null;
    },

    /**
     * Makes a request to the server to delete a rule
     * @param id the id of the rule to delete
     * @returns boolean true on success, false on failure
     */
    async deleteRule(id) {
        await axios.delete(baseUrl + '/' + id, await this.getHeader()).catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                return false;
            }
        });
        await axios.get(updateRuleFileUrl, await this.getHeader());
        return true;
    },

    /**
     * Makes a request to server to get all rule including non active (deleted) ones
     * @returns {Promise<any>} allRules on success, null on failure
     */
    async getRulesIncludeDeleted() {
        const allRules = await axios.get(baseUrl + '/includeDeleted', await this.getHeader()).then(res => res.data)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
        return allRules ? allRules : null;
    },

    /**
     * Makes a request to the server to restore a rule
     * @param id the id of the rule to restore
     * @returns {Promise<any>} the restored rule on success, null on failure
     */
    async restoreRule(id) {
        const restoredRule = await axios.get(baseUrl + '/' + id + '/restore', await this.getHeader()).then(res => res.data)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
        await axios.get(updateRuleFileUrl, await this.getHeader());
        return restoredRule ? restoredRule : null;
    },

    /**
     * get all all rules examples and sends them to the phonetic server to be analysed
     * then compares results with expected
     * @returns {Promise<*>}
     */
    async getTestResults() {
        let allRulesExamples = await axios.get(baseUrl + '/examples', await this.getHeader());
        let examplesServer = allRulesExamples.data.filter(ex => !!ex.Rule);
        let expected = await phoneticAnalysisService.getAnalysis(examplesServer.map(ex => ex.example));
        expected.forEach((element, i) => {
            examplesServer[i].actual = getPhonetization(element);
            examplesServer[i].sucess = examplesServer[i].actual === examplesServer[i].examplePhonetization;
        });
        examplesServer.sort((a, b) => {
            return a.sucess - b.sucess;
        })
        return examplesServer;
    }
}