<!-- This component contains the view & logic for the learner's home !-->

<template>
  <div>
    <v-row>
      <v-col class="ml-5 mt-3" cols="3">
        <LisonsStaticComponent v-if="!startAnimation"></LisonsStaticComponent>
        <AnimationComponent
            v-else
            :height="450"
            :width="450"
            :play-animation="startAnimation"
            :type-animation="typeAnimation"
        ></AnimationComponent>
      </v-col>

      <!-- Image !-->
      <v-row
          v-if="!showHouse"
          class="d-flex justify-center align-center mt-16">
        <v-col v-if="showImageChildren">
          <v-img
              width="450"
              height="450"
              :src="require('@/assets/img/kids_reading.jpg')">
          </v-img>
        </v-col>

        <v-col v-if="showImageAdults">
          <v-img
              width="450"
              height="450"
              :src="require('@/assets/img/adults_reading.jpg')">
          </v-img>
        </v-col>
      </v-row>

      <!-- House !-->
      <v-col v-if="showHouse">
        <SoundHouse
            v-if="learnerParcours && learnerInterGroup"
            :learner-inter-group="learnerInterGroup"
            :learner-parcours="learnerParcours"
            :fill-avance="fillAvance"
            :fill-intermediaire="fillIntermediate"
            :fill-debutant="fillDebutant"
            :is-first-connexion="isFirstConnexion"
            @item-clicked-on="itemClickedListener"
        >
        </SoundHouse>
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
  </div>
</template>

<script>
import AnimationComponent from "../Items/Lisons/AnimationComponent";
import {getTTS, playTTS, stopAllAudio} from "../../services/ttsService";
import {getLearner} from "../../services/learnerService";
import formulesUtils, {
  ACCUEIL_DEPART_ANIMATION,
  FELICITATION_ANIMATION
} from "../../utils/formulesUtils";
import {buildLearnerCurrentUrl} from "../../utils/globalUtils";
import LisonsStaticComponent from "../Items/Lisons/LisonsStaticComponent";
import {BEFORE, CURRENT, getInterGroup, getParcours} from "../../utils/cycleUtils";
import SoundHouse from "../Items/SoundHouse/SoundHouse";
import {transformTextToRead} from "../../utils/globalUtils";

/**
 * This component contains the view & logic for the learner's home
 */
export default {
  name: "HomeLearner",
  components: {SoundHouse, LisonsStaticComponent, AnimationComponent},
  data() {
    return {
      name: null,
      startAnimation: false,
      typeAnimation: '',
      learner: undefined,
      learnerParcours: undefined,
      learnerInterGroup: 0,
      fillAvance: false,
      fillIntermediate: false,
      fillDebutant: false,
      showHouse: false,
      showImageChildren: false,
      showImageAdults: false,
      isFirstConnexion: false,
      tutoriel: false,
    }
  },

  /**
   * If learner is not logged in redirect to login
   * @returns {Promise<Route>}
   */
  beforeCreate() {
    if (!this.$session.exists()) {
      this.$router.push('/connexion');
    }
  },

  /**
   * Starts animation
   * @return {Promise<Route>}
   */
  async created() {
    this.learner = await this.getConnectedLearner();
    this.learnerParcours = getParcours(this.learner.cycleId);
    this.learnerInterGroup = getInterGroup(this.learner.cycleId);
    this.showHouse = parseInt(localStorage.firstLogin) !== 1;
    this.isFirstConnexion = parseInt(localStorage.firstLogin) === 1;
    this.tutoriel = localStorage.backToHome === "true";

    // Fall back to bleu in case of a bug
    let floorName = 'bleu';
    if (this.learnerParcours === 'A') {
      this.fillAvance = true;
      floorName = 'bleu';
    } else if (this.learnerParcours === 'I') {
      this.fillIntermediate = true;
      floorName = 'jaune';
    } else {
      this.fillDebutant = true;
      floorName = 'bleu';
    }
    await this.welcomeLearner(floorName);
  },

  methods: {
    /**
     * Handles event item-clicked-on
     * @param parcoursClicked
     * @param interGroupClicked
     * @param statusClicked
     * @return {Promise<Route>}
     */
    async itemClickedListener(parcoursClicked, interGroupClicked, statusClicked) {
      // If learner clicks on completed intergroup with status BEFORE or CURRENT
      // Redirects him to PracticeList Navigation
      if (statusClicked === BEFORE || (statusClicked === CURRENT && interGroupClicked < this.learnerInterGroup)) {
        stopAllAudio();
        return await this.$router.push({
          name: 'navigation-liste-de-pratique',
          params: {parcours: parcoursClicked, interGroup: interGroupClicked, learnerName: this.learner.name}
        });
      }
      // If learners clicks on his current activity redirects him to current
      else if (interGroupClicked === this.learnerInterGroup && statusClicked === CURRENT) {
        stopAllAudio();
        return await this.$router.push(await buildLearnerCurrentUrl(this.learner));
      } else {
        console.log("Not unlocked");
      }
    },

    /**
     * @param floorName : string the floor name according to the current Parcours of the learner
     * @return {Promise<void>}
     */
    async welcomeLearner(floorName) {
      // First connection
      if (localStorage.firstLogin && parseInt(localStorage.firstLogin) === 1) {
        this.typeAnimation = ACCUEIL_DEPART_ANIMATION;
        this.startAnimation = true;
        await playTTS("Bonjour" + this.learner.name + ". Je m'appelle Lisons !", 3500, 1, "97%");
        this.startAnimation = false;
        await playTTS("On m’appelle Lisons parce que j’adore lire et que j’aide tous ceux qui veulent apprendre" +
            " à lire  !", 4800, 1, "97%");
        await playTTS("comme toi !", 1000, 1, "97%");
        await playTTS("J’ai une voix de robot parce que...", 2000, 1, "97%");
        await playTTS("Je suis un robot !", 1000, 1, "97%");
        await playTTS("Hé oui, je suis un robot qui aime aider à apprendre à lire.", 3500, 1, "97%");
        this.showImageChildren = true;
        await playTTS("J’aide souvent des enfants à lire.", 2000, 1, "97%");
        this.showImageAdults = true;
        await playTTS("Mais j’aide aussi des plus grands. Alors je dis souvent ", 3500, 1, "97%");
        await playTTS("Lisons ensemble !", 2000, 1, "97%");
        await playTTS("C’est pour ça qu’on m’appelle Lisons", 2000, 1, "97%");
        this.showImageChildren = false;
        this.showImageAdults = false;

        this.typeAnimation = FELICITATION_ANIMATION;
        this.startAnimation = true;
        await playTTS("Si tu veux apprendre à lire, je vais t’aider", 3000, 1, "97%");
        this.startAnimation = false;
        await playTTS("À chaque jour, si tu veux, je vais t’aider à lire. Tu verras, avec moi, c’est simple", 6500, 1, "97%");

        await playTTS(this.learner.name + ", voici ta maison de la lecture", 2700, 1, "97%");
        this.showHouse = true;
        await playTTS("Dans ta maison de la lecture, en bleu, il y a l’étage des lettres ordinaires", 3800, 1, "97%");
        this.fillDebutant = true;
        await playTTS("Au-dessus, en jaune, c’est l’étage des lettres spéciales.", 3500, 1, "97%");
        this.fillIntermediate = true;
        await playTTS("Et, tout en haut, en vert, il y a l’étage des défis. ", 4000, 1, "97%");
        this.fillAvance = true;
        await playTTS("Tu vas commencer à l’étage des lettres ordinaires par le premier groupe de sons.", 3500, 1, "97%");
        this.fillIntermediate = false;
        this.fillAvance = false;
        await playTTS("Vasy," + this.learner.name + ", clique sur le carré orange pour trouver le premier groupe de sons.", 0, 1, "97%");
        localStorage.firstLogin = 0;
      } else if (this.tutoriel) {
var constraints = { audio: true }

navigator.mediaDevices.getUserMedia(constraints, async (stream) => {
	console.log(stream);
    let audioContext = new (window.AudioContext || window.webkitAudioContext)();
    let textToRead = transformTextToRead(textToRead, false);

    const responseTTS = await getTTS(textToRead, 1, "97%");
    const arrayBuffer = new Uint8Array(responseTTS.audioContent.data).buffer;
    
    await audioContext.decodeAudioData(arrayBuffer, async function (decodedArrayBuffer) {
        const audioBufferSourceNode = audioContext.createBufferSource();
        audioBufferSourceNode.buffer = decodedArrayBuffer;
        audioBufferSourceNode.start(0);
      audioBufferSourceNode.connect(audioContext.destination);
	});
});
      } else {
        const randomA = await formulesUtils.randomA(this.learner.name);
        this.typeAnimation = ACCUEIL_DEPART_ANIMATION;
        this.startAnimation = true;
        await playTTS(randomA.sentence + "Tu es à l’étage des lettres ordinaires, en " + floorName + ". Et tu es rendu au groupe de sons numéro "
            + this.learnerInterGroup + ", en orange. Si tu veux pratiquer des lectures que tu as déjà vues, clique sur un " +
            "carré vert. Si tu veux plutôt continuer là où tu es rendu, clique sur le carré orange. ", randomA.time, 1, "97%");
        this.startAnimation = false;
      }
    },

    /**
     * Returns the connected learner
     * @returns Learner Object on success or null on failure
     */
    async getConnectedLearner() {
      const code = this.$session.get("learnerConnectedCode");
      const learner = await getLearner(code);

      if (learner !== null && learner !== undefined) {
        return learner;
      } else {
        console.log("Could not get Session !");
      }
    },
  }
}
</script>

<style scoped>

</style>
