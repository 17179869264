<!-- This component is responsible for displaying a
     single word contained in an analysed sentence
     It can contains multiple GraphemePhonemePair.vue !-->

<template>
  <span class="analysedWord">
    <template v-for="(value, index) in word">
      <grapheme-phoneme-pair
          :key="index"
          :grapheme="value.grapheme"
          :phoneme="value.phoneme"
          :showAnalysis="showAnalysis"
          @clickedOn="propagateEvent"
      />
    </template>
  </span>
</template>

<script>
import GraphemePhonemePair from "./GraphemePhonemePair";

export default {
  components: {GraphemePhonemePair},
  name: "AnalysedWord",
  props: ["word", "showAnalysis"],
  methods: {
    /**
     * Propagates to parent clickedOn Event
     */
    propagateEvent() {
      this.$emit("clickedOn");
    }
  }
};

</script>

<style lang="scss" scoped>
.analysedWord {
  margin-left: 1.5rem;
}
</style>