<!-- This component is the representation of the Practice activities of a cycle
     Activities considered as practice are the following : "Leçon" or "Liste de Pratique"
     !-->

<template>
  <v-container
      class="rectangle"
      :class="{
          'green-completed' : isCompleted,
          'orange-current' : isCurrent,
          'white-unavailable' : isUnavailable,
      }">
    <v-row class="justify-center align-center">
      <span class="line"></span>
      <span class="line"></span>
      <span class="line"></span>
      <span class="line"></span>
      <span class="line"></span>
    </v-row>
  </v-container>
</template>
<script>

/**
 * This component is the representation of the Practice activities of a cycle.
 * Activities considered as practice are the following : "Leçon" or "Liste de Pratique"
 */
export default {
  name: "PracticeActivityItem",
  props: {
    learnerIntraGroup: {
      type: Number,
      required: true,
      default: 0
    },
    learnerPracticeListNb: {
      type: Number,
      required: true,
      default: 0
    },
    practiceActivityIntraGroup: {
      type: Number,
      required: true,
      default: 0
    },
  },
  computed: {
    /**
     * Returns true if learner completed the practice activities of the cycle : (Lecçon + 2 Liste de pratique)
     * @return {boolean}
     */
    isCompleted() {
      /* Learner is on the evaluation of the cycle, practice must have been completed */
      if (this.learnerPracticeListNb === 0 && this.practiceActivityIntraGroup === this.learnerIntraGroup) {
        return true;
      } else
        return this.practiceActivityIntraGroup < this.learnerIntraGroup;
    },

    /**
     * Returns true if learner is currently practicing one of the practice Activities
     * @return {boolean}
     */
    isCurrent() {
      return this.learnerPracticeListNb !== 0 && this.practiceActivityIntraGroup === this.learnerIntraGroup;
    },

    /**
     * Returns true if the practiceActivityIntraGroup is after the learner intra group
     * @return {boolean}
     */
    isUnavailable() {
      return this.practiceActivityIntraGroup > this.learnerIntraGroup;
    }
  },
}
</script>

<style scoped>
.rectangle {
  height: 44px;
  width: 80px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  display: flex;
}

.line {
  width: 72px;
  border-bottom: 1px solid black;
}

.green-completed {
  background-color: rgb(153, 255, 102);
}

.orange-current {
  background-color: rgb(255, 204, 0);
}

.white-unavailable {
  background-color: white;
}

</style>