<!-- This component is used to presents the target that a learner did not mastered before practice list activity !-->

<template>
  <div>
    <!-- Top page !-->
    <v-row style="height: 220px">
      <v-col cols="2">
        <LisonsStaticComponent
            class="ml-5"
            v-if="!startAnimation">
        </LisonsStaticComponent>
        <AnimationComponent
            v-else
            :height="400"
            :width="400"
            :play-animation="startAnimation"
            :type-animation="typeAnimation">
        </AnimationComponent>
      </v-col>

      <!-- Activities Row !-->
      <v-col v-if="learner" class="ml-6">
        <v-row no-gutters>
          <v-col cols="1">
            <InterGroupItem
                class="mt-7"
                :inter-group="parseInt(learner.cycleId[1])"
                :parcours-letter="learner.cycleId[0]"
                :learner-current-inter-group="parseInt(learner.cycleId[1])">
            </InterGroupItem>
          </v-col>
          <v-col>
            <ActivitiesRow
                class="mt-8"
                :learner-intra-group="learnerIntraGroup"
                :learner-practice-list-nb="learner.practiceListNumber">
            </ActivitiesRow>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-10" v-if="showTargets">
      <!-- Previous Arrow !-->
      <v-col class="pt-8 pb-8 pl-0" cols="1">
        <PreviousArrow
            :style="{visibility: showPrevArrow ? 'visible' : 'hidden'}"
            @previous="previous"
        >
        </PreviousArrow>
      </v-col>

      <!-- Targets displayed !-->
      <v-col class="ml-4">
        <LetterRow
            :display-all="displayAll"
            :is-first-part-over="isFirstPartOver"
            :items-row="resultsNotPassed"
            @clicked-on="playTextToSpeech"
        >
        </LetterRow>
      </v-col>

      <!-- White Arrow !-->
      <v-col class="text-right pt-8 pb-8" cols="1">
        <NextArrow
            v-if="showNextArrow"
            @next="next"
        >
        </NextArrow>
      </v-col>
    </v-row>

    <!-- Speaker !-->
    <v-row justify="center" style="margin-top: 11%">
      <SpeakerComponent
          v-if="showSpeaker"
          :shake-speaker="shakeSpeaker"
          @speaker-clicked-on="playTextToSpeech(currentTarget.target, false)"
      >
      </SpeakerComponent>
    </v-row>

    <template>
      <SoundTablet
          v-if="learner && learningGroup"
          :sound-tablets-items="resultsPassed"
          :tablet-visible="showTablet"
          :first-name="learner.name"
          :learning-group="learningGroup"
          :consolidation="consolidationPresentation"
          @consolidation-over="consolidationOverListener"
      >
      </SoundTablet>
    </template>

  </div>
</template>

<script>
import {getLearner} from "../../services/learnerService";
import resultService from "../../services/resultsService";
import {DEBUTANT_ONE_FIRST_INITIAL_EVAL, getIntraGroup, getLearningGroup} from "../../utils/cycleUtils";
import SoundTablet from "../Items/Tablet/SoundTablet";
import cycleService from "../../services/cycleService";
import {playTTS, stopAllAudio} from "../../services/ttsService";
import SpeakerComponent from "../Items/TextToSpeech/SpeakerComponent";
import AnimationComponent from "../Items/Lisons/AnimationComponent";
import {playGraphemeAudioFile} from "../../utils/globalUtils";
import {ECOUTE_ARTICULE_ANIMATION} from "../../utils/formulesUtils";
import NextArrow from "../Items/Arrows/NextArrow";
import PreviousArrow from "../Items/Arrows/PreviousArrow";
import LisonsStaticComponent from "../Items/Lisons/LisonsStaticComponent";
import LetterRow from "../TextAnalysis/LetterRow";
import ActivitiesRow from "../Items/ActivitiesRow/ActivitiesRow";
import InterGroupItem from "../Items/SoundHouse/InterGroupItem";

export default {
  name: "TargetsPresentation",
  components: {
    InterGroupItem,
    ActivitiesRow,
    LetterRow,
    LisonsStaticComponent,
    PreviousArrow,
    NextArrow,
    SpeakerComponent,
    SoundTablet,
    AnimationComponent
  },
  data() {
    return {
      learner: null,
      resultsNotPassed: [],
      resultsPassed: [],
      noHighlightClass: 'font-weight-light',
      highlightClass: 'orange--text',
      isFirstPresentationEver: false,
      currentTarget: null,
      indexTarget: -1,
      displayAll: true,
      showSpeaker: false,
      shakeSpeaker: false,
      showTargets: false,
      showNextArrow: false,
      showTablet: false,
      cptClickSpeaker: 0,
      disableArrows: false,
      cptCustomNextInstructions: 0,
      isFirstPartOver: false,
      startAnimation: false,
      typeAnimation: '',
      consolidationPresentation: null,
      disableSpeaker: false,
      learningGroup: '',
      learnerIntraGroup: undefined
    };
  },
  computed: {
    isOnSecondTarget() {
      return this.indexTarget === 1;
    },
    isOnThirdTarget() {
      return this.indexTarget === 2;
    },
    isLastTarget() {
      return this.indexTarget === this.resultsNotPassed.length - 1;
    },
    showPrevArrow() {
      return this.indexTarget !== 0 && !this.isFirstPartOver;
    }
  },

  /**
   * If learner is not logged in redirect to login
   * @returns Route
   */
  beforeCreate() {
    if (!this.$session.exists()) {
      this.$router.push('/connexion');
    }
  },

  /**
   * Get connected learner and sets value to start evaluation
   */
  async created() {
    if (this.$session.exists()) {
      this.learner = await this.getConnectedLearner();
      this.learningGroup = await getLearningGroup(this.learner.cycleId);
      this.learnerIntraGroup = getIntraGroup(this.learner.cycleId);
      await this.initializeItems();
    }
  },

  methods: {

    /**
     * Handler for click on white arrow
     * @return {Promise<void>}
     */
    async next() {
      if (this.disableArrows)
        return;

      // Redirection
      if (this.isFirstPartOver) {
        await playTTS("Super ! Amusons nous maintenant avec des" +
            "mots à lire. Tu vas pouvoir apprendre les sons que nous venons de répéter", 8000, 1, "100%");
        await this.$router.push('/liste-de-pratique/' + this.learner.practiceListNumber);
      }

      // If learner is on the last target and clicks white arrow
      if (this.isLastTarget && !this.isFirstPartOver) {
        stopAllAudio();
        this.disableArrows = true;
        this.playAnimation(ECOUTE_ARTICULE_ANIMATION, 8000);
        this.isFirstPartOver = true;
        this.displayAll = true;
        this.showSpeaker = false;
        await playTTS(this.learner.name + ",voici encore une fois les prochaines lettres à apprendre, si tu  veux" +
            "  écouter à nouveau le son d’une lettre, clique dessus. Si tu veux pratiquer la lecture de mots, clique sur " +
            "la flèche blanche", 8000, 1, "98%");
        this.disableArrows = false;
      }

      /* updates new target */
      if (this.indexTarget !== this.resultsNotPassed.length - 1) {
        this.cptCustomNextInstructions++;
        this.currentTarget.focus = false;
        this.indexTarget++;
        this.currentTarget = this.resultsNotPassed[this.indexTarget];
        this.currentTarget.focus = true;
      }

      // Custom instructions on second target
      if (this.isOnSecondTarget && this.cptCustomNextInstructions === 1) {
        this.disableArrows = true;
        await playTTS("Clique sur le haut-parleur et écoute bien ", 0, 1, "98%");
      }

      // Custom instructions on third target
      if (this.cptCustomNextInstructions === 2 && this.isOnThirdTarget) {
        stopAllAudio();
        await playTTS("Continue seul.", 0, 1, "98%");
      }

      // Learner arrives on last target
      if (this.isLastTarget && !this.isFirstPartOver) {
        await playTTS("Très bien" + this.learner.name + " Si tu sais bien le son que fait cette lettre, " +
            "clique sur la flèche blanche", 0, 1, "98%");
      }

      if (this.cptCustomNextInstructions > 3 && !this.isLastTarget) {
        stopAllAudio();
        await playTTS("Continue.", 0, 1, "98%");
      }

    },

    /**
     * Moves current target to previous
     * @return {Promise<void>}
     */
    async previous() {
      if (this.disableArrows)
        return;

      if (this.indexTarget !== 0 && !this.isFirstPartOver) {
        this.currentTarget.focus = false;
        this.indexTarget--;
        this.currentTarget = this.resultsNotPassed[this.indexTarget];
        this.currentTarget.focus = true;
      }
    },

    /**
     * Plays Text to speech of currentTarget by default or the specified text
     * @param textToRead : string , the text to read if specified
     * @param isLetterClicked : boolean true if the method is called by a click on a letter, false if not
     * @return {Promise<void>}
     */
    async playTextToSpeech(textToRead, isLetterClicked = true) {
      try {
        // Do not allow click on letter before second part is true
        if (isLetterClicked && !this.isFirstPartOver || this.disableSpeaker) {
          return;
        }

        this.disableSpeaker = true;
        await playGraphemeAudioFile(textToRead, 2000);

        // First click on Speaker
        if (this.cptClickSpeaker === 0) {
          this.cptClickSpeaker++;
          await playTTS(" Si tu veux écouter à nouveau, quand je finis de parler, clique sur le haut-parleur. Tu peux écouter le son " +
              "plusieurs fois si tu veux. Pour passer à la lettre suivante", 9500, 1, "98%");
          this.showNextArrow = true;
          await playTTS('clique sur la flèche blanche', 1500, 1, "98%");
        }

        //Second click has on Speaker on Second Target
        if (this.cptClickSpeaker === 1 && this.isOnSecondTarget) {
          this.cptClickSpeaker++;
          setTimeout(() => this.disableArrows = false, 750);
          await playTTS("Super ! Maintenant, clique sur le haut-parleur ou sur la fléche blanche",
              3800, 1, "98%");
        }

        this.disableSpeaker = false;
      } catch (e) {
        this.disableSpeaker = false;
        console.log(e);
      }
    },

    /**
     * Initialize all items needed for the presentation activity
     * @return {Promise<void>}
     */
    async initializeItems() {
      this.isFirstPresentationEver = this.learner.previous_cycle === DEBUTANT_ONE_FIRST_INITIAL_EVAL;

      let resultPassedTmp = [];
      let tmpResultsPassed = []

      // There is not consecutive success if learner had only one initial evaluation,
      // if learner failed at least one evaluation, he might have consecutive success hence the second condition
      if (this.learnerIntraGroup === 1 &&
          getIntraGroup(this.learner.previous_cycle) === 0) {
        this.resultsNotPassed = await cycleService.getTestsCycle(this.learner.cycleId);
      } else {
        this.resultsNotPassed = await resultService.getResultsLearningGroup(this.learner.id, this.learningGroup[0], this.learningGroup[1], false);
        resultPassedTmp = await resultService.getResultsLearningGroup(this.learner.id, this.learningGroup[0], this.learningGroup[1], true);
      }

      // initialize first target
      this.indexTarget = 0;
      this.currentTarget = this.resultsNotPassed[this.indexTarget];
      this.currentTarget.focus = true;

      // Prepare sound tablets items
      for (let result of resultPassedTmp) {
        tmpResultsPassed.push({target: result.target, focus: false, isConsecutive: true});
      }
      this.resultsPassed.push(tmpResultsPassed);

      // Show sound tablet if it is not first presentation and there is at least one item
      this.showTablet = !this.isFirstPresentationEver && this.resultsPassed[this.resultsPassed.length - 1].length !== 0;
      // Will trigger watcher whether true or not, contents depends on boolean value. See SoundTablet.vue for more detail
      this.consolidationPresentation = this.isFirstPresentationEver !== true;
    },

    /**
     * Listener on consolidation finished event
     */
    async consolidationOverListener() {
      // First presentation
      if (this.isFirstPresentationEver) {
        this.showTargets = true;
        await playTTS(this.learner.name + ", avant de commencer à lire, tu vas d’abord écouter le son que fait " +
            "chacune de ces lettres...", 6000, 1, "98%");
        this.displayAll = false;
        await playTTS(".Clique sur le haut-parleur qui bouge et écoute le son que fait cette première lettre", 0, 1, "98%");
        this.showSpeaker = true;
        this.shakeSpeaker = true;
      }
      // Subsequent
      else {
        this.showTablet = false;
        await playTTS(this.learner.name + ",", 750, 1, "98%");
        this.showTargets = true;
        await playTTS(" regarde les prochaines lettres à apprendre.", 2000, 1, "98%");
        this.displayAll = false;
        await playTTS("Pour entendre le son de cette lettre, clique sur le haut-parleur et écoute bien. ", 0, 1, "98%");
        this.showSpeaker = true;
        this.shakeSpeaker = true;
      }
    },


    /**
     * Returns the connected learner
     * @returns Learner Object on success or null on failure
     */
    async getConnectedLearner() {
      const code = this.$session.get("learnerConnectedCode");
      const learner = await getLearner(code);

      if (learner !== null && learner !== undefined) {
        return learner;
      } else {
        console.log("Could not get Session !");
      }
    },

    /**
     * Set animation type and start animation
     * @param animationType the animation type
     * @param timeout the duration of the animation in ms
     */
    playAnimation(animationType, timeout = undefined) {
      this.typeAnimation = animationType;
      this.startAnimation = true
      if (timeout !== undefined) {
        setTimeout(() => {
          this.startAnimation = false;
        }, timeout);
      }
    },

  },
}
</script>

<style scoped>


</style>