/**
 * This file contains Vue configuration and the instantiation of the Vue
 */

import FlashMessage from '@smartweb/vue-flash-message';
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './plugins/router';
import VueSession from 'vue-session';
import VueCookies from 'vue-cookies'
import {Auth0Plugin} from "./auth";
import 'vuetify/dist/vuetify.min.css'
import "material-design-icons-iconfont/dist/material-design-icons.css";
import i18n from './i18n';

/**
 * Auth0 Configuration from config file
 */
import {audience, clientId, domain} from "../auth_config.json";

/**
 * Specify Vue configuration
 */
Vue.use(Auth0Plugin, {
    domain,
    clientId,
    audience,
    onRedirectCallback: appState => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        );
    }
});

Vue.use(VueCookies);

/**
 * https://www.vuescript.com/beautiful-flash-messages-in-vue/
 */
Vue.use(FlashMessage);

/**
 * https://www.npmjs.com/package/vue-session
 */
Vue.use(VueSession);


new Vue({
    vuetify,
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
