<template>
  <v-data-table
      :headers="headers"
      :item-class="itemClass"
      :items="rules"
      :items-per-page="15"
      :search="search"
      class="mx-auto align-middle my-4 w-75 h-75 elevation-2"
      dense
      style="width:80%"
  >
    <br/>
    <template v-slot:top>

      <v-toolbar
          flat
      >
        <br/>
        <v-toolbar-title>Règles phonétiques</v-toolbar-title>
        <v-text-field
            v-model="search"
            class="mx-4"
            label="Rechercher"
        ></v-text-field>
        <v-btn
            class="mx-2"
            color="success"
            @click="newBtnClick">
          {{ $t('admin.rules.add-rule') }}
        </v-btn>

        <v-btn v-if="showDeleted"
               color="blue-grey"
               @click="hideDeletedClick"
        >
          Cacher règles supprimés
        </v-btn>
        <v-btn v-else
               color="blue-grey"
               @click="showDeletedClick"
        >
          {{ $t('admin.rules.show-deleted-rules') }}
        </v-btn>
      </v-toolbar>
      <v-dialog
          v-model="editDialog"
          max-width="70%">
        <v-card>
          <v-card-title>
                <span v-if="editItem.id === 0" class="headline">
                    Nouvelle règle
                </span>
            <span v-else>
                    Modifier la règle
                </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="3" sm="6">
                  <v-text-field
                      v-model="editItem.prefix"
                      label="Préfixe"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="6">
                  <v-text-field
                      v-model="editItem.grapheme"
                      label="Graphème"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="6">
                  <v-text-field
                      v-model="editItem.suffix"
                      label="Suffixe"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="6">
                  <v-text-field
                      v-model="editItem.phoneme"
                      label="Phonème"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="6">
                  <v-textarea
                      v-model="editItem.comment"
                      label="Commentaire"
                      rows="1"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="3" sm="6">
                  <v-select
                      v-model="editItem.type"
                      :items="types"
                      item-text="title"
                      item-value="id"
                      label="Couleur"
                      single-line
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <rule-example-table :items.sync="editItem.examples"/>
                </v-col>
              </v-row>
              <rule-history v-if="editItem.id !== 0" :id="currentItemId" @restore-item='restoreItem'/>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="close"
            >
              Annuler
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="save"
            >
              Sauvegarder
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteDialog" max-width="700px">
        <v-card>
          <v-card-title class="headline">Êtes vous sûr de vouloir supprimer cette règle ?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">Annuler</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">Oui</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:[`item.actions`]="{item}">
      <template v-if="item.deletedAt">
        <v-icon
            class="mr-2"
            small
            @click="restoreRule(item)"
        >
          mdi-restore
        </v-icon>
      </template>
      <template v-else>
        <v-icon
            class="mr-2"
            small
            @click="editRule(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            small
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </template>
    <template v-slot:[`item.examples`]="{item}">
      {{ formatExamples(item) }}
      <!-- <example-column :examples="item.RuleExamples"/> -->
    </template>
  </v-data-table>
</template>

<style lang="scss">
.item-gray {
  background: #cccccc;
}

.regle-complete {
  background: #a7ff96;
}

.regle-jacques {
  background: yellow;
}

.regle-equipe {
  background: rgb(112, 255, 255);
}

.regle-travail {
  background: #ff9696;
}
</style>

<script>
import rulesService from '../../services/rulesService';
import RuleHistory from './RuleHistory.vue';
import RuleExampleTable from './RuleExampleTable.vue';

export default {
  components: {RuleHistory, RuleExampleTable, /*ExampleColumn*/},
  name: "RulesTable",

  data() {
    return {
      search: '',
      headers: [
        {text: 'id', align: 'start', value: 'id', width: '5%'},
        {
          text: 'Préfixe',
          sortable: true,
          value: 'prefix',
          width: '5%'
        },
        {text: 'Graphème', value: 'grapheme', width: '5%'},
        {text: 'Suffixe', value: 'suffix', width: '5%'},
        {text: 'Type', value: 'type', width: '5%'},
        {text: 'Archigraphème', value: 'phoneme', width: '5%'},
        {text: 'Commentaire', value: 'comment', width: '15%'},
        {text: "Exemples", value: "examples", sortable: false},
        {text: 'Actions', value: 'actions', sortable: false, width: '5%'}
      ],

      rules: [],
      editDialog: false,
      deleteDialog: false,
      showDeleted: false,
      editItem: {id: 0, grapheme: "", prefix: "", suffix: "", comment: "", phoneme: "", examples: []},
      currentItemIndex: -1,
      currentItemId: 1,
      types: [
        {title: 'Regle complétée', id: 'regle-complete'},
        {title: 'Règle à voir avec Jacques ', id: 'regle-jacques'},
        {title: 'Règle à voir avec l’équipe ', id: 'regle-equipe'},
        {title: 'Règle à travailler', id: 'regle-travail'},
        {title: 'Blanc', id: 'regle-white'}
      ]
    };

  },
  created() {
    this.getRulesData();
  },
  methods: {
    async getRulesData() {
      this.rules = await rulesService.getRules();
    },
    newBtnClick() {
      this.editDialog = true;
      this.editItem = {id: 0, grapheme: "", prefix: "", suffix: "", comment: "", phoneme: "", examples: []};
    },
    editRule(item) {
      this.editDialog = true;
      this.currentItemIndex = this.rules.indexOf(item);
      this.editItem = Object.assign({}, item);
      this.currentItem = item;
      this.currentItemId = item.id
      this.editItem.examples = [];
      this.editItem.examples = JSON.parse(JSON.stringify(item.RuleExamples)) // deep copy of array
    },
    deleteItem(item) {
      this.deleteDialog = true;
      this.currentItemIndex = this.rules.indexOf(item);
      this.editItem = Object.assign({}, item);
    },
    itemClass(item) {
      return item.type + ' ' + (item.deletedAt ? 'item-gray' : 'item-white');
    },
    close() {
      this.editItem = {id: 0, grapheme: "", prefix: "", suffix: "", comment: "", phoneme: "", examples: []};
      this.currentItemIndex = -1;
      this.editDialog = false;
    },
    closeDelete() {
      this.restoreDefaultEditItem();
      this.deleteDialog = false;
    },
    async deleteItemConfirm() {
      await rulesService.deleteRule(this.editItem.id);
      this.rules[this.currentItemIndex].deletedAt = new Date();
      if (!this.showDeleted) {
        this.rules.splice(this.currentItemIndex, 1)
      }

      this.restoreDefaultEditItem();
      this.deleteDialog = false;
    },
    async save() {
      this.editItem.RuleExamples = this.editItem.examples;
      if (this.editItem.id === 0) {
        var rule = await rulesService.addRule(this.editItem);
        this.rules.push(rule)
        this.currentItem = rule;
      } else {
        await rulesService.editRule(this.editItem, this.editItem.id);
        Object.assign(this.rules[this.currentItemIndex], this.editItem)
      }
      this.currentItem.RuleExamples = this.editItem.examples;
      this.restoreDefaultEditItem()
      this.editDialog = false;
    },
    restoreDefaultEditItem() {
      this.editItem = {id: 0};
      this.currentItemIndex = -1;
    },
    restoreItem(data) {
      this.editItem.prefix = data.prefix;
      this.editItem.grapheme = data.grapheme;
      this.editItem.suffix = data.suffix;
      this.editItem.phoneme = data.phoneme;
      this.editItem.comment = data.comment;
    },
    formatExamples(item) {
      let str = "";
      item.RuleExamples.forEach(example => {
        str += example.example + '->' + example.examplePhonetization + '\t'
      })
      return str;
    },
    async showDeletedClick() {
      this.rules = await rulesService.getRulesIncludeDeleted()
      this.showDeleted = true;
    },
    async hideDeletedClick() {
      this.rules = await rulesService.getRules();
      this.showDeleted = false;
    },
    async restoreRule(item) {
      await rulesService.restoreRule(item.id);
      item.deletedAt = null;
    }
  }
}
</script>