<!-- This components refers to the nav bar after the agent connection  -->

<template>
  <v-container>
    <v-col>
      <v-btn
          text
          to="/learners"
      >
        <v-icon class="mr-4"> mdi-school</v-icon>
        {{ $t('agent.my-learners') }}
      </v-btn>
      <span class="mx-2"/>
      <v-btn
          text
          @click="logout"
      >
        {{ $t('common.disconnect') }}
      </v-btn>
    </v-col>
  </v-container>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
  }
}
</script>