/**
 * This file is responsible for requests to the server
 * regarding Test and Practice model
 */

import axios from 'axios';
import {getInstance} from "../auth";

const testUrl = process.env.VUE_APP_AILE_SERVER_API_BASE_URL + 'test';
const practiceUrl = process.env.VUE_APP_AILE_SERVER_API_BASE_URL + 'practice';

export default {
    /**
     * Defines header containing the access token for the REST Server
     * @returns A Header for a request
     */
    async getHeader() {
        const token = await getInstance().getTokenSilently();
        return {
            headers: {
                Authorization: `Bearer ${token}`,   // send the access token through the 'Authorization' header
                'Content-Type': 'application/json'
            }
        }
    },

    /**
     * Makes a request to the server to get all existing enonce of all practice list
     * @return {Promise<Array<Object>>} The list of all practice list on success.
     */
    async getAllEvaluations() {
        try {
            return await axios.get(testUrl, await this.getHeader())
                .then(res => res.data)
                .catch(function (error) {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                });
        } catch (e) {
            console.log(e);
        }
    },

    /**
     * Makes a request to the server to create an Evaluation for a learning group
     * @param target : string the target of the evaluation
     * @param text : string the text of the evaluation
     * @param phoneme : string the phoneme associated to the evaluation, can be undefined
     * @param cycle_array : Array<String> the cycles of the learning group
     * @param enonce : string A || B
     * @returns
     */
    async createEvaluation(target, text, enonce, phoneme, cycle_array) {
        const evaluation = {
            target: target,
            text: text,
            enonce: enonce,
            phoneme: phoneme,
            cycle_array: cycle_array
        }

        try {
            return await (await axios.post(testUrl, evaluation, await this.getHeader())).data;
        } catch (e) {
            console.log(e);
        }
    },

    /**
     * Makes a request to the server to delete the test with the id passed in parameter
     * @param idToDelete the id of the test list to delete
     * @return boolean true on success, false on error
     */
    async deleteEvaluation(idToDelete) {
        try {
            const result = await (await axios.delete(testUrl + '/' + idToDelete, await this.getHeader())).data;
            return result.status === 'ok';
        } catch (e) {
            console.log(e);
        }
    },

    /**
     * Makes a request to the API to update an evaluation of a learning group
     * Update DOES NOT feature changing an evaluation's learning group.The proper way is to delete the evaluation then
     * create a new one with the correct learning group
     * @param idTest : number the id of the text to update
     * @param target : string the new target
     * @param text : string the new text
     * @param selectedEnonce : string A||B : the new selected enonce
     * @param phoneme : string the phoneme associated to the evaluation, can be undefined
     * @return {Promise<{model: Object, status: boolean}>}
     */
    async updateEvaluation(idTest, target, text, selectedEnonce, phoneme) {
        const evaluationToUpdate = {
            target: target,
            text: text,
            enonce: selectedEnonce,
            phoneme: phoneme
        }

        try {
            return await (await axios.put(testUrl + '/' + idTest, evaluationToUpdate, await this.getHeader())).data;
        } catch (e) {
            console.log(e);
        }
    },

    /**
     * Makes a request to the server to create a practice text associated with a practice list
     * @param text the text to add
     * @param cycleId the cycle of the practice list
     * @param practiceListNumber 1 || 2, the number of the practice list in the cycle
     * @returns
     */
    async createPracticeText(text, cycleId, practiceListNumber) {
        const practiceText = {
            text: text,
            cycleId: cycleId,
            practiceListNumber: practiceListNumber
        }

        try {
            const result = await (await axios.post(practiceUrl, practiceText, await this.getHeader())).data;
            const status = result !== undefined;
            return {
                status: status,
                model: result,
            };
        } catch (e) {
            console.log(e);
        }
    },

    /**
     * Makes a request to the server to get all existing enonce of all practice list
     * @return {Promise<Array<Object>>} The list of all practice list on success.
     */
    async getAllPractice() {
        try {
            return await axios.get(practiceUrl, await this.getHeader())
                .then(res => res.data)
                .catch(function (error) {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                });
        } catch (e) {
            console.log(e);
        }
    },

    /**
     * Makes a request to the server to delete the PracticeList with the id passed in parameter
     * @param idToDelete the id of the practice list to delete
     * @return boolean true on success, false on error
     */
    async deletePractice(idToDelete) {
        try {
            const result = await (await axios.delete(practiceUrl + '/' + idToDelete, await this.getHeader())).data;
            return result.status === 'ok';
        } catch (e) {
            console.log(e);
        }
    },

    /**
     * Makes a request to the api to update a practice text
     * @param idEnonce the id of the text to update
     * @param text the new text
     * @param cycleId the new cycleId
     * @param practiceListNumber the new practice number
     * @return {Promise<{model: Object, status: boolean}>}
     */
    async updatePracticeText(idEnonce, text, cycleId, practiceListNumber) {
        const practiceTextToUpdate = {
            id: idEnonce,
            text: text,
            cycleId: cycleId,
            practiceListNumber: practiceListNumber
        }

        try {
            const result = await (await axios.put(practiceUrl + '/' + idEnonce, practiceTextToUpdate, await this.getHeader())).data;
            const status = result.status === 'ok';

            return {
                status: status,
                model: result.updatedModel
            };
        } catch (e) {
            console.log(e);
        }
    }
}