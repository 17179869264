<!-- This Component is the root of all the application
     It includes the nav bar at the top and below are the component
     that changes according to the user navigation. These
     components can be found in aile-client/src/components.
     Their routing can be found in src/plugins/route.js !-->

<template>
  <v-app class="didact_font">
    <v-app-bar app color="primary" dark height="65">
      <v-col align="left">
        <a href="/">
          <img
              height="65"
              src="./assets/img/Lecture_logo_White.png"
              alt="Logo_LecturePlus"/>
        </a>
      </v-col>
      <v-col align="right">
        <learner-bar v-if="learnerLoggedIn"/>
        <agent-bar v-else-if="$auth.isAuthenticated"/>
        <home-bar v-else/>
      </v-col>
    </v-app-bar>

    <v-main class="didact_font">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<style lang="scss">
@font-face {
  font-family: "Aile Gothic";
  src: local("Aile Gothic"),
  url(assets/font/AileGothic-Regular.ttf) format("truetype");
}

// @import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Nerko+One&display=swap");
$body-font-family: "Aile Gothic", sans-serif;
.logo {
  font-family: "Nerko One", serif;
  font-size: 36pt;
  height: 100%;
  width: 100px;
  text-decoration: none;
  color: white;
}

.didact_font {
  font-family: "Aile Gothic", sans-serif;
}
</style>

<script>
import HomeBar from "./components/Items/Bar/HomeBar.vue";
import AgentBar from "./components/Items/Bar/AgentBar.vue";
import LearnerBar from "./components/Items/Bar/LearnerBar";
import LearnerBarEvent from "./event/LearnerBarEvent";

export default {
  name: "App",

  components: {
    HomeBar,
    AgentBar,
    LearnerBar
  },

  data: () => ({
    learnerLoggedIn: false
  }),
  created() {
    /**
     * Event listener for learner connection
     */
    LearnerBarEvent.$on("Logged", booleanValue => {
      this.learnerLoggedIn = booleanValue;
    });

    /**
     * Default Value of learnerLoggedIn
     */
    this.learnerLoggedIn = this.isLearnerLoggedIn();
  },
  methods: {
    /**
     * Returns if the learner is logged in
     * @returns true if learner is logged in false if not
     */
    isLearnerLoggedIn() {
      return this.$session.exists();
    }
  }
};
</script>
