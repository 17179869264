<template>
  <v-container>
    <v-row align="center"
           justify="center">
      <AnimationComponent
          :height="650"
          :width="650"
          :play-animation="startAnimation"
          :type-animation="typeAnimation"
      >
      </AnimationComponent>
      <FireworksComponent :boxHeight="'100%'" :boxWidth="'100%'"/>
    </v-row>
  </v-container>
</template>

<script>
import formulesUtils, {FELICITATION_ANIMATION} from "../../utils/formulesUtils";
import AnimationComponent from "../Items/Lisons/AnimationComponent";
import FireworksComponent from "./FireworksComponent";
import {playTTS} from "../../services/ttsService";
import {getLearner} from "../../services/learnerService";

export default {
  name: "CongratulationComponent",
  components: {AnimationComponent, FireworksComponent},
  data() {
    return {
      startAnimation: false,
      typeAnimation: '',
    }
  },

  /**
   * If learner is not logged in redirect to login
   * @returns {Promise<Route>}
   */
  beforeCreate() {
    if (!this.$session.exists()) {
      this.$router.push('/connexion');
    }
  },

  /**
   * Starts animation
   */
  async created() {
    this.typeAnimation = FELICITATION_ANIMATION;
    this.startAnimation = true;

    const randomFipObj = await formulesUtils.randomFip((await this.getConnectedLearner()).name);
    await playTTS(randomFipObj.sentence, randomFipObj.time + 1000, 1, "97%");
    return await this.$router.push('/transition-intermediaire');
  },
  methods: {
    /**
     * Returns the connected learner
     * @returns Learner Object on success or null on failure
     */
    async getConnectedLearner() {
      const code = this.$session.get("learnerConnectedCode");
      const learner = await getLearner(code);

      if (learner !== null && learner !== undefined) {
        return learner;
      } else {
        console.log("Could not get Session !");
      }
    },
  }
}
</script>

<style scoped>

</style>