<!-- This component is responsible for displaying all practice list of a parcours and interGroup !-->
<template>
  <div>

    <!-- Top Page !-->
    <v-row>
      <!-- Lisons !-->
      <v-col class="ml-5 mt-3" cols="2">
        <LisonsStaticComponent></LisonsStaticComponent>
      </v-col>

      <!-- InterGroup Item !-->
      <InterGroupItem
          class="mt-12"
          :parcours-letter="parcoursDisplayed"
          :inter-group="interGroupDisplayed"
          :learner-current-inter-group="0"
          :status="'BEFORE'">
      </InterGroupItem>

      <!-- Practice List Navigation Row !-->
      <v-col>
        <PracticeListItemRow
            class="mt-8 ml-4"
            @practice-clicked-on="loadPractice">
        </PracticeListItemRow>
      </v-col>
    </v-row>
    <!-- End Top Page !-->

    <!-- Mid Page !-->
    <v-row class="mt-4">
      <!-- Previous Arrow !-->
      <v-col cols="1" class="text-left pt-8 pb-8 mt-10">
        <PreviousArrow
            v-if="showPrevArrow"
            @previous="previous"
        >
        </PreviousArrow>
      </v-col>

      <!-- Sentence displayed !-->
      <v-col cols="10">
        <template>
          <v-row
              v-if="isFirstLoadingFinished"
              align="center"
              align-content="center"
              justify="center"
          >
            <h1
                style="font-size: 5rem;"
                class="black--text pb-4"
            >
              <AnalysedSentence
                  :sentence="currentSentence"
                  :show-analysis="true">
              </AnalysedSentence>
            </h1>
          </v-row>
        </template>
      </v-col>

      <!-- White Arrow !-->
      <v-col cols="1" class=" pt-8 pb-8 mt-10 pl-0">
        <NextArrow
            v-if="showNextArrow"
            @next="next"
        >
        </NextArrow>
      </v-col>
    </v-row>
    <v-row></v-row>
    <!-- End Mid Page !-->

    <!-- Bottom Page !-->
    <v-row
        align="center"
        align-content="center"
        justify="center"
        class="mt-16"
    >
      <SpeakerComponent
          class="mt-16"
          v-if="isFirstLoadingFinished"
          @speaker-clicked-on="playTextToSpeech(items[currentIndex])"
      >
      </SpeakerComponent>
    </v-row>
  </div>
</template>

<script>
import PracticeListItemRow from "../Items/PracticeNavigation/PracticeListItemRow";
import LisonsStaticComponent from "../Items/Lisons/LisonsStaticComponent";
import InterGroupItem from "../Items/SoundHouse/InterGroupItem";
import SpeakerComponent from "../Items/TextToSpeech/SpeakerComponent";
import NextArrow from "../Items/Arrows/NextArrow";
import PreviousArrow from "../Items/Arrows/PreviousArrow";
import cycleService from "../../services/cycleService";
import AnalysedSentence from "../TextAnalysis/AnalysedSentence";
import {playTTS, stopAllAudio} from "../../services/ttsService";
import {playPracticeAudioFile} from "../../utils/globalUtils";

const MAX_INDEX_PRACTICE_LIST = 21;

/**
 * This component is responsible for allowing leaner to navigate through
 * all the practice list of a parcours and an interGroup
 */
export default {
  name: "PracticeListNavigation",
  components: {
    AnalysedSentence,
    PreviousArrow, NextArrow, SpeakerComponent, LisonsStaticComponent, PracticeListItemRow, InterGroupItem
  },
  props: ['parcours', 'interGroup', 'learnerName'],
  data() {
    return {
      parcoursDisplayed: undefined,
      interGroupDisplayed: undefined,
      nameSpoken: undefined,
      analyzedSentencesArray: [],
      currentIndex: 0,
      currentSentence: '',
      isFirstLoadingFinished: false,
      isLisonsSpeaking: false,
      items: []
    }
  },
  computed: {
    showNextArrow: function () {
      return this.currentIndex !== MAX_INDEX_PRACTICE_LIST && this.isFirstLoadingFinished;
    },
    showPrevArrow: function () {
      return this.currentIndex !== 0 && this.isFirstLoadingFinished;
    }
  },

  /**
   * If learner is not logged in redirect to login
   * @returns {Promise<void>}
   */
  beforeCreate() {
    if (!this.$session.exists()) {
      this.$router.push('/connexion');
    }
  },

  /**
   * Adds event Listener
   */
  async created() {
    this.parcoursDisplayed = this.parcours;
    this.interGroupDisplayed = this.interGroup;
    this.nameSpoken = this.learnerName;
    // Will saves to local storage before users reload page
    await window.addEventListener('beforeunload', this.savesToLocalStorage);
  },

  async mounted() {
    // Props will not be saved on page reload,
    // so if users reloads page, get values from local storage
    if (this.parcoursDisplayed === undefined && localStorage.parcoursSaved) {
      this.parcoursDisplayed = localStorage.parcoursSaved;
    }
    if (this.interGroupDisplayed === undefined && localStorage.interGroupSaved) {
      this.interGroupDisplayed = parseInt(localStorage.interGroupSaved);
    }
    if (this.nameSpoken === undefined && localStorage.nameSaved) {
      this.nameSpoken = localStorage.nameSaved;
    }
    await playTTS("C’est une bonne idée " + this.nameSpoken + " de pratiquer à nouveau des lectures de ce groupe de sons. Clique" +
        " sur la liste de lecture que tu veux pratiquer.", 0, 1, "97%");
  },

  /**
   * Clears local storage
   * Removes EventListener
   */
  beforeDestroy() {
    localStorage.removeItem('parcoursSaved');
    localStorage.removeItem('interGroupSaved');
    localStorage.removeItem('nameSaved');
    window.removeEventListener('beforeunload', this.savesToLocalStorage);
  },

  methods: {
    /**
     * Saves to local storage parcours and interGroup
     */
    savesToLocalStorage() {
      localStorage.parcoursSaved = this.parcoursDisplayed;
      localStorage.interGroupSaved = this.interGroupDisplayed;
      localStorage.nameSaved = this.nameSpoken;
    },

    /**
     * Listener for event practice-clicked-on
     * Loads the Practice List according to user's click
     * @param intraGroupClickedOn : number the intra group clicked on
     */
    async loadPractice(intraGroupClickedOn) {
      try {
        // On first click from learner, Lisons has special behavior
        if (!this.isFirstLoadingFinished) {
          stopAllAudio();
          await playTTS("Tu peux maintenant pratiquer la lecture de cette liste. Si tu veux changer, clique sur" +
              " une autre liste. Si tu veux arrêter, clique sur la petite maison en haut, dans la bande bleu.", 0, 1, "97%");
        }

        this.analyzedSentencesArray = [];
        const cycleId = this.parcoursDisplayed + this.interGroupDisplayed + intraGroupClickedOn;

        // Concats two practice list into one
        let practiceListArray = await cycleService.getPracticeTextCycle(cycleId, 1);
        this.items = practiceListArray.concat(await cycleService.getPracticeTextCycle(cycleId, 2));
        for (let practiceListItem of practiceListArray) {
          this.analyzedSentencesArray.push(practiceListItem.text);
        }

        /* Initialize items */
        this.currentIndex = 0;
        this.currentSentence = this.analyzedSentencesArray[this.currentIndex];
        this.isFirstLoadingFinished = true;
      } catch (e) {
        console.log(e);
      }

    },

    /**
     * Moves currentSentence and currentIndex to next item
     */
    next() {
      if (this.currentIndex !== this.analyzedSentencesArray.length - 1) {
        this.currentIndex++;
        this.currentSentence = this.analyzedSentencesArray[this.currentIndex];
      }
    },

    /**
     * Moves currentSentence and currentIndex to previous item
     */
    previous() {
      if (this.currentIndex !== 0) {
        this.currentIndex--;
        this.currentSentence = this.analyzedSentencesArray[this.currentIndex];
      }
    },

    /**
     * Reads The text passed in parameter in the browser
     * @param practiceTextClicked the text to read
     */
    async playTextToSpeech(practiceTextClicked) {
      try {
        //if Lisons is speaking do nothing
        if (this.isLisonsSpeaking === true)
          return;

        this.isLisonsSpeaking = true;
        //TODO Must remove condition once Lyne has finished recording all practice text
        practiceTextClicked.id <= 440 ?
            await playPracticeAudioFile(practiceTextClicked.id, 1500, this.parcours + this.interGroupDisplayed) :
            await playTTS(practiceTextClicked.text.sentence, 0, 0.95, "50%", true);
        this.isLisonsSpeaking = false;

      } catch (e) {
        console.log(e);
        console.error("Error while trying to read text");
      }
    },

  }
}
</script>

<style scoped>

</style>