<!-- This component is the representation of a row of InterGroupItem Component !-->

<template>
  <v-row>
    <!-- Loop through intergroup !-->
    <v-col
        class="d-flex justify-center"
        v-for="(interGroup) in interGroupMax"
        :key="interGroup">
      <!-- Associate every interGroup to an Item !-->
      <InterGroupItem
          @clicked-on="propagateEvent"
          :inter-group="interGroup"
          :learner-current-inter-group="learnerCurrentInterGroup"
          :parcours-letter="parcoursLetter"
          :is-first-connexion="isFirstConnexion"
          :status="status">
      </InterGroupItem>
    </v-col>
  </v-row>
</template>

<script>
import InterGroupItem from "./InterGroupItem";

/**
 * This component is the representation of a row of InterGroupItem Component
 */
export default {
  name: "InterGroupRow",
  components: {InterGroupItem},
  props: ['learnerCurrentInterGroup', 'parcoursLetter', 'status', 'interGroupMax', 'isFirstConnexion'],
  methods: {
    /**
     * Propagates clicked-on event
     */
    propagateEvent(parcoursClicked, interGroupClicked, statusClicked) {
      this.$emit('item-clicked-on', parcoursClicked, interGroupClicked, statusClicked);
    }
  }
}
</script>

<style scoped>

</style>