<!-- This component is the responsible for displaying the SoundHouse !-->
<template>
  <div>
    <div class="triangle  mx-auto"></div>

    <!-- Avance !-->
    <v-container
        class="rectangle pt-10"
        :class="{'background-green' : fillAvance}"
    >
      <template>
        <InterGroupRow
            :parcours-letter="'A'"
            :learner-current-inter-group="learnerInterGroup"
            :inter-group-max="3"
            :status="statusAvance"
            :is-first-connexion="isFirstConnexion"
            @item-clicked-on="propagateEvent"
        >
        </InterGroupRow>
      </template>
    </v-container>

    <!-- Intermediaire !-->
    <v-container
        class="rectangle no-top pt-10"
        :class="{'background-yellow' : fillIntermediaire}"
    >
      <template>
        <InterGroupRow
            :parcours-letter="'I'"
            :learner-current-inter-group="learnerInterGroup"
            :inter-group-max="7"
            :status="statusIntermediate"
            :is-first-connexion="isFirstConnexion"
            @item-clicked-on="propagateEvent"
        >
        </InterGroupRow>
      </template>
    </v-container>

    <!-- Debutant !-->
    <v-container
        class="rectangle no-top pt-10"
        :class="{'background-blue' : fillDebutant}"
    >
      <template>
        <InterGroupRow
            :parcours-letter="'D'"
            :learner-current-inter-group="learnerInterGroup"
            :inter-group-max="4"
            :status="statusDebutant"
            :is-first-connexion="isFirstConnexion"
            @item-clicked-on="propagateEvent"
        >
        </InterGroupRow>
      </template>
    </v-container>
  </div>
</template>

<script>
import InterGroupRow from "./InterGroupRow";
import {compareParcoursTo} from "../../../utils/cycleUtils";

/**
 * This component is the responsible for displaying the SoundHouse
 */
export default {
  name: "SoundHouse",
  components: {InterGroupRow},
  props: {
    learnerParcours: {
      type: String,
      required: true
    },
    learnerInterGroup: {
      type: Number,
      required: true
    },
    fillAvance: {
      type: Boolean,
      default: false
    },
    fillIntermediaire: {
      type: Boolean,
      default: false
    },
    fillDebutant: {
      type: Boolean,
      default: false
    },
    isFirstConnexion: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    statusAvance() {
      return this.status('A');
    },
    statusIntermediate() {
      return this.status('I');
    },
    statusDebutant() {
      return this.status('D');
    }
  },
  methods: {
    /**
     * Compares parcours passed in parameter with learner's Parcours
     * @param parcours : string the parcours to compare to learner's parcours
     * @return {string}
     */
    status(parcours) {
      return compareParcoursTo(parcours, this.learnerParcours);
    },

    propagateEvent(parcoursClicked, interGroupClicked, statusClicked) {
      this.$emit('item-clicked-on', parcoursClicked, interGroupClicked, statusClicked);
    }
  }
}
</script>

<style scoped>
.background-green {
  background-color: rgb(223, 241, 204);
}

.background-yellow {
  background-color: rgb(255, 255, 153);
}

.background-blue {
  background-color: rgb(153, 204, 255);
}

.triangle {
  width: 0;
  height: 0;
  border-left: 350px solid transparent;
  border-right: 350px solid transparent;
  border-bottom: 200px solid #757575;
}

.rectangle {
  height: 150px;
  width: 600px;
  border-style: solid;
}

.rectangle.no-top {
  border-top: 0 solid black !important;
}

</style>