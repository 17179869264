<!-- This component is the representation of an Evaluation of a cycle !-->
<template>
  <div
      class="oval"
      :class="{
          'green-completed' : isCompleted,
          'orange-current' : isCurrent,
          'white-unavailable' : isUnavailable,
      }"
  >
    <span class="font-weight-bold">
          ?
    </span>
  </div>
</template>

<script>
/**
 * This component is the representation of an Evaluation of a cycle
 */
export default {
  name: "IntraGroupItem",
  props: {
    learnerIntraGroup: {
      type: Number,
      required: true,
      default: 0
    },
    learnerPracticeListNb: {
      type: Number,
      required: true,
      default: 0
    },
    evalIntraGroup: {
      type: Number,
      required: true,
      default: 0
    },
  },
  computed: {
    /**
     * Returns true if evaluation was completed by learner
     * @return {boolean}
     */
    isCompleted() {
      return this.evalIntraGroup < this.learnerIntraGroup;
    },

    /**
     * Returns true if it is the current evaluation of the learner
     * @return {boolean}
     */
    isCurrent() {
      return this.learnerPracticeListNb === 0 && this.evalIntraGroup === this.learnerIntraGroup;
    },

    /**
     * Returns true if evaluation is after the current evaluation
     * @return {boolean}
     */
    isUnavailable() {
      return this.evalIntraGroup > this.learnerIntraGroup;
    }
  },
}
</script>

<style scoped>
.oval {
  font-size: 180%;
  height: 42px;
  width: 80px;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.green-completed {
  background-color: rgb(153, 255, 102);
}

.orange-current {
  background-color: rgb(255, 204, 0);
}

.white-unavailable {
  background-color: white;
}
</style>