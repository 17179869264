<!-- This component is responsible for the nav bar displayed in the Home component
     This nav bar is used when NO one is connected either agent or learner !-->

<template>
  <v-container>
    <v-col>
      <v-btn
          text
          to='/connexion'>
        <v-icon class="mr-4"> mdi-school</v-icon>
        {{ $t('app.i-learn') }}
      </v-btn>
      <v-divider class="" vertical/>

      <v-btn
          text
          @click="login"
      >
        <v-icon class="mr-4" color="white"> work</v-icon>
        {{ $t('app.i-teach') }}
      </v-btn>
    </v-col>
  </v-container>
</template>

<script>

/**
 * This component is responsible for the nav bar displayed in the Home component
 * This nav bar is used when NO one is connected either agent or learner
 */
export default {
  methods: {
    /**
     * Auth authentication
     */
    login() {
      this.$auth.loginWithPopup();
    },

  }
}
</script>