<!-- This component is the transition between Parcours Debutant and Parcours Intermediaire !-->

<template>
  <div>
    <!-- Top Page : Avatar img !-->
    <v-row style="height: 220px">
      <v-col class="ml-5 mt-3" cols="3">
        <LisonsStaticComponent></LisonsStaticComponent>
      </v-col>
      <v-col>
        <SoundHouse
            v-if="learnerParcours && learnerInterGroup"
            :learner-inter-group="learnerInterGroup"
            :learner-parcours="learnerParcours"
            :fill-intermediaire="fillIntermediate"
            :fill-debutant="!fillIntermediate"
        >
        </SoundHouse>
      </v-col>
    </v-row>

    <!-- Middle Page !-->
    <v-container fluid>
      <v-row class="mt-16">
        <!-- Debutant Tablet !-->
        <v-col>
          <SoundTablet
              learning-group="D4"
              :sound-tablets-items="soundTabletsDebutantItems"
              :is-tablet-opaque="isTabletOpaque"
              :letter-to-highlight="letterToHighlight"
          >
          </SoundTablet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SoundTablet from "../Items/Tablet/SoundTablet";
import {getLearner} from "../../services/learnerService";
import LisonsStaticComponent from "../Items/Lisons/LisonsStaticComponent";
import SoundHouse from "../Items/SoundHouse/SoundHouse";
import {getAllLearningGroups, getInterGroup, getParcours} from "../../utils/cycleUtils";
import resultsService from "../../services/resultsService";
import {playTTS} from "../../services/ttsService";

/**
 * This component is the transition between Parcours Debutant and Parcours Intermediaire
 */
export default {
  name: "IntermediateTransition",
  components: {SoundHouse, LisonsStaticComponent, SoundTablet},
  data() {
    return {
      learner: null,
      soundTabletsDebutantItems: [],
      learnerParcours: undefined,
      letterToHighlight: '',
      learnerInterGroup: undefined,
      showSpeaker: false,
      isTabletOpaque: false,
      fillIntermediate: false,
    };
  },
  computed: {},


  /**
   * If learner is not logged in redirect to connexion
   * @returns {Promise<Route>}
   */
  beforeCreate() {
    if (!this.$session.exists()) {
      this.$router.push('/connexion');
    }
  },

  /**
   * Gets connected Learner and starts presentation
   * @return {Promise<void>}
   */
  async created() {
    if (this.$session.exists()) {
      this.learner = await this.getConnectedLearner();
      this.learnerParcours = 'D';
      this.learnerInterGroup = 5;
      await this.populateSoundTablet();
      await this.startIntermediatePresentation();
    }
  },

  methods: {

    /**
     * Populates Sound Tablet
     */
    async populateSoundTablet() {
      const allPrevLearningGroup = getAllLearningGroups('D');
      for (let learningGroup of allPrevLearningGroup) {
        this.soundTabletsDebutantItems.push(await resultsService.getComputedResults(this.learner.id, learningGroup[0], learningGroup[1], this.learner.previous_cycle));
      }
    },

    async startIntermediatePresentation() {
      await playTTS(this.learner.name + ", tu connais déjà le son que font les lettres dans ta tablette des sons.", 5000, 1, "98%");
      this.isTabletOpaque = true;
      await playTTS("Ta tablette des sons est pleine et tu peux maintenant monter à l’étage des lettres spéciales.", 5000, 1, "98%");
      this.fillIntermediate = true;
      this.learnerParcours = getParcours(this.learner.cycleId);
      this.learnerInterGroup = getInterGroup(this.learner.cycleId);
      await playTTS(this.learner.name + ", tu es rendu à l’étage jaune.  À cet étage, il y a sept groupes de lettres " +
          "spéciales à découvrir. Commençons par ce premier groupe en orange. ", 9500, 1, "98%");
      await this.$router.push('/presentation-intermediaire');
    },

    /**
     * Returns the connected learner
     * @returns Learner Object on success or null on failure
     */
    async getConnectedLearner() {
      const code = this.$session.get("learnerConnectedCode");
      const learner = await getLearner(code);

      if (learner !== null && learner !== undefined) {
        return learner;
      } else {
        console.log("Could not get Session !");
      }
    },
  }
}


</script>

<style scoped>

</style>