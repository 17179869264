/**
 * This file is responsible for storing all Lisons sentences
 */

const FELICITATION_ANIMATION = 'Felicitation';
const ENCOURAGEMENT_ANIMATION = 'Encouragement';
const ACCUEIL_DEPART_ANIMATION = 'Accueil_Depart';
const ECOUTE_ARTICULE_ANIMATION = 'Ecoute_Articule';

export {FELICITATION_ANIMATION, ENCOURAGEMENT_ANIMATION, ACCUEIL_DEPART_ANIMATION, ECOUTE_ARTICULE_ANIMATION};

const Fs = [
    {sentence: "     .Bien.", time: 1200},
    {sentence: "    .C’est ça.", time: 1200},
    {sentence: "    .Ok.", time: 1200},
    {sentence: "    .Oui.", time: 1200},
]

const Fo = [
    {sentence: "    .Très bien.", time: 1500},
    {sentence: "    .Très bien #.", time: 1500},
    {sentence: "    .C’est bien, continue.", time: 2000},
    {sentence: "    .C’est bon, tu apprends bien.", time: 2500},
    {sentence: "        .Félicitations #", time: 1700},
    {sentence: "    .Bravo #.", time: 1500},
    {sentence: "    .Ne lâche pas #, tu avances bien.", time: 2500},
    {sentence: "    .Continue #, tu fais du beau travail.", time: 3200},
]

const E = [
    {sentence: "    .Humm. Ce n’est pas grave. Essaie à nouveau.", time: 3000},
    {sentence: "    .Ce n’est pas ça, mais tu vas y arriver. Continue #.", time: 4300},
    {sentence: "    .Bien essayé, mais ce n’est pas la bonne réponse. Essaie encore.", time: 4250},
    {sentence: "    .Non, mais je suis certaine que tu peux réussir.", time: 2700},
    {sentence: "    .Bel effort, #. Fais un nouvel essai.", time: 3000},
    {sentence: "    .Ce n’est pas facile, #, essaie à nouveau.", time: 3000},
    {sentence: "    .Continue tes efforts, #, tu vas y arriver.", time: 3000},
    {sentence: "    .Essaie encore, #. Ensemble, on va l’avoir.", time: 3000},
    {sentence: "    .Essaie de nouveau, #, j’ai confiance que tu peux réussir.", time: 4000},
]

const Ep = [
    {sentence: "    Bien #. Tu avances. Continue à pratiquer.", time: 4500},
    {sentence: "    OK #. Je pense que tu as besoin de plus de pratique.", time: 4500},
    {sentence: "    J’apprécie tes efforts #. Continue à pratiquer ta lecture.", time: 5000},
    {sentence: "    Ça va bien #. Pratique encore un peu.", time: 4500},
    {sentence: "    Continuons, #, ensemble on va y arriver.", time: 4000},
    {sentence: "    Ne lâche pas, #. Tu progresses.", time: 4000},
    {sentence: "    Avec un peu plus de pratique, tu vas encore progresser, #.", time: 5000},
    {sentence: "    Beaux efforts, #. Et si tu continuais à te pratiquer...", time: 4800},
]

const Eb = [
    {sentence: "    .Attention #. Écoute et répète après moi.", time: 3650},
    {sentence: "    .#, écoute bien et répète après moi.", time: 3850},
    {sentence: "    .Écoute moi bien et répète après moi.", time: 3000},
    {sentence: "    Je lis d’abord et tu répètes après moi. Écoute bien.", time: 4000},
    {sentence: "    .Écoute ce que je lis puis, lis-le à ton tour.", time: 3500},
    {sentence: "    .Écoute bien, #, je lis d’abord et tu répètes après.", time: 4150},
    {
        sentence: "    .Ouvre grandes tes oreilles, #, je vais maintenant lire et ensuite tu vas répéter après moi.",
        time: 6500
    },
    {sentence: "    .Travaillons en équipe, #, je lis d’abord et ensuite tu répètes. Écoute ...", time: 5700},
]

const Fig = [
    {sentence: "    .Super #. Tu apprends vite. Je suis fière de toi.", time: 4500},
    {sentence: "    .Bravo #. Ça va très bien. Continue comme ça.", time: 4500},
    {sentence: "    .C’est très bien. Tu peux être fier de toi.", time: 4500},
    {sentence: "    .OH La La. C’est super bien réussi.", time: 3000},
    {
        sentence: "    .Bravo, #, tu progresses vraiment bien. Je vais maintenant te présenter de nouveaux objectifs.",
        time: 6800
    },
    {sentence: "    .Quel beau travail, #. Tu vas maintenant pratiquer de nouveaux apprentissages.", time: 5250},
    {
        sentence: "    .C’est très bien, #. Tu lis de mieux en mieux. Tu peux maintenant apprendre autre chose.",
        time: 6200
    },
    {
        sentence: "    .C’est super, #. Ta lecture progresse. Je vais maintenant te présenter du nouveau à apprendre.",
        time: 6200
    },
]

const Ab = [
    {sentence: "    .J’ai mal entendu. Répète s’il te plaît.", time: 3000},
    {sentence: "    .Je n’ai pas compris. Parle lentement et bien fort.", time: 3000},
    {sentence: "    .Répète s’il te plaît et prononce bien.", time: 3000},
    {sentence: "    .Essaie à nouveau et articule bien.", time: 3000},
    {sentence: "    .Mes oreilles ont besoin que tu parles fort et lentement. Vas-y.", time: 4100},
    {sentence: "    .Tu peux répéter pour moi? N’oublie pas, parle lentement et bien fort.", time: 4100},
    {sentence: "    .J’ai du mal à bien t’entendre. S’il te plait, répète fort et lentement.", time: 4300},
    {
        sentence: "    .J’ai besoin que tu essaies à nouveau. Vas-y, n’oublie pas de parler fort et lentement.",
        time: 4800
    },
]

const Fil = [
    {sentence: "    .C’est très bien #. Je vais te présenter quelque chose de nouveau à lire.", time: 5100},
    {sentence: "    .Je vois que tu aimes lire. Voici du nouveau pour te pratiquer.", time: 4700},
    {sentence: "    .Tu apprends bien #. Je vais te donner de nouvelles lectures.", time: 4800},
    {sentence: "    .Ça va très bien. Pratique-toi avec ces nouvelles lectures.", time: 4800},
    {sentence: "    .Tu avances bien, #. Voici du nouveau pour toi.", time: 4600},
    {sentence: "    .Belle réussite, #. Je te présente maintenant de nouvelles lectures.", time: 5100},
    {sentence: "    .Tu travailles bien, #. Je t’offre de nouvelles lectures.", time: 4800},
    {sentence: "    .Beau progrès, #. Continue avec une nouvelle pratique.", time: 4200},
]

const D = [
    {sentence: "    .Tu veux t’arrêter maintenant, alors à bientôt #.", time: 4600},
    {sentence: "    .OK #, tu as bien travaillé. À la prochaine.", time: 4600},
    {sentence: "    .Très bien #, c’est assez pour cette fois. J’ai hâte de te retrouver.", time: 4600},
    {sentence: "    .C’est bien #, je suis fière de toi. Au plaisir de lire à nouveau avec toi.", time: 4600},
    {sentence: "    .Tu as fait de beaux efforts #. On poursuivra à ton retour.", time: 4600},
    {sentence: "    .Bravo #, on fait une belle équipe. J’ai hâte que tu reviennes lire avec moi.", time: 4600},
    {sentence: "    .Beau travail #. On se reprend à ta prochaine visite.", time: 4600},
    {sentence: "    .Tu mérites une pause, #. Reviens bientôt lire avec moi.", time: 4600},
]

const A = [
    {sentence: "    Bonjour #, je suis Lisons et je suis contente de te revoir.", time: 4600},
    {sentence: "    Allo #, j’espère que tu es en forme pour lire avec moi.", time: 4600},
    {sentence: "    Salut #, lisons ensemble à nouveau.", time: 3700},
    {sentence: "    Comment vas-tu # ?... Viens lire avec moi.", time: 3800},
    {sentence: "    Bienvenue #, je suis ravie de lire de nouveau avec toi.", time: 4600},
    {sentence: "    Bonjour #, prépare-toi à lire avec moi.", time: 3800},
    {sentence: "    Salut #, je suis heureuse que tu viennes lire avec moi.", time: 4600},
    {sentence: "    Allo #, continuons à pratiquer ta lecture.", time: 3800},

]

const Fip = [
    {
        sentence: "    .Toutes mes félicitations, #. Tu as vraiment bien travaillé. Tu arrives à lire tous les sons" +
            " qui remplissent ta tablette. Je vais maintenant pouvoir te présenter de plus grands défis de lecture. " +
            "J’ai bien hâte de commencer ces nouveaux apprentissages avec toi, quand tu seras prêt.", time: 17000
    },
]

export default {

    /**
     * Returns a random object from "Accueil" bank
     * @param learnerName : String the learner name
     * @return {Promise<Object>}
     */
    async randomA(learnerName) {
        const randomObject = A[Math.floor(Math.random() * A.length)];
        randomObject.sentence = randomObject.sentence.replace("#", learnerName);
        randomObject.typeAnimation = ACCUEIL_DEPART_ANIMATION;
        return randomObject;
    },

    /**
     * Returns a random object from "Depart" bank
     * @param learnerName : String the learner name
     * @return {Promise<Object>}
     */
    async randomD(learnerName) {
        const randomObject = D[Math.floor(Math.random() * D.length)];
        randomObject.sentence = randomObject.sentence.replace("#", learnerName);
        randomObject.typeAnimation = ACCUEIL_DEPART_ANIMATION;
        return randomObject;
    },

    /**
     * Returns a random object from "Félicitations simples" bank
     * @return {Promise<Object>}
     */
    async randomFs() {
        return Fs[Math.floor(Math.random() * Fs.length)];
    },

    /**
     * Returns a random object from "Félicitations ordinaires" bank
     * @param learnerName : String the learner name
     * @return {Promise<Object>}
     */
    async randomFo(learnerName) {
        const randomObject = Fo[Math.floor(Math.random() * Fo.length)];
        randomObject.sentence = randomObject.sentence.replace("#", learnerName);
        return randomObject;
    },

    /**
     * Returns a random object from "Encouragement" bank
     * @param learnerName : String the learner name
     * @return {Promise<Object>}
     */
    async randomE(learnerName) {
        const randomObject = E[Math.floor(Math.random() * E.length)];
        randomObject.sentence = randomObject.sentence.replace("#", learnerName);
        randomObject.typeAnimation = ENCOURAGEMENT_ANIMATION;
        return randomObject;
    },

    /**
     * Returns a random object from "Écoute bien" bank
     * @param learnerName : String the learner name
     * @return {Promise<Object>}
     */
    async randomEb(learnerName) {
        const randomObject = Eb[Math.floor(Math.random() * Eb.length)];
        randomObject.sentence = randomObject.sentence.replace("#", learnerName);
        randomObject.typeAnimation = ECOUTE_ARTICULE_ANIMATION;
        return randomObject;
    },

    /**
     * Returns a random object from "Félicitations inter-listes" bank
     * @param learnerName : String the learner name
     * @return {Promise<Object>}
     */
    async randomFil(learnerName) {
        const randomObject = Fil[Math.floor(Math.random() * Fil.length)];
        randomObject.sentence = randomObject.sentence.replace("#", learnerName);
        randomObject.typeAnimation = FELICITATION_ANIMATION;
        return randomObject;
    },

    /**
     * Returns a random object from "Félicitations inter-groupes" bank
     * @param learnerName : String the learner name
     * @return {Promise<Object>}
     */
    async randomFig(learnerName) {
        const randomObject = Fig[Math.floor(Math.random() * Fig.length)];
        randomObject.sentence = randomObject.sentence.replace("#", learnerName);
        randomObject.typeAnimation = FELICITATION_ANIMATION;
        return randomObject;
    },

    /**
     * Returns a random object from "Félicitations inter-parcours" bank
     * @param learnerName : String the learner name
     * @return {Promise<Object>}
     */
    async randomFip(learnerName) {
        const randomObject = Fip[Math.floor(Math.random() * Fip.length)];
        randomObject.sentence = randomObject.sentence.replace("#", learnerName);
        randomObject.typeAnimation = FELICITATION_ANIMATION;
        return randomObject;
    },

    /**
     * Returns a random object from "Encouragement à pratiquer" bank
     * @param learnerName : String the learner name
     * @return {Promise<Object>}
     */
    async randomEp(learnerName) {
        const randomObject = Ep[Math.floor(Math.random() * Ep.length)];
        randomObject.sentence = randomObject.sentence.replace("#", learnerName);
        randomObject.typeAnimation = ENCOURAGEMENT_ANIMATION;
        return randomObject;
    },

    /**
     * Returns a random object from "Articule bien" bank
     * @param learnerName : String the learner name
     * @return {Promise<Object>}
     */
    async randomAb(learnerName) {
        const randomObject = Ab[Math.floor(Math.random() * Ab.length)];
        randomObject.sentence = randomObject.sentence.replace("#", learnerName);
        randomObject.typeAnimation = ECOUTE_ARTICULE_ANIMATION;
        return randomObject;
    },


}


