<template>
  <!-- Middle Page : Apostrophe sentence !-->
  <div :class="{ shake: shakeApostrophe }"
       class="keep-spaces ml-7 mb-0"
       :style="[isFirstApostropheTuto ? firstTutorialApostropheStyle : secondTutorialApostropheStyle]"
  >
    <h1
        style="font-size: 5rem;"
        class="black--text pb-4"
    >
      <ruby>
        {{ displayedSentence }}
        <rt>{{ }}</rt>
      </ruby>
    </h1>
  </div>
</template>

<script>
import {playTTS} from "../../services/ttsService";

export default {
  name: "TutorialApostrophe",
  props: ['sentence', 'isFirstApostropheTuto'],
  data() {
    return {
      displayedSentence: "",
      shakeApostrophe: false,
      firstTutorialApostropheStyle: {
        marginRight: '217.5px',
      },
      secondTutorialApostropheStyle: {
        marginRight: '32.625px',
      }
    }
  },

  async created() {
    this.displayedSentence = this.replaceChar(this.sentence, ["'"]);
    await this.playApostropheTutorial();
  },

  methods: {

    /**
     * Plays apostrophe tutorial then emits event at the end
     * @return {Promise<void>}
     */
    async playApostropheTutorial() {
      !this.isFirstApostropheTuto ?
          await playTTS("Tu te rappelles ?", 1000, 1, "98%") :
          await playTTS("....Regarde le signe qui bouge.", 2500, 1, "96%");

      this.shakeApostrophe = true;
      await playTTS("Quand tu vois ce petit signe, tu ne t’en occupes pas", 3500, 1, "98%");
      await playTTS("Il est juste là pour accompagner un mot", 2500, 1, "98%");
      await playTTS("Comme dans.", 1000, 1, "100%");

      //End tutorial
      this.$emit('apostrophe-tutorial-over');
    },

    /**
     * Replaces in str all string with char EXCEPT those in array arr parameter
     * @param str : string the string that will have its chars replaced
     * @param arr : Array<string> the array containing the char not to replace in str
     * @param char : string the character that will replace all char in str
     * @return {*}
     */
    replaceChar(str, arr, char = " ") {
      return str.split("").map(word => {
        return arr.includes(word) ? word : char;
      }).join("");
    }
  }
}
</script>

<style scoped>

.keep-spaces {
  white-space: pre;
}

.shake {
  animation: shake 6.00s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>