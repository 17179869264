<template>
  <v-data-table
      :headers="headers"
      :item-class="itemClass"
      :items="rules"
      :items-per-page="15"
      :search="search"
      class="mx-auto align-middle my-4 w-75 h-75 elevation-2"
      dense
      style="width:80%"
  >
    <br/>
    <template v-slot:top>

      <v-toolbar
          flat
      >
        <br/>
        <v-toolbar-title>Résultat tests</v-toolbar-title>
        <v-text-field
            v-model="search"
            class="mx-4"
            label="Rechercher"
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:[`item.rule`]="{item}">
      {{ formatRule(item) }}
      <!-- <example-column :examples="item.RuleExamples"/> -->
    </template>
  </v-data-table>
</template>

<style lang="scss">
.item-green {
  background: #a7ff96;
}

.item-red {
  background: #ff9696;
}
</style>

<script>
import rulesService from '../../services/rulesService'

export default {
  props: ['id'],
  data() {
    return {
      search: "",
      headers: [
        {
          text: 'Id règle',
          align: 'start',
          sortable: true,
          value: 'Rule.id'
        },
        {text: 'rège', value: 'rule'},
        {text: 'Exemple', value: 'example'},
        {text: 'Attendu', value: 'examplePhonetization'},
        {text: 'reçu', value: 'actual'}
      ],
      rules: []
    }
  },
  methods: {
    async getData() {
      var res = await rulesService.getTestResults(this.id);
      this.rules = res;
    },
    itemClass(item) {
      return item.actual === item.examplePhonetization ? 'item-green' : 'item-red';
    },
    formatRule(item) {
      return item.Rule.prefix + ' [[ ' + item.Rule.grapheme + ' ]] ' + item.Rule.suffix + ' -> ' + item.Rule.phoneme
    }
  },
  watch: {
    id: async function (newId) {
      // eslint-disable-next-line vue/no-mutating-props
      this.id = newId;
      await this.getData();
    }
  },
  async created() {
    await this.getData();
  }
}
</script>