<!-- This component is the representation of an PracticeList Item !-->

<template>
  <v-card
      class="rounded-0"
      :class="[isSelected ? 'selected' : 'not-selected']"
      width="150"
      height="100"
      @click="emitClickEvent"
  >
    <v-card-title class="justify-center">
      Liste {{ intraGroup }}
    </v-card-title>
  </v-card>
</template>

<script>

/**
 * This component is the representation of an PracticeList Item
 */
export default {
  name: "PracticeListItem",
  props: {
    intraGroup: {
      type: Number,
      default: 0,
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Returns orange if isSelected is true
     * @return {string}
     */
    cardColor() {
      return this.isSelected ? 'orange lighten-2' : 'white';
    }
  },
  methods: {

    /**
     * Emits to Parent event "clicked-on"
     * With arguments
     */
    emitClickEvent() {
      this.$emit('clicked-on', this.intraGroup);
    }
  }
}
</script>

<style scoped>
.theme--light .v-card {
  border-left: 2px solid black !important;
  border-right: 2px solid black !important;
  border-top: 2px solid black !important;
  border-bottom: 2px solid black !important;
}

.not-selected {
  background-color: rgb(153, 255, 102) !important;
}

.selected {
  background-color: rgb(255, 204, 102) !important;
}

</style>