<!-- This component is responsible for adding an Evaluation to Lecture+ !-->

<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-card class="py-6 my-6 elevation-10">
          <v-row>
            <v-col>
              <v-card-title>
                {{ titleForm }}
              </v-card-title>
            </v-col>
            <v-col>
              <p v-if="errors.length !==0" class="red--text"> Veuillez remplir tous les champs les champs !</p>
            </v-col>
          </v-row>
          <v-container>
            <v-row align="center" align-content="center" justify="center">
              <v-col>
                <v-text-field v-model="target" label="Cible"/>
              </v-col>
              <v-col>
                <v-select
                    v-model="selectedLearningGrp"
                    :items="itemsLearning"
                    :disabled="isEditForm"
                    item-text="displayed"
                    item-value="code"
                    label="Groupe d'apprentissage"
                    persistent-hint
                    return-object
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                    v-model="selectedEnonce"
                    :items="enonceNumberList"
                    label="Lettre de l'énoncé"
                />
              </v-col>
              <v-col>
                <v-text-field
                    label="Phonème associé (laissez vide si non applicable)"
                    v-model="phonemeInput">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    label="Énoncé d'évaluation"
                    v-model="enonceEvaluation">
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <!-- Possible actions !-->
          <v-card-actions>
            <v-spacer/>
            <v-btn color="success" @click="save">
              Enregistrer
            </v-btn>
            <v-btn v-if="isEditForm" color="primary" @click="sendCancelToParent">
              Annuler
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <FlashMessage></FlashMessage>
  </v-container>
</template>

<script>

import testService from "../../services/textService";
import flashMessageUtils from "../../utils/flashMessageUtils";
import {getCyclesOfLearningGrp} from "../../utils/cycleUtils";

export default {
  name: "AddEvaluation",
  data() {
    return {
      idTest: -1,
      target: "",
      support: "",
      selectedEnonce: "A",
      enonceNumberList: [
        "A",
        "B"
      ],
      phonemeInput: "",
      enonceEvaluation: "",
      errors: [],
      selectedLearningGrp: {displayed: "Débutant 1", code: "D1"},
      itemsLearning: [
        {displayed: "Débutant 1", code: "D1"},
        {displayed: "Débutant 2", code: "D2"},
        {displayed: "Débutant 3", code: "D3"},
        {displayed: "Débutant 4", code: "D4"},
        {displayed: "Intermédiaire 1", code: "I1"},
        {displayed: "Intermédiaire 2", code: "I2"},
        {displayed: "Intermédiaire 3", code: "I3"},
        {displayed: "Intermédiaire 4", code: "I4"},
        {displayed: "Intermédiaire 5", code: "I5"},
        {displayed: "Intermédiaire 6", code: "I6"},
        {displayed: "Intermédiaire 7", code: "I7"},
        {displayed: "Avancé 1", code: "A1"},
        {displayed: "Avancé 2", code: "A2"}
      ],
      isEditForm: false,
      titleForm: "Ajouter une évaluation",
    }
  },

  methods: {

    /**
     * Sends cancel click to parent
     */
    sendCancelToParent() {
      this.$emit('cancel');
    },

    /**
     * Prepares form for edition on parent click
     * @param itemToEdit
     */
    prepareEdition(itemToEdit) {
      this.idTest = itemToEdit.id;
      this.isEditForm = true;
      this.titleForm = "Modifier une évaluation";
      this.target = itemToEdit.target;
      this.enonceEvaluation = itemToEdit.text;
      this.selectedEnonce = itemToEdit.enonce;
      this.selectedLearningGrp = this.itemsLearning.find(element => element.code === itemToEdit.learningGroup);
      this.phonemeInput = itemToEdit.phoneme
    },

    /**
     * Creates or Update an evaluation
     * @returns
     */
    async save() {
      if (await this.validateData()) {
        //Prepares messages to display
        let title = '';
        let msg = '';
        let result = undefined;

        //Creation
        if (this.idTest === -1) {
          const cycle_array = getCyclesOfLearningGrp(this.selectedLearningGrp.code);
          result = await testService.createEvaluation(this.target, this.enonceEvaluation, this.selectedEnonce, this.phonemeInput, cycle_array);
          title += 'Confirmation de création';
          msg += "L'évaluation a été correctement créé !";
        } else {
          result = await testService.updateEvaluation(this.idTest, this.target, this.enonceEvaluation, this.selectedEnonce, this.phonemeInput);
          title += "Confirmation d'édition";
          msg += "L'évaluation a été correctement modifiée !";
        }

        // Send Success to parent
        if (result.status === 'ok') {
          this.$emit('success', result.model);
          flashMessageUtils.displayMessage(this, 'success', title, msg, 3000);
          this.enonceEvaluation = '';
          this.target = '';
          this.phonemeInput = '';
        } else {
          // display error
          flashMessageUtils.displayMessage(this, 'error', 'Impossible de créer l\'evaluation',
              'Une erreur est survenue lors de la création de l\'évaluation', 3000);
        }
      }
    },

    /**
     * Small validation on input data
     * @returns true if all field are valid, false if not
     */
    async validateData() {
      this.errors = [];

      if (this.target === "") {
        this.errors.push({target: "Veuillez saisir une cible"});
      }
      if (this.selectedEnonce === "") {
        this.errors.push({target: "Veuillez saisir un enoncé"});
      }
      if (this.selectedLearningGrp === undefined) {
        this.errors.push({learningGroup: "Veuillez saisir un groupe d'apprentissage valide"});
      }
      if (this.enonceEvaluation === "" || this.enonceEvaluation === undefined)
        this.errors.push({enonceEval: "Veuillez saisir un énoncé d'évaluation"});

      return this.errors.length === 0;
    },
  },

}
</script>

<style lang="scss">
.tiptap-border {
  border-color: black;
}

.ProseMirror {
  font-size: 150%;
  margin-top: 2em;
  padding-top: 1em;
  padding-left: 0.25em;
  word-spacing: 0.3em;

  > * + * {
    margin-top: 1em;
  }
}
</style>