<!-- This component is responsible for displaying a single analyzed sentence
     It can contains multiple AnalysedWord.vue !-->

<template>
  <span class="analysedSentence" @click="toggleAnalysis">
    <template v-for="(value, index) in sentence.markup">
      <analysed-word :key="index" :showAnalysis="showAnalysis" :word="value"
                     @clickedOn="propagateEvent(index)"
      />
    </template>
  </span>
</template>

<script>
import AnalysedWord from "./AnalysedWord.vue";

/**
 * This component is responsible for displaying a single analyzed sentence
 * It can contain multiple AnalysedWord.vue
 */
export default {
  components: {AnalysedWord},
  name: "AnalysedSentence",
  props: ["sentence", "showAnalysis"],
  data() {
    return {
      analysis: false
    };
  },

  mounted() {
    if (this.showAnalysis) {
      this.analysis = true;
    }
  },

  methods: {
    /**
     * Handlers when analysis help os clicked
     */
    toggleAnalysis() {
      this.analysis = !this.analysis;
    },
    /**
     * Propagates to parent clickedOn Event
     */
    propagateEvent(wordIndexClickedOn) {
      this.$emit("clickedOn", wordIndexClickedOn);
    }
  }
};
</script>

<style lang="scss" scoped>
.analysedSentence {
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-wrap: wrap;
  user-select: none;
}
</style>