var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tabletVisible)?_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"rounded-xl",class:[_vm.isTabletOpaque ? 'opaque' : '', 'tablet',
              _vm.learningGroupLetter === 'D' ? 'debutant' : 'intermediate'],attrs:{"height":_vm.heightTablet,"width":_vm.widthTablet}},[_c('v-row',{style:({height: _vm.heightRow + 'px'})}),_vm._l((_vm.soundTabletsItems.slice().reverse()),function(learningGroup,index){return _c('v-row',{key:index,attrs:{"dense":""}},_vm._l((learningGroup),function(displayedItem,index){return _c('v-col',{key:index,attrs:{"align":"center"}},[_c('span',{staticClass:"font-weight-bold",class:[
                      displayedItem.focus === true && _vm.consolidation ? _vm.orangeLetterClass : '',
                      displayedItem.isConsecutive || displayedItem.isConsecutive === undefined ? _vm.consecutiveClass : _vm.notConsecutiveClass
                  ],style:([
                      displayedItem.target === _vm.letterToHighlight && _vm.applyCircleHighlight ? _vm.circleStyle : '',
                      displayedItem.target !== _vm.letterToHighlight && _vm.isTabletOpaque ? _vm.opacityStyle : _vm.noOpacityStyle,
                      {visibility: displayedItem.target === '-' ? 'collapse' : 'visible'},
                      _vm.learningGroupLetter !== 'D' ? _vm.learningGroupLetter === 'I' ? _vm.intermediateStyle : _vm.advancedStyle : _vm.debutantStyle

                  ])},[_vm._v(" "+_vm._s(displayedItem.target)+" ")])])}),1)})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }