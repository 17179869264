<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="practiceListArray"
        sort-by="id"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>Table des listes de pratiques</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-btn class="success" href="#enonce-pratique">
            Ajouter une liste de pratique
          </v-btn>

          <!-- Edit dialog !-->
          <v-dialog
              width="1180"
              v-model="dialog">
            <AddPractice
                ref="editForm"
                @cancel="close"
                @success="saveSuccess">
            </AddPractice>
          </v-dialog>

          <!-- Delete dialog !-->
          <v-dialog v-model="dialogDelete" max-width="570px">
            <v-card>
              <v-card-title class="text-h5">Êtes-vous sûr de vouloir supprimer cet énoncé ?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="error" text @click="closeDelete">Annuler</v-btn>
                <v-btn class="success" text @click="deleteItemConfirm">Confirmer</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="prepareEditItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            small
            @click="prepareDeleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        Pas de données.
      </template>
    </v-data-table>

    <!-- Add Evaluation !-->
    <div id="enonce-pratique">
      <AddPractice
          @success="saveSuccess">
      </AddPractice>
    </div>

  </div>
</template>

<script>

import AddPractice from "./AddPractice";
import textService from "../../services/textService";
import {getIntraGroup, getLearningGroup} from "../../utils/cycleUtils";
import flashMessageUtils from "../../utils/flashMessageUtils";

/**
 * This component is responsible for CRUD operation regarding PracticeLists
 * This component was written with the help of Vuetify documentation
 * See here : https://vuetifyjs.com/en/components/data-tables/#crud-actions
 * for more information
 */
export default {
  name: "PracticeListTable",
  components: {AddPractice},
  data: () => ({
    dialogDelete: false,
    dialog: false,
    headers: [
      {
        text: "id",
        align: 'start',
        value: 'id',
      },
      {text: "Groupe d'apprentissage", value: 'learningGroup', sortable: true},
      {text: 'Énoncé de pratique', value: 'enonce'},
      {text: 'Numéro de la liste de pratique', value: 'displayedNumList'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    practiceListArray: [],
    editedIndex: -1,
    editedItem: {
      id: -1,
      learningGroup: 0,
      enonce: 0,
      displayedNumList: 0,
    },
    defaultItem: {
      id: -1,
      learningGroup: 0,
      enonce: 0,
      displayedNumList: 0,
    },
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  /**
   * Populates DataTable
   */
  async created() {
    await this.populateDataTable();
  },

  methods: {
    /**
     * Gets All Practice List
     */
    async populateDataTable() {
      const allPracticeList = await textService.getAllPractice();
      for (let listObject of allPracticeList) {
        await this.convertsObjectIntoDisplayable(listObject);
      }
      this.practiceListArray = allPracticeList;
    },

    /**
     * Converts listObject passed in parameter into the format used in the datatable
     * @param listObject the object to format
     */
    async convertsObjectIntoDisplayable(listObject) {
      listObject.text = JSON.parse(listObject.text);
      listObject.enonce = listObject.text.sentence;
      listObject.learningGroup = await getLearningGroup(listObject.cycleId);
      listObject.displayedNumList = await this.getNumList(listObject.practiceListNumber, listObject.cycleId);
    },

    /**
     * Converts practiceListNumber from database : 1 || 2
     * into list number fitted for display : [1-10]
     * @param currentListNumber
     * @param cycleId
     * @return {Promise<number>} the new practice list number
     */
    async getNumList(currentListNumber, cycleId) {
      const intra = getIntraGroup(cycleId);
      let newListNumber = intra * 2;
      if (currentListNumber === 1) {
        newListNumber--;
      }
      return newListNumber;
    },

    /**
     * Prepares item edition and opens modal
     * @param item
     */
    prepareEditItem(item) {
      this.dialog = true;
      this.editedIndex = this.practiceListArray.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$nextTick(() => {
        this.$refs.editForm.prepareEdition(this.editedItem);
      });
    },

    /**
     * Opens delete dialog and stores item & index to delete
     * @param itemToDelete the item to delete
     */
    prepareDeleteItem(itemToDelete) {
      this.editedIndex = this.practiceListArray.indexOf(itemToDelete);
      this.editedItem = Object.assign({}, itemToDelete);
      this.dialogDelete = true;
    },

    /**
     * Deletes item passed in parameter
     * and closes dialog
     */
    async deleteItemConfirm() {
      const result = await textService.deletePractice(this.editedItem.id);

      // success
      if (result === true) {
        flashMessageUtils.displayMessage(this, 'success', 'Confirmation de suppression',
            'L\'énoncé de la liste de pratique a été correctement supprimé !', 3000);
        this.practiceListArray.splice(this.editedIndex, 1);
      }
      //failure
      else {
        flashMessageUtils.displayMessage(this, 'error', 'Impossible de supprimer l\'énoncé de la liste de pratique',
            "Une erreur est survenue lors de la suppresion de l'évaluation. Veuillez contacter l'administrateur" +
            " si le problème persiste", 4500);
      }
      this.closeDelete();
    },

    /**
     * Resets edited item to default
     */
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.$refs.editForm.isSentenceAnalyzed = false;
        this.$refs.editForm.practiceSentenceUnanalyzed = '';
        this.$refs.editForm.practiceSentenceAnalyzed = undefined;
        this.$refs.editForm.graphemePhonemePairArray = [];
      });
    },

    /**
     * Closes modal and resets editItem
     */
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      })
    },

    /**
     * Listener for success event emitted by AddPractice Component
     * Behavior depends on edit or creation.
     */
    async saveSuccess(createdOrEditedEnonce) {
      await this.convertsObjectIntoDisplayable(createdOrEditedEnonce);
      // If it was an edit, update values
      if (this.editedIndex > -1) {
        Object.assign(this.practiceListArray[this.editedIndex], createdOrEditedEnonce);
      }
      // Creation
      else {
        this.practiceListArray.push(createdOrEditedEnonce);
      }
      this.close();
    },
  }

}
</script>

<style scoped>

</style>