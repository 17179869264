<!-- This file is responsible for CRUD operations regarding a learner !-->

<template>
  <div v-if="conditionCheckDone">
    <div v-if=authorized>
      <v-data-table
          :headers="headers"
          :items="learners"
          :items-per-page="15"
          :search="search"
          class="mx-auto align-middle my-4 w-75 h-75 elevation-2"
          dense
          style="width:80%"
      >
        <br/>
        <template v-slot:top>
          <v-toolbar flat>
            <br/>
            <v-toolbar-title>{{ $t("agent.my-learners") }}</v-toolbar-title>
            <v-text-field
                v-model="search"
                class="mx-4"
                label="Rechercher"
            ></v-text-field>
            <v-btn class="mx-2" color="success" @click="newBtnClick">
              {{ $t("agent.add-learner") }}
            </v-btn>
          </v-toolbar>
          <v-dialog v-model="editDialog" max-width="70%">
            <v-card>
              <v-card-title>
              <span v-if="editItem.id === 0" class="headline">
                {{ $t("agent.new-learner") }}
              </span>
                <span v-else>
                {{ $t("agent.modify-learner") }}
              </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="3" sm="6">
                      <v-text-field
                          v-model="editItem.name"
                          :label="$t('student.name-or-surname')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cancelEditOrCreate">
                  {{ $t("common.cancel") }}
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  {{ $t("common.save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="deleteDialog" max-width="700px">
            <v-card>
              <v-card-title class="headline">{{
                  $t("agent.confirm-delete-learner")
                }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">{{
                    $t("common.cancel")
                  }}
                </v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{
                    $t("common.yes")
                  }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" small @click="editRule(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </div>
    <div v-else>
      Vous devez payer pour avoir accés à cette fonctionnalité
    </div>
  </div>
</template>

<style lang="scss">
.item-gray {
  background: #cccccc;
}

.item-white {
}
</style>

<script>
import agentService from "../../services/agentService";

export default {
  components: {},
  name: "LearnerTable",
  data() {
    return {
      search: "",
      headers: [
        {
          text: this.$t("student.name-or-surname"),
          align: "start",
          value: "name"
        },
        {text: this.$t("app.access-code"), value: "code"},
        {text: "Actions", value: "actions"}
      ],
      texts: [],
      learners: [],
      agent: {},
      editDialog: false,
      deleteDialog: false,
      showDeleted: false,
      editItem: {id: 0},
      currentItemIndex: -1,
      currentItemId: 1,
      authorized: false,
      conditionCheckDone: false // trick not to display <div else> a fraction of second
    };
  },
  async created() {
    await this.isAuthorized();
    this.conditionCheckDone = true;
    await this.getLearnersData();
  },
  methods: {
    async getLearnersData() {
      this.learners = await agentService.getLearners();
    },

    async isAuthorized() {
      this.agent = await agentService.getOrCreateAgent();
      this.authorized = this.agent.hasPaid;
    },

    newBtnClick() {
      this.editDialog = true;
    },

    /**
     * handler when user clicks on edit icon in a row
     * @param item
     */
    editRule(item) {
      this.editDialog = true;
      this.currentItemIndex = this.learners.indexOf(item);
      this.editItem = Object.assign({}, item);
      this.currentItem = item;
      this.currentItemId = item.id;
    },

    /**
     * handler when user clicks on delete icon in a row
     * @param item row
     */
    deleteItem(item) {
      this.deleteDialog = true;
      this.currentItemIndex = this.learners.indexOf(item);
      this.editItem = Object.assign({}, item);
    },

    /**
     * Handler when user clicks on cancel in modify or create Modal
     */
    cancelEditOrCreate() {
      this.editItem = {id: 0};
      this.currentItemIndex = -1;
      this.editDialog = false;
    },

    /**
     * Handler when user clicks on cancel in delete Modal
     */
    closeDelete() {
      this.restoreDefaultEditItem();
      this.deleteDialog = false;
    },

    /**
     * Handler when users click on delete in delete Modal
     */
    async deleteItemConfirm() {
      await agentService.deleteLearner(this.editItem.id);
      this.learners[this.currentItemIndex].deletedAt = new Date();
      if (!this.showDeleted) {
        this.learners.splice(this.currentItemIndex, 1);
      }

      this.restoreDefaultEditItem();
      this.deleteDialog = false;
    },

    /**
     * Handler when users click on save in edit or create Modal
     */
    async save() {
      /* on veut ajouter */
      if (this.editItem.id === 0) {
        const learnerAdded = await agentService.addLearner(this.editItem);
        this.learners.push(learnerAdded);
      }
      /* on veut editer */
      else {
        await agentService.editLearner(this.editItem, this.editItem.id);
        Object.assign(this.learners[this.currentItemIndex], this.editItem);
      }
      this.restoreDefaultEditItem();
      this.editDialog = false;
    },

    restoreDefaultEditItem() {
      this.editItem = {id: 0};
      this.currentItemIndex = -1;
    },

    restoreItem(data) {
      this.editItem.prefix = data.prefix;
      this.editItem.grapheme = data.grapheme;
      this.editItem.suffix = data.suffix;
      this.editItem.phoneme = data.phoneme;
      this.editItem.comment = data.comment;
    }
  }
};
</script>