<!-- This component is a responsible for displaying
     a pair of phoneme and grapheme !-->

<template>
  <!-- If learners does toggle Analysis then just displays grapheme and its phoneme above !-->
  <span
      v-if="showAnalysis"
      class="user-select-none"
      @click="propagateClick"
      v-bind:class="{ redText: isRed, silverText: isSilver, blueText: isBlue }"
  >
    <ruby>{{ graphemeSpace }}<rt>{{ phonemeComp }}</rt></ruby>
  </span>

  <!-- If learners does not toggle Analysis then just displays grapheme !-->
  <span
      v-else
      class="user-select-none"
  >
    <ruby>{{ graphemeSpace }}<rt></rt></ruby>
  </span>
</template>

<style lang="scss" scoped>
.redText {
  color: red;
}

.silverText {
  color: silver;
}

.blueText {
  color: #133eff;
}
</style>

<script>
import {isPunctuation, isSilent, isVowel} from "../../utils/phonemeFormat";

/**
 * This component serves as the most atomic part of a phonetically analyzed sentence or word
 * it will display the grapheme with the phoneme superscripted above it
 *
 * it uses the <ruby> html element to do so see: https://www.w3schools.com/tags/tag_ruby.asp
 */
export default {
  name: "grapheme-phoneme-pair",
  props: ["phoneme", "grapheme", "showAnalysis"],

  computed: {
    /**
     * Returns the current grapheme, if current is underscore, replaces "_" by space
     */
    graphemeSpace: function () {
      const NON_BREAKING_SPACE = "\xa0";
      return this.grapheme.replaceAll("_", NON_BREAKING_SPACE);
    },
    /**
     * This function shows whether we show the output or not it's also responsible for formating purpuses if the phoneme
     * has an half-space in it
     */
    phonemeComp: function () {
      if (this.phoneme /*.toLowerCase()*/ === this.grapheme /*.toLowerCase()*/ || this.phoneme === "\\") {
        return "";
      }
      const NON_BREAKING_SPACE = "\xa0";
      return this.phoneme.replace("=", NON_BREAKING_SPACE);
    },
    /**
     *
     */
    isRed: function () {
      return (
          isVowel(this.phoneme) &&
          this.grapheme.toLowerCase() !== this.phoneme.toLowerCase()
      );
    },

    isBlue: function () {
      return (
          !isVowel(this.phoneme) &&
          !isSilent(this.grapheme, this.phoneme) &&
          !(this.grapheme === this.phoneme) &&
          !isPunctuation(this.grapheme)
      );
    },
    /**
     *
     */
    isSilver: function () {
      return isSilent(this.grapheme, this.phoneme);
    },
  },
  methods: {
    /**
     * Observer on a click, emits clickedOn Event
     */
    propagateClick() {
      this.$emit("clickedOn");
    },
  }
};
</script>