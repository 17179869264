<!-- This component is responsible for displaying in a row letters !-->

<template>
  <div>
    <v-row v-if="!isIntermediate">
      <v-col
          v-for="(item, index) in ItemsRow"
          :key="index"
          class="d-flex justify-center"
      >
        <strong
            @click="emitClickEvent(item.target)"
            :class="{'target' : isFirstPartOver }"
            class="font-weight-light big-letters"
            :style="[{opacity : displayAll ||  (!displayAll && item.focus) ? '1' : '0.1'}]"
        >
          {{ item.target }}
        </strong>
      </v-col>
    </v-row>
    <v-container v-else>
      <v-row no-gutters>
        <v-col cols="1"></v-col>
        <v-col
            v-for="(item, index) in ItemsRow"
            :key="index"
            class="d-flex justify-center big-letters"
            :class="{'opaque-letter' : !displayAll && (displayAll || !item.focus)}"
        >
          <grapheme-phoneme-pair
              :grapheme="item.target"
              :phoneme="item.phoneme"
              :show-analysis="item.showPhonetique">
          </grapheme-phoneme-pair>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
    </v-container>

  </div>

</template>

<script>

import GraphemePhonemePair from "./GraphemePhonemePair";

/**
 * This component is responsible for displaying in a row letters
 */
export default {
  name: "LetterRow",
  components: {GraphemePhonemePair},
  props: {
    ItemsRow: {
      type: Array,
      required: true
    },
    isFirstPartOver: {
      type: Boolean,
      required: true
    },
    displayAll: {
      type: Boolean,
      required: true
    },
    isIntermediate: {
      type: Boolean,
      default: false
    }
  },
  methods: {

    /**
     * Emits to Parent event "clicked-on"
     * @param target
     */
    emitClickEvent(target) {
      //Params are
      // target of the item clicked
      // boolean signifies that it was click on by mouse
      this.$emit('clicked-on', target, true);
    }
  }
}
</script>

<style scoped>

.target:hover {
  color: orange;
}

.opaque-letter {
  opacity: 0.1;
}

.big-letters {
  font-size: 333% !important;
}

</style>