/**
 * This file contains configuration of the routing fo the client
 * See here https://router.vuejs.org/guide/#html for more information
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeComponent from '@/components/Home/Home';
import RulesTable from '../components/Rules/RulesTable';
import TestResult from '../components/Rules/TestResults';
import {authGuard} from '@/auth/authGuard';
import LoginComponent from "../components/Learners/Login";
import LogoutComponent from "../components/Learners/Logout";
import InitialEvaluation from "../components/Evaluation/InitialEvaluation";
import EvaluationTable from "../components/Evaluation/EvaluationTable";
import AddEvaluation from "../components/Evaluation/AddEvaluation";
import AddPractice from "../components/Practice/AddPractice";
import PracticeList from "../components/Practice/PracticeList";
import LearnerTable from '../components/Learners/LearnerTable';
import TargetPresentations from '../components/Practice/TargetsPresentation';
import RegularEvaluation from '../components/Evaluation/RegularEvaluation';
import CongratulationComponent from "../components/Transition/CongratulationComponent";
import LearnerHome from "../components/Home/LearnerHome";
import PracticeListTable from "../components/Practice/PracticeListTable";
import IntermediateTransition from "../components/Transition/IntermediateTransition";
import PracticeListNavigation from "../components/Practice/PracticeListNavigation";
import HomeTutorial from "../components/Transition/HomeTutorial";
import IntermediatePresentation from "../components/Practice/IntermediatePresentation";

Vue.use(VueRouter)

/**
 * Map url to components
 */
const routes = [

    /**
     * Home page
     */
    {path: '/', component: HomeComponent},
    /**
     * Learner routes
     */
    {path: '/connexion', component: LoginComponent},
    {path: '/deconnexion/:name', component: LogoutComponent},
    {path: '/evaluation-initiale/:cycleId', component: InitialEvaluation},
    {path: '/liste-de-pratique/:id', component: PracticeList},
    {path: '/presentation/:cycleId', component: TargetPresentations},
    {path: '/enonce-evaluation/:cycleId', component: RegularEvaluation},
    {path: '/page-accueil-apprenant/', name: 'page-accueil-apprenant', component: LearnerHome, props: true},
    {path: '/felicitations/', component: CongratulationComponent},
    {path: '/presentation-intermediaire', component: IntermediatePresentation},
    {path: '/transition-intermediaire', component: IntermediateTransition},
    {path: '/maison-tutoriel/', component: HomeTutorial},
    {
        path: '/navigation-liste-de-pratique',
        name: 'navigation-liste-de-pratique',
        component: PracticeListNavigation,
        props: true
    },

    /**
     * Agent routes
     */
    {path: '/rules', component: RulesTable, beforeEnter: authGuard},
    {path: '/learners', component: LearnerTable, beforeEnter: authGuard},
    {path: '/tests', component: TestResult, beforeEnter: authGuard},
    {path: '/tableau-evaluations', component: EvaluationTable, beforeEnter: authGuard},
    {path: '/ajouter-evaluation', component: AddEvaluation, beforeEnter: authGuard},
    {path: '/ajouter-liste-pratique', component: AddPractice, beforeEnter: authGuard},
    {path: '/tableau-liste-pratique', component: PracticeListTable, beforeEnter: authGuard},
];

/**
 * Creates Vue Router
 */
const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes: routes
});

export default router;