const VOWELS = ["i", "é", "ê", "e", "a", "o", "ou", "u", "eu", "in", "an", "on", "un", "y", "i", "ou", "oua", "ay", "ouy", "ey", "êy", "iy", "â", "à", "ou=in", "ou=a"]
const SIMILAR_LETTER_UPPER_CASE = ["C", "K", "O", "P", "S", "U", "V", "W", "X", "Y", "Z"]

/**
 *
 * @param {phoneme} phoneme
 * @returns {boolean} tr
 */
function isVowel(phoneme) {
    return VOWELS.includes(phoneme);
}

/**
 *
 * @param {string} grapheme
 * @param {string} phoneme
 */
function upperSimilar(grapheme, phoneme) {
    if (grapheme.toUpperCase() === grapheme) {
        if (phoneme.toUpperCase() === grapheme && SIMILAR_LETTER_UPPER_CASE.includes(grapheme)) {
            return true;
        }
    }
    return false;
}

/**
 *
 * @param {string} grapheme
 * @param {string} phoneme
 * @returns
 */
function isSilent(grapheme, phoneme) {
    return phoneme === '\\' && grapheme !== '_' && grapheme !== ' ' && grapheme !== '' &&
        ![".", ",", "!", "?", "-", "'"].includes(grapheme);
}

/**
 *
 * @param {string} grapheme
 * @returns {boolean}
 */
function isPunctuation(grapheme) {
    return [".", ",", "!", "?", "-", "'"].includes(grapheme);
}

/**
 *
 * @param {string} grapheme
 * @param {string} phoneme
 * @returns {boolean}
 */
function displayAsText(grapheme, phoneme) {
    return isPunctuation(grapheme) || upperSimilar(grapheme, phoneme) || grapheme === phoneme || grapheme === '_'
}

/**
 *
 * @param {string} grapheme
 * @param  {string} phoneme
 * @returns
 */
function getType(grapheme, phoneme) {
    if (displayAsText(grapheme, phoneme)) return "text";
    else if (isVowel(phoneme)) return "vowel";
    else if (isSilent(grapheme, phoneme)) return "silent"
    else return "consonant";
}

export {isVowel, isSilent, isPunctuation, displayAsText, getType};