<!-- This component is displaying the sound tablet !-->
<template>
  <v-container v-if="tabletVisible" fluid>
    <v-card
        :height="heightTablet"
        :width="widthTablet"
        :class="[isTabletOpaque ? 'opaque' : '', 'tablet',
                learningGroupLetter === 'D' ? 'debutant' : 'intermediate']"
        class="rounded-xl">
      <v-row :style="{height: heightRow + 'px'}"></v-row>
      <v-row dense
             v-for="(learningGroup ,index) in soundTabletsItems.slice().reverse()"
             :key="index"
      >
        <v-col
            align="center"
            v-for="(displayedItem, index) in learningGroup"
            :key="index"
        >
                <span
                    class="font-weight-bold"
                    :style="[
                        displayedItem.target === letterToHighlight && applyCircleHighlight ? circleStyle : '',
                        displayedItem.target !== letterToHighlight && isTabletOpaque ? opacityStyle : noOpacityStyle,
                        {visibility: displayedItem.target === '-' ? 'collapse' : 'visible'},
                        learningGroupLetter !== 'D' ? learningGroupLetter === 'I' ? intermediateStyle : advancedStyle : debutantStyle

                    ]"
                    :class="[
                        displayedItem.focus === true && consolidation ? orangeLetterClass : '',
                        displayedItem.isConsecutive || displayedItem.isConsecutive === undefined ? consecutiveClass : notConsecutiveClass
                    ]"
                >
          {{ displayedItem.target }}
          </span>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>

import {playTTS} from "../../../services/ttsService";
import {playGraphemeAudioFile} from "../../../utils/globalUtils";
import formulesUtils from "../../../utils/formulesUtils";

/**
 * Sound Tablet items must have at least the following fields :
 * - target : string,
 * - focus : boolean
 * - isConsecutive : boolean
 * And must be of type : <Array<Array<Object>>> <br>
 * - Object are tests passed,
 * - "Array<Object>" the test passed of a learning group
 * - "Array<Array<Object>" All the test of all the learning groups of a "Parcours"
 */
export default {
  name: "SoundTablet",
  props: {
    tabletVisible: {default: true, type: Boolean},
    soundTabletsItems: {type: Array},
    // Apply circle style to letter to Highlight
    applyCircleHighlight: {default: false, type: Boolean},
    // Apply opacity style to letter to Highlight
    isTabletOpaque: {default: false, type: Boolean},
    letterToHighlight: {default: '', type: String},
    learningGroup: {type: String},
    firstName: {type: String},
    consolidation: {
      default: false,
      type: Boolean
    },
    widthTablet: {
      default: 520,
      type: Number
    },
    heightTablet: {
      default: 250,
      type: Number
    }
  },

  data() {
    return {
      learningGroupLetter: '',
      debutantStyle: {fontSize: '200%'},
      intermediateStyle: {fontSize: '180%'},
      advancedStyle: {fontSize: '200%'},
      noOpacityStyle: {opacity: '1'},
      opacityStyle: {opacity: '0.2'},
      circleStyle: {
        borderRadius: '50%',
        border: '2px solid #000',
        marginRight: '25px',
        fontSize: '278%',
      },
      notConsecutiveClass: 'grey--text text--lighten-1',
      consecutiveClass: 'black--text',
      orangeLetterClass: 'orange--text text-lg-h3',
      heightRow: 0
    }
  },
  watch: {
    learningGroup: function () {
      this.getSpacingHeight();
    },
    /* Watch on consolidation, even if false send finished event*/
    consolidation: async function () {
      if (this.consolidation === false || this.soundTabletsItems[this.soundTabletsItems.length - 1].length === 0)
        this.$emit("consolidation-over");
      else
        await this.startConsolidation();
    },
  },

  mounted() {
    this.learningGroupLetter = Array.from(this.learningGroup)[0];
    this.getSpacingHeight();
  },
  methods: {

    /**
     * Starts presentation for every item in sound tablets
     */
    async startConsolidation() {
      const randomFsObj = await formulesUtils.randomFs();
      await playTTS(randomFsObj.sentence, 0, 1, "97%");

      await playTTS(this.firstName + "Regarde ce que tu as dans ta tablette des sons. Écoute bien chaque son...", 4000, 1, "95% ");
      for (let item of this.soundTabletsItems[this.soundTabletsItems.length - 1]) {
        if (item.target !== '-' && item.target !== '_') {
          item.focus = true;
          await playGraphemeAudioFile(item.target, 3000);
          item.focus = false;
        }
      }
      this.$emit("consolidation-over");
    },

    /** Defines heightRow depending on current learning group
     * @return void
     */
    getSpacingHeight() {
      switch (this.learningGroup) {
        case "D1" :
          this.heightRow = 175;
          break;
        case "D2" :
          this.heightRow = 115;
          break;
        case "D3" :
          this.heightRow = 55;
          break;
        case "D4" :
          this.heightRow = 15;
          break;
        case "I1" :
          this.heightRow = 298;
          break;
        case "I7" :
          this.heightRow = 15;
          break;
        default :
          this.heightRow = 160;
      }
    }
  }
}
</script>

<style scoped>
.tablet {
  background: white !important;
  border: 16px solid black !important;
  border-left-width: 32px !important;
  border-right-width: 32px !important;
}

.tablet:before {
  content: '';
  display: block;
  width: 5px;
  height: 60px;
  position: absolute;
  bottom: 22.5%;
  left: -3.75%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

.tablet:after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 42%;
  right: -8.0%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

.intermediate:before {
  bottom: 32%;
  left: -2.75%;
}

.intermediate:after {
  right: -6.0%;
  bottom: 45%;
}

.opaque {
  border: 16px solid rgba(64, 64, 64, 0.2) !important;
  border-left-width: 32px !important;
  border-right-width: 32px !important;
}

.opaque:before {
  background: rgba(120, 120, 120, 0.2) !important;
}

.opaque:after {
  background: rgba(120, 120, 120, 0.2) !important;
}

</style>