/**
 * This file is responsible for requests to the server
 * regarding cycle
 */

import axios from 'axios';

const baseUrl = process.env.VUE_APP_AILE_SERVER_API_BASE_URL + 'cycle';

export default {
    /**
     * Makes a request to the server to get the all tests of an evaluation from a cycle
     * @param idCycle the id of the cycle
     * @param isPresentation : boolean Must be true only for presentation purpose, it will show all target of tests as consecutive
     * @returns list_tests the array containing all the tests
     */
    async getTestsCycle(idCycle, isPresentation = false) {
        const list_tests = await axios.get(baseUrl + '/' + idCycle + '/tests').then(res => res.data)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
        /* must add field is_passed and errorCount to element */
        list_tests.forEach(element => {
            element.is_test_passed = "unknown";
            element.error_count = 0;
            element.focus = false;
            if (isPresentation)
                element.showPhonetique = false;
        });
        return list_tests !== undefined ? list_tests : undefined
    },

    /**
     * Makes a request to the server to get the all practice text from a cycle
     * @param idCycle the id of the cycle
     * @param listNumber 1 || 2, the number of the practice list of the cycle
     * @returns Array<Object> the array containing all the practice text
     */
    async getPracticeTextCycle(idCycle, listNumber) {
        const list_practice = await axios.get(baseUrl + '/' + idCycle + '/practice/' + listNumber).then(res => res.data)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });

        list_practice.forEach(element => {
            element.text = JSON.parse(element.text);
        });
        return list_practice !== undefined ? list_practice : undefined
    },

    /**
     * Makes a request to the server to get Cycle passed in parameter
     * @param idCycle the code of the cycle
     * @returns Promise<CycleModel> a cycle
     */
    async getCycle(idCycle) {
        const cycle = await axios.get(baseUrl + '/' + idCycle)
            .then(res => res.data)
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
        return cycle !== undefined ? cycle : undefined
    },


}